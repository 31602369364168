import { combineReducers } from 'redux';


import {otpReducer} from './reducers/otp'
import {viewReducer} from './reducers/view'

const makeRootReducer = asyncReducers =>
  combineReducers({
    otpReducer,
    viewReducer,
    ...asyncReducers,
  });

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
