// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicModalRegisterAdmin } from "./plasmic/imbewu_mobile_first/PlasmicModalRegisterAdmin";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import { setSession, setUser, setUserName } from "../redux/reducers/otp";
import { showAdminCreateModal, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import Select__Option from "./Select__Option";
import { API } from 'aws-amplify'
import { createUser } from '../graphql/mutations'
import ProcessedItem from "./ProcessedItem";
import Papa from 'papaparse';
Amplify.configure(awsconfig);


function ModalRegisterAdmin_(props, ref) {
  const [file, setFile] = React.useState();
  const [fileName, setFileName] = React.useState('No File Selected');
  const [array, setArray] = React.useState([]);
  const [arrayCsv, setArrayCsv] = React.useState([]);

  const fileReader = new FileReader();

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    setFile(event.target.files[0]);
    setFileName(fileUploaded.name)
  };

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
     
      var str = values[0]
      var tmp = String(str).split("\r");
      console.log('Emailx', tmp)
      //console.log('Emailx', tmp[0], tmp[1], tmp[2], tmp[3], tmp[4])
      signUpCSV(tmp[0], tmp[1], tmp[2], tmp[3], tmp[4])
      const obj = csvHeader.reduce((object, header, index) => {
        object[index] = values[index];

        return object;
      }, {});
      return obj;
    });
   
    setArray(array);
  };

  const signUpCSV = async (emailAddress, phone, gend, fname, lname) => {
        setStatus('csvProcess')
        console.log('Emailx', emailAddress, phone, gend, fname, lname)
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email:emailAddress, firstName:fname, lastName:lname, cell:phone, gender:gend, admin:switched, username:emailAddress })
        };
      
        try {
          const response = await fetch('https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/addUser', requestOptions);
          const data = await response.json();
          console.log(data); // prints the response data to the console
        } catch (error) {
          console.error(error);
        }
        try {
         
      //    let apiName = 'AdminQueries';
      //    let path = '/createUser';
      //    let myInit = { 
      //        body: {
      //         "admin": switched,
      //         "email":emailAddress,
      //         "password":"Tr!st@n1",
      //         "gender": gend,
      //         "firstName": fname,
      //         "lastName": lname,
      //         "cell": '+'+phone
      //        },
      //        headers: {
      //          'Content-Type' : 'application/json',
      //          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      //        }
      //    }
      //    const { NextToken, ...rest } =  await API.post(apiName, path, myInit);
      //    nextToken = NextToken;
 
      //    let path2 = '/addUserToGroup';
      //    let myUser 
        
      //      myUser = "hurtusers"
         
 
 
      //    let myInit2 = { 
      //        body: {
      //          "username": emailAddress,
      //          "groupname":myUser,
      //        },
      //        headers: {
      //          'Content-Type' : 'application/json',
      //          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      //        }
      //    }
      //    const add = await API.post(apiName, path2, myInit2);
         var obj = {item:emailAddress, status:"success"}
         var arr = arrayCsv;
         arr.push(obj)
         console.log('sad', obj)
         setArrayCsv(oldArray => [...oldArray, obj])
       } catch (error) {
         var obj = {item:emailAddress, status:"error"}
         var arr = arrayCsv;
         arr.push(obj)
         console.log('sadx', obj)
         setArrayCsv(oldArray => [...oldArray, obj])
        
       }
       setStatus('csvComplete')
      
   }

   const inputElement1 = React.useRef(null);
   const inputElement2 = React.useRef(null);
   const inputElement3 = React.useRef(null);
   const inputFirstName = React.useRef(null);
   const inputLastName = React.useRef(null);
   const inputGender = React.useRef(null);
   const inputCell = React.useRef(null);
   const inputEmail = React.useRef(null);
   const [cellNumber1, setCellNumber1] = React.useState(null);
   const [cellNumber2, setCellNumber2] = React.useState(null);
   const [cellNumber3, setCellNumber3] = React.useState(null);
   const [firstName, setFirstName] = React.useState(null);
   const [lastName, setLastName] = React.useState(null);
   const [valid, setValid] = React.useState(true);
   
   
   const [gender, setGender] = React.useState(null);
   const [password, setPassword] = React.useState(null);
   const [confirmPassword, setConfirmPassword] = React.useState(null);
   const [errorFirstNameTxt, setErrorFirstNameTxt] = React.useState(true);
   const [errorFirstName, setErrorFirstName] = React.useState(false);
   const [errorLastNameTxt, setErrorLastNameTxt] = React.useState(true);
   const [errorLastName, setErrorLastName] = React.useState(false);
   const [errorGender, setErrorGender] = React.useState(true);
 
   const [errorCell, setErrorCell] = React.useState(true);
   const [email, setEmail] = React.useState(null);
   const [errorEmail, setErrorEmail] = React.useState(false);
   const [errorEmailTxt, setErrorEmailTxt] = React.useState(true);
   const [errorPassword, setErrorPassword] = React.useState(true);
   const [errorConfirmPassword, setErrorConfirmPassword] = React.useState(true);
   const [showPassword, setShowPassword] = React.useState(false);
   const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
   const [switched, setSwitch] = React.useState(false);
   const [status, setStatus] = React.useState('start');
 

   const onChangeCell1 =  React.useCallback((e) => {
    if(e.length<3){
      setCellNumber1(e); 
    }
    console.log('tab', e.length)
    if(e.length==2){
     inputElement2.current.focus()
    }
   
  },[cellNumber1])

  const onChangeCell2 =  React.useCallback((e) => {
    if(e.length<4){
    setCellNumber2(e); 
    }
    if(e.length==3){
      inputElement3.current.focus()
     }
  },[cellNumber2])

  const onChangeCell3 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber3(e); 
    }
  },[cellNumber3])
  
  const onChangeShowPassword=  React.useCallback((e) => {
    console.log('LLL', showPassword)
    setShowPassword(!showPassword); 
  },[showPassword])

  const onChangeShowConfirmPassword=  React.useCallback((e) => {
    console.log('LLL2', showConfirmPassword)
    setShowConfirmPassword(!showConfirmPassword); 
  },[showConfirmPassword])


  const onChangeFirstName = React.useCallback((e) => {
    setFirstName(e);
  },[firstName])

  const onChangeLastName =  React.useCallback((e) => {
    console.log('LLL4', firstName)
    setLastName(e);
  },[lastName])

  const onChangeEmail = React.useCallback((e) => {
    setEmail(e); 
  },[email])



  const onChangeGender =  React.useCallback((e) => {
    setGender(e);
  },[gender])

  const onChangePassword =  React.useCallback((e) => {
    setPassword(e);
  },[password])


  const onChangeConfirmPassword =  React.useCallback((e) => {
    setConfirmPassword(e);
  },[confirmPassword])


  const validate =  React.useCallback((e) => {
    
    if(firstName==null || firstName.length<2){
     setErrorFirstNameTxt('Required')
     window.scrollTo(0, inputFirstName.current.offsetTop)
     setErrorFirstName(true)
     return false
   }else{
     setErrorFirstName(false)
   }
   if(lastName==null || lastName.length<2){
     
     setErrorLastNameTxt('Required')
     window.scrollTo(0, inputLastName.current.offsetTop)
     setErrorLastName(true)
     return false
   }else{
     setErrorLastNameTxt(false)
   }
   console.log('GEnder', gender)
   if(gender==null){
    
     setErrorGender('Required')
     window.scrollTo(0, inputGender.current.offsetTop)
     return false
   }else{
     setErrorGender(true)
   }
   if(cellNumber1== null || cellNumber2==null || cellNumber3==null || (cellNumber1+cellNumber2+cellNumber3).length<9){
     
     setErrorCell('A Valid cell number is required')
     return false
   }else{
     setErrorCell(true)
   }


   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
    
     setErrorEmail(false)
   }else{
     setErrorEmailTxt('A valid email address is required')
     setErrorEmail(true)
     return false
   }

   
   return true
 },[firstName, lastName, gender, password, confirmPassword, email, cellNumber1,cellNumber2,cellNumber3])


  


  let nextToken;

  const signUp = async () => {
    console.log('SIGN UP ', validate())
   if(validate()){

      props.showLoadingModal(true)
      try {
        // let apiName = 'AdminQueries';
        // let path = '/createUser';
        // let myInit = { 
        //     body: {
        //       "admin": switched,
        //       "email":email,
        //       "password":"Tr!st@n1",
        //       "gender": gender,
        //       "firstName": firstName,
        //       "lastName": lastName,
        //       "cell": '+27'+cellNumber1+cellNumber2+cellNumber3
        //     },
        //     headers: {
        //       'Content-Type' : 'application/json',
        //       Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        //     }
        // }
        // const { NextToken, ...rest } =  await API.post(apiName, path, myInit);
        // nextToken = NextToken;

        // let path2 = '/addUserToGroup';
        // let myUser 
        // if(switched==true){
        //   myUser = "shaunadmin"
        // }else{
        //   myUser = "hurtusers"
        // }


        // let myInit2 = { 
        //     body: {
        //       "username": email,
        //       "groupname":myUser,
        //     },
        //     headers: {
        //       'Content-Type' : 'application/json',
        //       Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        //     }
        // }
        // const add = await API.post(apiName, path2, myInit2);
       let phoneNumber =  "+27"+cellNumber1+cellNumber2+cellNumber3
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, firstName, lastName,cell:phoneNumber, gender,admin:switched, username: email  })
        };
      
        try {
          const response = await fetch('https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/addUser', requestOptions);
          const data = await response.json();
          console.log(data); // prints the response data to the console
        } catch (error) {
          console.error(error);
        }
      
        setStatus('successSingle')
        props.showLoadingModal(false)
      } catch (error) {
        console.log('errorrror', error)
        props.showErrorModal("The email address is already registered")
        props.showLoadingModal(false)
      }
     

    }
  }
  

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log('CSV2')
    if (file) {
      // fileReader.onload = function (event) {
      //   const text = event.target.result;
      //   csvFileToArray(text);
      // };

      // fileReader.readAsText(file);
      const reader = new FileReader();

      reader.onload = async (e) => {
        const csvData = e.target.result;
        const parsedData = Papa.parse(csvData);
        for (let index = 0; index < parsedData.data.length; index++) {
          const element = parsedData.data[index];
          if(index!=0){
            
            signUpCSV(element[0], "+"+element[1], element[2], element[3], element[4])
          }
          
        }
        console.log('Emailx3',parsedData);
      };

      reader.readAsText(file);
        }else{
      signUp()
    }
  };
console.log('Admin create 1',arrayCsv)
  return <div>{props.view.showAdminCreateModal ?<div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}><input
  type="file"
  ref={hiddenFileInput}
  onChange={handleChange}
  style={{display: 'none'}}
/><PlasmicModalRegisterAdmin root={{ ref }} {...props} 
  status = {status}
  txtInput = {fileName}
  inputCell = {{
    txtCell1: {
      onChange: (e) => onChangeCell1(e.target.value),
      value: cellNumber1,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:2,
      ref: inputElement1
    },
    txtCell2:{
      onChange: (e) => onChangeCell2(e.target.value),
      value: cellNumber2,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:3,
      ref: inputElement2
    },
    txtCell3:{
      onChange: (e) => onChangeCell3(e.target.value),
      value: cellNumber3,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxlLength:4,
      ref: inputElement3
    },
    txtError: errorCell,
    showError:errorCell
  }}
  
  inputFirstName = {{
    txtValue : {
      onChange: (e) => onChangeFirstName(e.target.value),
      value: firstName,
      placeHolder: 'First Name'
    },
    txtError: errorFirstNameTxt,
    showError:errorFirstName,
    ref: inputFirstName
  }}
  inputLastName = {{
    txtValue : {
      onChange: (e) => onChangeLastName(e.target.value),
      value: lastName,
      placeHolder: 'Last Name'
    },
    showError:true,
    txtError: errorLastNameTxt,
    showError:errorLastName,
    ref: inputLastName
  }}

  inputEmail = {{
    txtValue : {
      onChange: (e) => onChangeEmail(e.target.value),
      value: email,
      placeHolder: 'Email address'
    },
    txtError: errorEmailTxt,
    showError:errorEmail,
    ref: inputEmail
  }}


  inputGender = {{
    selectTxt:'Gender',
    txtError: errorGender,
    showError:errorGender,
    ref: inputGender,
   selectGender: {
     children:  <React.Fragment>
   <Select__Option
     value={"male"}
   >
     {"Male"}
   </Select__Option>

   <Select__Option
     value={"female"}
   >
     {"Female"}
   </Select__Option>
 </React.Fragment>,
    onChange: (e) => onChangeGender(e),
    selectedContent: gender
  }}
  }

  adminSwitch={{
    onChange: (e) => {
     setSwitch(e)
    
    }
  }}
  btnUpload = {{
    onClick: (e) => {
      setStatus('csvUpload')
    }
  }}
  btnProcess = {{
    onClick: (e) => {
      console.log()
      handleOnSubmit(e)
    }
  }}
  btnChoose = {{
    onClick: (e) => {
      handleClick(e)
    }
  }}

  btnCancel = {{
    onClick: (e) => {
      setStatus('start')
     props.showAdminCreateModal(false)
    }
  }}


  processList = {{
    children: arrayCsv.map((item1, index)=>(
      <React.Fragment key={index}>
        {console.log('Admin create x', item1)}
        <ProcessedItem 
        nameTxt = {item1.item}
        status = {item1.status}
        />
      </React.Fragment>
   ))
  }}


  
    submitBtn = {{
      onClick: (e) => {
        signUp()
      }
    }}
    btnBack = {{
      onClick: (e) => {
        setStatus('start')
       props.showAdminCreateModal(false)
      }
    }}
  />   </div>:null}
  </div>;
}

const ModalRegisterAdmin = React.forwardRef(ModalRegisterAdmin_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer

});

const mapDispatchToProps = dispatch => ({
  setUsername: (string) => dispatch(setUserName(string)),
  setUser: (obj) => dispatch(setUser(obj)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  showAdminCreateModal:(string) => dispatch(showAdminCreateModal(string))
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalRegisterAdmin);
