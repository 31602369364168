// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Icon33Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      stroke={"currentColor"}
      fill={"currentColor"}
      strokeWidth={"0"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g stroke={"none"}>
        <path fill={"none"} d={"M0 0h24v24H0z"}></path>

        <path
          d={
            "M7 20.981a6.5 6.5 0 01-2.936-12 8.001 8.001 0 0115.872 0 6.5 6.5 0 01-2.936 12V21H7v-.019zM13 12V8h-2v4H8l4 5 4-5h-3z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default Icon33Icon;
/* prettier-ignore-end */
