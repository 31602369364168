// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicModalPaymentOutcome } from "./plasmic/imbewu_mobile_first/PlasmicModalPaymentOutcome";
import axios from 'axios'
import qs from 'qs'

import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { showLoadingModal } from "../redux/reducers/view";
//import { useSearchParams } from "react-router-dom";

function ModalPaymentOutcome_(props, ref) {
  const [amount, setAmount] = React.useState('0.00');
  const [status, setStatus] = React.useState('loading');
  const [id, setId] = React.useState(null);

  //const [searchParams, setSearchParams] = useSearchParams();

 React.useEffect(() => {
 
  
  // if(props.match.path=='/'){
  //   setIsAdmin(false)
  // }
  // setSearchParams(props.history)
  // var resourcePath
  const resourcePath =  qs.parse(props.location.search, { ignoreQueryPrefix: true }).resourcePath
  // if(searchParams){
  //  resourcePath  = searchParams.get("resourcePath")
  // }
  console.log('wet8', resourcePath)
  if(resourcePath){
    setId(resourcePath)
    if(id){
      console.log('wet4', id)
      getStatus()
    }
  }else{
    console.log('wet9', props.otpReducer.user)
    if(props.otpReducer.user && props.otpReducer.user.plan){
      console.log('wet6', id)
      setAmount(String(props.otpReducer.user.plan.items[0].cost)+'.00')
    
    }
    if(amount!='0.00'){
      console.log('wet7', id)
      getId()
    }
  }
}, [amount, id, props.otpReducer]);
  var data = qs.stringify({
    'entityId': '8ac7a4c9802850cf0180288b040600fb',
    'amount': amount,
    'currency': 'ZAR',
    'paymentType': 'DB' 
  });
  var config2 = {
    method: 'post',
    url: 'https://eu-test.oppwa.com//v1/checkouts',
    headers: { 
      'Authorization': 'Bearer OGFjN2E0Yzk4MDI4NTBjZjAxODAyODhhZjUyZjAwZjV8OUVCc2J6NTJXVw==', 
      'Content-Type': 'application/x-www-form-urlencoded', 
     },
    data : data
  };
  var config3 = {
    method: 'get',
    url: 'https://eu-test.oppwa.com'+id+'?entityId=8ac7a4c9802850cf0180288b040600fb',
    headers: { 
      'Authorization': 'Bearer OGFjN2E0Yzk4MDI4NTBjZjAxODAyODhhZjUyZjAwZjV8OUVCc2J6NTJXVw==', 
      'Content-Type': 'application/x-www-form-urlencoded', 
     }
  };
  const getId = async() => {
    const test = await axios(config2)
    .then(function (response) {
      console.log('Pay',JSON.stringify(response.data));
      window.setId(response.data.id)
      // const script = document.createElement("script");
  
      // script.src = "https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=A3AB0ED43316883C044C015A0C145A07.uat01-vm-tx02";
      // script.async = true;
  
      // document.body.appendChild(script);
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  const getStatus = async() => {
    console.log('wet5',id);
    const test = await axios(config3)
    .then(function (response) {
      console.log('wet3',JSON.stringify(response.data));
      window.setId(response.data.id)
      // const script = document.createElement("script");
  
      // script.src = "https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=A3AB0ED43316883C044C015A0C145A07.uat01-vm-tx02";
      // script.async = true;
  
      // document.body.appendChild(script);
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  
  return <div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}><PlasmicModalPaymentOutcome root={{ ref }} {...props} 
        status={status}
  /></div>;
}

const ModalPaymentOutcome = React.forwardRef(ModalPaymentOutcome_);

const mapStateToProps = state => ({
  view: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalPaymentOutcome);
