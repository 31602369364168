// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicMyProfile } from "./plasmic/imbewu_mobile_first/PlasmicMyProfile";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import { setSession, setUser, setUserName } from "../redux/reducers/otp";
import { showDependantModal, showErrorModal, showLoadingModal, showNotifyModal } from "../redux/reducers/view";
import { API } from 'aws-amplify'
import { createAccount, createUser } from '../graphql/mutations'
import CardPlanA from "./CardPlanA";
import CardPlanB from "./CardPlanB";
import CardPlanC from "./CardPlanC";
import Select from 'react-select'
import genderList from '../assets/gender';
import {updateAccount,updateUser} from '../graphql/mutations'
import banks from '../assets/banks.json';
import accountTypes from '../assets/acountTypes.json';
import moment from "moment";
import DatePicker from "react-datepicker";
Amplify.configure(awsconfig);

function MyProfile_(props, ref) {

  const plans = [{plan:'Plan A',component:<CardPlanA />}, {plan:'Plan B',component:<CardPlanB />}, {plan:'Plan C',component:<CardPlanC/>}]


  const [isOpenDay, setIsOpenDay] = React.useState(false);

  const [showHelp, setShowHelp] = React.useState(true);



  const [errorPaymentDate, setErrorPaymentDate] = React.useState(false);
  const [errorPaymentDateTxt, setErrorPaymentDateTxt] = React.useState(false);

  const inputElement1 = React.useRef(null);
  const inputElement2 = React.useRef(null);
  const inputElement3 = React.useRef(null);
  const inputFirstName = React.useRef(null);
  const inputLastName = React.useRef(null);
  const inputGender = React.useRef(null);
  const inputCell = React.useRef(null);
  const inputEmail = React.useRef(null);
  const [cellNumber1, setCellNumber1] = React.useState(null);
  const [cellNumber2, setCellNumber2] = React.useState(null);
  const [cellNumber3, setCellNumber3] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [valid, setValid] = React.useState(true);
  const [email, setEmail] = React.useState(null);
  
  
  const [gender, setGender] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [errorFirstNameTxt, setErrorFirstNameTxt] = React.useState("");
  const [errorFirstName, setErrorFirstName] = React.useState(false);
  const [errorLastNameTxt, setErrorLastNameTxt] = React.useState("");
  const [errorLastName, setErrorLastName] = React.useState(false);
  const [errorGender, setErrorGender] = React.useState(true);
  const [errorGenderTxt, setErrorGenderTxt] = React.useState("");

  const [errorDob, setErrorDob] = React.useState(false);

  const [errorCell, setErrorCell] = React.useState(true);
  const [errorCellTxt, setErrorCellTxt] = React.useState("");
 
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorEmailTxt, setErrorEmailTxt] = React.useState(true);
  const [errorPassword, setErrorPassword] = React.useState(true);
  const [errorPasswordTxt, setErrorPasswordTxt] = React.useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = React.useState(true);
  const [errorConfirmPasswordTxt, setErrorConfirmPasswordTxt] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [idNumber, setSAId] = React.useState(null);
  const [errorSAId, setErrorSAId] = React.useState(false);
  const [errorSAIdTxt, setErrorSAIdTxt] = React.useState(null);
  const [errorDOB, setErrorDOB] = React.useState(false);
  const [errorDobTxt, setErrorDobTxt] = React.useState("");
  const [errorTerms, setErrorTerms] = React.useState(true);
  const [errorTermsTxt, setErrorTermsTxt] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [year, setYear] = React.useState(null);
  const [month, setMonth] = React.useState(null);
  const [day, setDay] = React.useState(null);
  const [paymentDay, setPaymentDay] = React.useState();
  const [plan, setPlan] = React.useState(null);

  const inputAccountName = React.useRef(null);
  const [accountName, setAccountName] = React.useState(null);
  const [errorAccountName, setErrorAccountName] = React.useState(false);
  const [errorAccountNameTxt, setErrorAccountNameTxt] = React.useState("");

  const inputBranch = React.useRef(null);
  const [branch, setBranch] = React.useState(null);
  const [errorBranch, setErrorBranch] = React.useState(false);
  const [errorBranchTxt, setErrorBranchTxt] = React.useState("");


  const [oldPassword, setOldPassword] = React.useState(null);
  const [newPassword, setNewPassword] = React.useState(null);

  const inputBank = React.useRef(null);
  const [bank, setBank] = React.useState(null);
  const [errorBank, setErrorBank] = React.useState(true)
  const [errorBankTxt, setErrorBankTxt] = React.useState("");

  const inputType= React.useRef(null);
  const [type, setType] = React.useState(null);
  const [errorType, setErrorType] = React.useState(true)
  const [errorTypeTxt, setErrorTypeTxt] = React.useState("");

  const inputAccountNo= React.useRef(null);
  const [accountNo, setAccountNo] = React.useState(null);
  const [errorAccountNo, setErrorAccountNo] = React.useState(true)
  const [errorAccountNoTxt, setErrorAccountNoTxt] = React.useState("");

  const sendUpdate = async(amount) => {
    
    if(validate()){
      const user = await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id:props.otpReducer.user.id ? props.otpReducer.user.id : props.otpReducer.user.username,
            paymentDate: paymentDay
          },
        },
       
      })
     
      props.showNotifyModal("Payment date has been changed")
    }
    
  }


  const onChangeShowConfirmPassword=  React.useCallback((e) => {
    console.log('LLL2', showConfirmPassword)
    setShowConfirmPassword(!showConfirmPassword); 
  },[showConfirmPassword])

  React.useEffect(() => {
    if(props.otpReducer.user){
      setFirstName(props.otpReducer.user.firstName)
      setLastName(props.otpReducer.user.lastName)
      setPaymentDay(props.otpReducer.user.paymentDate)
      setGender(props.otpReducer.user.gender)
      setSAId(props.otpReducer.user.idNumber)
      var yearSplit = String(props.otpReducer.user.idNumber).substr(0,2)
    var monthSplit = String(props.otpReducer.user.idNumber).substr(2,2)
    var daySplit = String(props.otpReducer.user.idNumber).substr(4,2)
   
    if(parseInt(yearSplit) < 30){
      yearSplit = "20"+yearSplit
    }else{
      yearSplit = "19"+yearSplit
    }
    console.log('SAID2', props.otpReducer.user)
    setYear(yearSplit)
    setMonth(monthSplit)
    setDay(daySplit)
      setCellNumber1(String(props.otpReducer.user.cell).substring(3, 5));
      setCellNumber2(String(props.otpReducer.user.cell).substring(5, 8));
      setCellNumber3(String(props.otpReducer.user.cell).substring(8));
      setEmail(props.otpReducer.user.email)
      console.log('ACCOUNT',props.otpReducer.user.account )
      if(props.otpReducer.user.account.items[0]){
        var account = props.otpReducer.user.account.items[0]
        setAccountNo(account.accountNo)
        setAccountName(account.accountName)
        setType(account.accountType)
        setBank(account.bank)
        setBranch(account.code)
      }
     
      // switch (account.accountType) {
      //   case "Savings":
      //     setType({"label": "Savings", "value":"Savings"})
      //     break;
      //     case "Checking":
      //       setType({"label": "Checking", "value":"Checking"})
      //       break;
      //       case "Current":
      //         setType({"label": "Current", "value":"Current"})
      //         break;
        
      //   default:

      //     break;
      // }

   
      switch (props.view.selectedPlan.name) {
        case 'Plan A':
          setPlan(<CardPlanA/>)
          break;
          case 'Plan B':
            setPlan(<CardPlanA/>)
            break;
            case 'Plan C':
              setPlan(<CardPlanA/>)
              break;
      
        default:
          break;
      }
    }

   
    
   
  }, [props.otpReducer, props.view]);

const handleDayOpen = () => {

  setIsOpenDay(true)
 }
 
 
 const handleDay = (e) => {
   var d = moment(e).format('DD')
   console.log('Momenyt', d)
   setIsOpenDay(false)
    console.log('Payment day', d)
   setPaymentDay(d)
 }
 
  
 
 console.log('VIEW0', props.view)

  const onChangeSAId= React.useCallback((e) => {
    var yearSplit = String(e).substr(0,2)
    var monthSplit = String(e).substr(2,2)
    var daySplit = String(e).substr(4,2)
   
    if(parseInt(yearSplit) < 30){
      yearSplit = "20"+yearSplit
    }else{
      yearSplit = "19"+yearSplit
    }
    console.log('SAID', yearSplit, monthSplit, daySplit)
    setYear(yearSplit)
    setMonth(monthSplit)
    setDay(daySplit)
    setSAId(e); 
  },[idNumber])
  const onChangeCell1 =  React.useCallback((e) => {
    if(e.length<3){
      setCellNumber1(e); 
    }
    console.log('tab', e.length)
    if(e.length==2){
     inputElement2.current.focus()
    }
   
  },[cellNumber1])

  const onChangeCell2 =  React.useCallback((e) => {
    if(e.length<4){
    setCellNumber2(e); 
    }
    if(e.length==3){
      inputElement3.current.focus()
     }
  },[cellNumber2])

  const onChangeCell3 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber3(e); 
    }
  },[cellNumber3])
  
  const onChangeShowPassword=  React.useCallback((e) => {
    console.log('LLL', showPassword)
    setShowPassword(!showPassword); 
  },[showPassword])



  const onChangeFirstName = React.useCallback((e) => {
    setFirstName(e);
  },[firstName])

  const onChangeLastName =  React.useCallback((e) => {
    console.log('LLL4', firstName)
    setLastName(e);
  },[lastName])

  const onChangeEmail = React.useCallback((e) => {
    setEmail(e); 
  },[email])



  const onChangeGender =  React.useCallback((e) => {
    setGender(e.value);
  },[gender])

  const onChangePassword =  React.useCallback((e) => {
    setOldPassword(e);
  },[oldPassword])


  const onChangeConfirmPassword =  React.useCallback((e) => {
    setNewPassword(e);
  },[newPassword])

  const onChangeAccountName =  React.useCallback((e) => {
    setAccountName(e);
  },[accountName])

  const onChangeBranch =  React.useCallback((e) => {
    setBranch(e);
  },[branch])

  const onChangeAccountNo =  React.useCallback((e) => {
    console.log('Acc')
    setAccountNo(e);
  },[accountNo])

  const onChangeBank=  React.useCallback((e) => {
    setBank(e.value);
  },[bank])

  const onChangeType=  React.useCallback((e) => {
    setType(e.value);
  },[type])

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const changePassword = React.useCallback((e)=>{
    console.log('CHange passwor', oldPassword, newPassword)
    Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
    })
    .then(data => {
      props.showDependantModal('changedPassword')
    })
    .catch(err => {
      props.showErrorModal(err.message)
    });
    
  }, [oldPassword, newPassword])

  const validatePasswords =  React.useCallback((e) => {
    if (oldPassword &&
      /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(oldPassword)
      ) {
        setErrorPassword(true) 
    }else{
      setErrorConfirmPassword('Invalid password. Minimum eight characters, at least one letter, one number and one special character')
   
    }
    if (newPassword &&
      /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(newPassword)
      ) {
        setErrorPassword(true) 
    }else{
     
      setErrorPasswordTxt('Invalid password. Minimum eight characters, at least one letter, one number and one special character'   )      
      setErrorPassword(false)    
      return false
    }
    if(confirmPassword==null || confirmPassword!=newPassword){
     
      setErrorConfirmPasswordTxt('Your passwords do not match')
      setErrorConfirmPassword(false)
      return false
    }else{
      setErrorConfirmPassword(true)
    }

    return true
  
    
  },[oldPassword, newPassword])


  const validate =  React.useCallback((e) => {

    if(accountName==null || accountName.length<3){
     
      setErrorAccountNameTxt('Required')
      setErrorAccountName(true)
      return false
    }else{
      setErrorAccountName(false)
    }

   

    
    if(accountNo==null || accountNo.length<6){
      console.log('Account', accountNo)
      setErrorAccountNoTxt('Required')
      setErrorAccountNo(false)

      return false
    }else{
      setErrorAccountNo(true)
    }

    if(bank==null ){
    
      setErrorBankTxt('Required')
      setErrorBank(false)

      return false
    }else{
      setErrorBank(true)
    }
  
    if(type==null ){
    
      setErrorTypeTxt('Required')
      setErrorType(false)

      return false
    }else{
      setErrorType(true)
    }

    if(branch==null ){
    
      setErrorBranchTxt('Required')
      setErrorBranch(true)

      return false
    }else{
      setErrorBranch(false)
    }
    
     if(firstName==null || firstName.length<2){
      setErrorFirstNameTxt('Required')
      window.scrollTo(0, inputFirstName.current.offsetTop)
      setErrorFirstName(true)
      return false
    }else{
      setErrorFirstName(false)
    }
    if(lastName==null || lastName.length<2){
      
      setErrorLastNameTxt('Required')
      window.scrollTo(0, inputLastName.current.offsetTop)
      setErrorLastName(true)
      return false
    }else{
      setErrorLastNameTxt(false)
    }
    console.log('GEnder', gender)
    if(gender==null){
     
      setErrorGenderTxt('Required')
      setErrorGender(false)
      window.scrollTo(0, inputGender.current.offsetTop)
      return false
    }else{
      setErrorGender(true)
    }
    if(cellNumber1== null || cellNumber2==null || cellNumber3==null || (cellNumber1+cellNumber2+cellNumber3).length<9){
      
      setErrorCellTxt('A Valid cell number is required')
      setErrorCell(false)
      return false
    }else{
      setErrorCell(true)
    }

 
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
     
      setErrorEmail(false)
    }else{
      setErrorEmailTxt('A valid email address is required')
      setErrorEmail(true)
      return false
    }

    console.log('day', day)
    if(day==null || day.length<2){
     
     setErrorPaymentDateTxt('Required')
 
     setErrorPaymentDate(true)
     return false
   }else{
     setErrorPaymentDate(false)
   }
 
    
    return true
  },[firstName, lastName, gender, password, confirmPassword, email, cellNumber1,cellNumber2,cellNumber3,accountNo, accountName, type, branch,bank, day])

  const setBankAccount = async() => {
      if(props.otpReducer.user.account.items[0]){
        const user = await API.graphql({
          query: updateAccount,
          variables: {
            input: {
              id:props.otpReducer.user.account.items[0].id,
              userId: props.otpReducer.user.id,
              accountName:accountName,
              accountNo: accountNo,
              bank:bank,
              accountType:type,
              code:branch
              }
            },
  
        })
        props.showNotifyModal("Bank Details have been changed")
        props.setUser(user.data.updateUser)
      }else{
        const user = await API.graphql({
          query: createAccount,
          variables: {
            input: {
              userId: props.otpReducer.user.id,
              accountName:accountName,
              accountNo: accountNo,
              bank:bank,
              accountType:type,
              code:branch
              }
            },
  
        })
        props.showNotifyModal("Bank details have been added")
        console.log('Create bank account')
        props.setUser(user.data.updateUser)
      }
       

       
 };
 
 const updateInfo = async() => {
   
    if(validate()){
      props.showLoadingModal(true)
      setBankAccount();
       var dob = day+'-'+month+'-'+year
       const user = await API.graphql({
         query: updateUser,
         variables: {
           input: {
             id:props.otpReducer.user.id ? props.otpReducer.user.id : props.otpReducer.user.username,
             idNumber,
             dob,
             cell: '+27'+cellNumber1+cellNumber2+cellNumber3,
             gender: gender,
             firstName: firstName,
             lastName: lastName,
             email: email
           },
         },
       })
        //props.showNotifyModal("Payment date has been changed")
        props.showLoadingModal(false)
        props.showDependantModal('updateSuccessful')
     }
 };
  
  const signUp = async () => {
    console.log('IS Valid', validate())
   if(validate()){

   props.showLoadingModal(true)
    const result = await Auth.signUp({
      username: email,
      password,
      attributes: {
        phone_number: '+27'+cellNumber1+cellNumber2+cellNumber3,
        gender: gender,
        name: firstName,
        family_name: lastName,
        email: email

      },
    }).then((result) => {
      console.log('Reg', result)
      props.setUsername(email)
      sendRegistration(result.userSub)
    }).catch((e) => {
      console.log('ERROR', e)
      if (e.code === 'UsernameExistsException') {
        props.showErrorModal('Error: A user already exists with this cell number')
      } else {
        props.showErrorModal('Error: '+e.code)
        console.error(e);
      }
      props.showLoadingModal(false)
    }); 
    
    return result;
  }
  }
  //"1b7f68e8-e16d-4509-ada4-13172c582650"
  //e59a55cc-7330-4f99-93fe-fe17605eaf4d

  const sendRegistration = async(id) => {
    if(validate()){
    //  props.showLoadingModal(true)
      var name = firstName + ' ' + lastName;
      var cell = '+27'+cellNumber1+cellNumber2+cellNumber3
      var type = 'user'
      const user = await API.graphql({
        query: createUser,
        variables: {
          input: {
            id,
            firstName,
            lastName,
            email,
            cell,
            gender,
            type
          },
        },
      })
      console.log('USER@',user.data)
      props.setUser(user.data.createUser)
      props.showLoadingModal(false)
      changePage('/success')
    }
    
  }

  // const getBeneficiaries = React.useCallback(async(id) => {
  //   try {
      
  //     const userReceived = await API.graphql(graphqlOperation(queries.getUser, { id:props.otpReducer.adminUser.id}));
      
  //     console.log('listen user received', userReceived)
  //     props.setUser(userReceived.data.getUser);
  //     subscription.unsubscribe()
  //   } catch (error) {
  //     console.log('ready to auth error', error)
  //   }
  // },[props.otpReducer.adminUser.id]);



  return <div><PlasmicMyProfile root={{ ref }} {...props} 
  paymentDate={{
    txtDate:'Payment Date',
    txtError: errorPaymentDateTxt,
    showError:errorPaymentDate,
    
    day:{
      onClick:(e)=>{
        console.log('year')
          handleDayOpen()
      },
      value:paymentDay
      
    },
  
    
    
  }}
planHolder = {{
  children: plan
}}
dob={{
  txtError: errorDobTxt,
  showError:errorDob,
  year:{
    onClick:(e)=>{
      console.log('year')
        //handleYearOpen()
    },
    value:year
    
  },
  month:{
    onClick:(e)=>{
      console.log('year')
       // handleMonthOpen()
    },

    value:month
  },
  day:{
    onClick:(e)=>{
      console.log('year')
        //handleDayOpen()
    },
    value:day
    
  },

  
  
}}
  inputId = {{
    txtValue: {
      onChange: (e) => onChangeSAId(e.target.value),
      placeHolder:'SA Id Number',
      value: idNumber
    },
    txtError: errorSAIdTxt,
    showError:errorSAId
  }}

  inputConfirmPassword = {{
    txtValue : {
      onChange: (e) => onChangeConfirmPassword(e.target.value),
      value: confirmPassword,
      placeHolder: 'Confirm Password'
    },
    showPassword: showConfirmPassword,
    btnEye: {onClick:(e)=>onChangeShowConfirmPassword()},
    btnEyeClosed: {onClick:(e)=>onChangeShowConfirmPassword()},
    txtError: errorConfirmPasswordTxt,
    showError:errorConfirmPassword
  }}

  inputCell = {{
    txtCell1: {
      onChange: (e) => onChangeCell1(e.target.value),
      value: cellNumber1,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:2,
      ref: inputElement1
    },
    txtCell2:{
      onChange: (e) => onChangeCell2(e.target.value),
      value: cellNumber2,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:3,
      ref: inputElement2
    },
    txtCell3:{
      onChange: (e) => onChangeCell3(e.target.value),
      value: cellNumber3,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxlLength:4,
      ref: inputElement3
    },
    txtError: errorCellTxt,
    showError:errorCell
  }}
  
  inputFirstName = {{
    txtValue : {
      onChange: (e) => onChangeFirstName(e.target.value),
      value: firstName,
      placeHolder: 'First Name'
    },
    txtError: errorFirstNameTxt,
    showError:errorFirstName,
    ref: inputFirstName
  }}

  inputLastName = {{
    txtValue : {
      onChange: (e) => onChangeLastName(e.target.value),
      value: lastName,
      placeHolder: 'Last Name'
    },
    showError:true,
    txtError: errorLastNameTxt,
    showError:errorLastName,
    ref: inputLastName
  }}


  inputEmail = {{
    txtValue : {
      onChange: (e) => onChangeEmail(e.target.value),
      value: email,
      placeHolder: 'Email address'
    },
    txtError: errorEmailTxt,
    showError:errorEmail,
    ref: inputEmail
  }}


  inputGender = {{
    selectTxt:'Gender',
    txtError: errorGenderTxt,
    showError:errorGender,
    ref: inputGender,
   selectHolder:{
     children:    <div  style={{zIndex:9, width:"100%"}}>
     <Select
      value={{ "label": gender, "value":gender}}
       options={genderList}
       isSearchable
       placeholder="Select..."
       onChange = {onChangeGender}
   />
 </div>
  }
 }}

  inputOldPassword = {{
    txtValue : {
      onChange: (e) => onChangePassword(e.target.value),
      value: oldPassword,
      placeHolder: 'Old Password'
    },
    
    showPassword: showPassword,
    btnEye: {onClick:(e)=>onChangeShowPassword()},
    btnEyeClosed: {onClick:(e)=>onChangeShowPassword()},
    txtError: errorPasswordTxt,
    showError:errorPassword
  }}



  inputNewPassword = {{
    txtValue : {
      onChange: (e) => onChangeConfirmPassword(e.target.value),
      value: newPassword,
      placeHolder: 'New Password'
    },
    showPassword: showConfirmPassword,
    btnEye: {onClick:(e)=>onChangeShowConfirmPassword()},
    btnEyeClosed: {onClick:(e)=>onChangeShowConfirmPassword()},
    txtError: errorConfirmPasswordTxt,
    showError:errorConfirmPassword
  }}

  accountName = {{
    txtValue : {
      onChange: (e) => onChangeAccountName(e.target.value),
      value: accountName,
      placeHolder: 'Account Name'
    },
    txtError: errorAccountNameTxt,
    showError:errorAccountName,
    ref: inputAccountName
  }}

  branchCode = {{
    txtValue : {
      onChange: (e) => onChangeBranch(e.target.value),
      value: branch,
      placeHolder: 'Branch Code'
    },
    txtError: errorBranchTxt,
    showError:errorBranch,
    ref: inputBranch
  }}
  accountNo= {{
    txtValue : {
      onChange: (e) => onChangeAccountNo(e.target.value),
      value: accountNo,
    },
    txtError: errorAccountNoTxt,
    showError:errorAccountNo,
    ref: inputAccountNo
  }}

  selectBank = {{
    txtError: errorBankTxt,
    showError:errorBank,
    ref: inputBank,
    selectTxt:'Bank',
   selectHolder: {
     placeholder:'Select your bank...',
     children:  <div  style={{zIndex:10, width:"100%"}}>
     <Select
       options={banks}
       isSearchable
       placeholder="Select..."
       onChange = {onChangeBank}
       value={{ "label": bank, "value":bank}}
   />
 </div>
  }}
  }


  accountType = {{
    txtError: errorTypeTxt,
    showError:errorType,
    ref: inputType,
    selectTxt:'Account Type',
    selectHolder: {
    
      children:  <div  style={{zIndex:9, width:"100%"}}>
      <Select
        options={accountTypes}
        isSearchable
        placeholder="Select..."
        onChange = {onChangeType}
        value={{ "label": type, "value":type}}
    />
  </div>
   }}
  }
  btnChangePassword = {{
    onClick: (e) => {
      console.log('change2')
      if(validatePasswords()){
        console.log('change')
        changePassword()
      }
      
    }
  }}

  changeDate = {{
    onClick: (e) => {
      sendUpdate()
    }
  }}
  
    submitBtn = {{
      onClick: (e) => {
        updateInfo()
      }
    }}
    backBtn = {{
      onClick: (e) => {
        changePage('/home')
      }
    }} />{isOpenDay && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'fixed', top:0, zIndex:3000000}}>
    <div style={{position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'}}>
        <DatePicker  onChange={handleDay} inline
        dateFormat="dd"
                
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            
          }) => (<div></div>)}/>
          </div>
          </div>}
          </div>
}

const MyProfile = React.forwardRef(MyProfile_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  setUsername: (string) => dispatch(setUserName(string)),
  setUser: (obj) => dispatch(setUser(obj)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showNotifyModal:(string) => dispatch(showNotifyModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  showDependantModal: (string) => dispatch(showDependantModal(string)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(MyProfile);
