// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicRegister4Payment } from "./plasmic/imbewu_mobile_first/PlasmicRegister4Payment";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setAmount, setBeneficary, setClaims, setDependant, setPayments, setPayouts, setPlan, setStep, setUserMain, showLoadingModal } from "../redux/reducers/view";
import { usePaystackPayment } from 'react-paystack';
import { API,graphqlOperation } from 'aws-amplify'
import DatePicker from "react-datepicker";
import {createPayment, createAuthorizations ,createUserPlan, updateUser} from '../graphql/mutations'
import { listPlans } from '../graphql/queries'
import moment from "moment";
import * as _ from "lodash";
import { setSession, setUser, setUserName, setVerified } from "../redux/reducers/otp";
import * as queries from '../graphql/queries';


function Register4Payment_(props, ref) {


  const [isOpenDay, setIsOpenDay] = React.useState(false);

  const [showHelp, setShowHelp] = React.useState(true);


  const [day, setDay] = React.useState(null);

  const [errorPaymentDate, setErrorPaymentDate] = React.useState(false);
  const [errorPaymentDateTxt, setErrorPaymentDateTxt] = React.useState(false);

  
  // const [config, setConfig] = React.useState(false);
  

  const config = {
    reference: (new Date()).getTime().toString(),
    email: props.otpReducer.user && props.otpReducer.user.email,
    amount: props.view.selectedPlan && parseInt(props.view.selectedPlan.cost*100),
    currency: "ZAR",
    publicKey: 'pk_test_9e51c1550eaaf52040999ccabc1d53ff0835cf3b',
};

const config2 = {
  reference: (new Date()).getTime().toString(),
  email: props.otpReducer.user && props.otpReducer.user.email,
  amount: 100,
  currency: "ZAR",
  publicKey: 'pk_test_9e51c1550eaaf52040999ccabc1d53ff0835cf3b',
};

React.useEffect(() => {
  // if(props.otpReducer.user){
  //   var configTmp = {
  //     reference: (new Date()).getTime().toString(),
  //     email: props.otpReducer.user.email,
  //     amount: props.view.selectedPlan && parseInt(props.view.selectedPlan.cost*100),
  //     currency: "ZAR",
  //     publicKey: 'pk_test_9e51c1550eaaf52040999ccabc1d53ff0835cf3b',
  // };
 // setConfig(configTmp)
 //}
 
}, [props.otpReducer]);

const initializePayment = usePaystackPayment(config);
const initializePayment2 = usePaystackPayment(config2);


  const onSuccess = (reference) => {
    console.log('PAID')
    setPay(reference)
    
    
    
    // you can call this function anything
    
  };

  const sendUpdate = async(amount) => {
    
    if(validate()){
      props.setAmount(amount)
      const user = await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id:props.otpReducer.user.id ? props.otpReducer.user.id : props.otpReducer.user.username,
            paymentDate: day
          },
        },
      })
      changePage('/carddetails')
      
    }
    
  }
  const setPay= async(reference) => {
    console.log('PAID', reference)
    props.showLoadingModal(true)
    sendUpdate()
        const user = await API.graphql({
          query: createPayment,
          variables: {
            input: {
              userId: props.otpReducer.user.id,
              message: reference.message,
              reference: reference.reference,
              status: reference.status,
              fees: 0,
              amount: parseFloat(props.view.selectedPlan.cost)*100
            },
          },
        })
       
        var authorization;
         await fetch('https://api.paystack.co/transaction/verify/'+reference.reference, {
            "method": "GET",
            "headers": {
              "Authorization": "Bearer sk_test_794fc660f74bdf0e723d9070c9ba524dfdcefa99"
            }
          }).then(response => response.json())

          .then(response => {
            authorization = response.data.authorization
          })
          .catch(err => {
            console.log(err);
          });

          const auth = await API.graphql({
            query: createAuthorizations,
            variables: {
              input: {
                authorization_code: authorization.authorization_code,
                card_type: authorization.card_type,
                last4: authorization.last4,
                exp_month:  authorization.exp_month,
                exp_year:  authorization.exp_year,
                bank: authorization.bank,
                channel:  authorization.channel,
                signature:  authorization.signature,
                reusable:  authorization.reusable,
                country_code:  authorization.country_code,
                account_name:  authorization.account_name,
                userId: props.otpReducer.user.id
               
              },
            },
          })
          const userReceived = await API.graphql(graphqlOperation(queries.getUser, { id:props.otpReducer.user.id }));
          console.log('TEsting user', userReceived)
          var loggedinUser =userReceived.data.getUser;
          props.setUser(userReceived);
          props.setSession(null);
          props.setVerified(true)
          console.log('ready to auth 5',loggedinUser)
          props.setDependants(loggedinUser.dependants.items)
          props.setBeneficary(loggedinUser.beneficiaries.items)
          props.setPayouts(loggedinUser.account.items)
          props.setPayments(loggedinUser.payments.items)
          props.setClaims(loggedinUser.claims.items)
          props.setPlan(loggedinUser.plan.items[0])
          props.setUser(loggedinUser);
          props.showLoadingModal(false)
          changePage('/payout')
  };

  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }



const handleDayOpen = () => {

 setIsOpenDay(true)
}


const handleDay = (e) => {
  var d = moment(e).format('DD')
  console.log('Momenyt', d)
  setIsOpenDay(false)
  setDay(d)
}

  const validate =  React.useCallback((e) => {
   
    console.log('day', day)
   if(day==null || day.length<2){
    
    setErrorPaymentDateTxt('Required')

    setErrorPaymentDate(true)
    return false
  }else{
    setErrorPaymentDate(false)
  }
  
 
   
   return true
 },[day])



 const changePage =  React.useCallback((e) => {

  props.history.push(e);
 
}, []);
console.log('VIEW0', props.view)
  
  return <div className="content"><PlasmicRegister4Payment root={{ ref }} {...props} 
    txtPlan={props.view.selectedPlan.name}
    txtCost = {'R'+props.view.selectedPlan.cost+'pm'}


    paymentDate={{
      txtDate:'Payment Date',
      txtError: errorPaymentDateTxt,
      showError:errorPaymentDate,
      
      day:{
        onClick:(e)=>{
          console.log('year')
            handleDayOpen()
        },
        value:day
        
      },
    
      
      
    }}
   
    submitBtn={{
      txt : 'Pay (R'+props.view.selectedPlan.cost+') Monthly',
      onClick:(e)=>{
        if(validate()){
          props.setStep(5)
          var today = moment().format('DD')
          console.log(' PAY', today, day)
          if(today == day){
            sendUpdate(props.view.selectedPlan.cost)
          }else{
            sendUpdate("1.00")
          }
         
        }
        
      }
    }}
  />{isOpenDay && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'absolute', top:0}}>
  <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
      <DatePicker  onChange={handleDay} inline
      dateFormat="dd"
              
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          
        }) => (<div></div>)}/>
        </div>
        </div>}
       


      </div>;
}

const Register4Payment = React.forwardRef(Register4Payment_);
const mapStateToProps = state => ({
  view: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  setStep: (int) => dispatch(setStep(int)),
  setSession: (string) => dispatch(setSession(string)),
  setVerified: (string) => dispatch(setVerified(string)),
  setUser: (obj) => dispatch(setUser(obj)),
  setUserName: (obj) => dispatch(setUserName(obj)),
  setUserMain: (obj) => dispatch(setUserMain(obj)),
  setDependants: (obj) => dispatch(setDependant(obj)),
  setBeneficary: (obj) => dispatch(setBeneficary(obj)),
  setPayments: (obj) => dispatch(setPayments(obj)),
  setPayouts: (obj) => dispatch(setPayouts(obj)),
  setClaims: (obj) => dispatch(setClaims(obj)),
  setPlan: (obj) => dispatch(setPlan(obj)),
  setAmount: (obj) => dispatch(setAmount(obj)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Register4Payment);
