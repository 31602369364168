// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect } from 'react';
import { PlasmicSignout } from "./plasmic/imbewu_mobile_first/PlasmicSignout";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import {Analytics} from '@aws-amplify/analytics';
import { setSession, setUser, setVerified } from "../redux/reducers/otp";

Amplify.configure(awsconfig);

const NOTSIGNIN = 'You are NOT logged in';
const SIGNEDIN = 'You have logged in successfully';
const SIGNEDOUT = 'You have logged out successfully';
const WAITINGFOROTP = 'Enter OTP number';
const VERIFYNUMBER = 'Verifying number (Country code +XX needed)';

function Signout_(props, ref) {
  const [message, setMessage] = useState('Starting up ...');

  useEffect(() => {
    console.log('Ready to signout');
    // Auth.currentCredentials();
    setTimeout(signOut, 1500);
  }, []);

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);


  const signOut = () => {
    console.log('SIGN OUT',props.otpReducer.user)
    if (props.otpReducer.user) {
      Auth.signOut();
      props.setVerified(null)
      props.setUser(null);
      changePage('/welcome')
    } else {
      setMessage(NOTSIGNIN);
    }
  };


  return <PlasmicSignout root={{ ref }} {...props} />;
}

const Signout = React.forwardRef(Signout_);
const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setVerified: (string) => dispatch(setVerified(string)),
  setSession: (string) => dispatch(setSession(string)),
  setUser: (obj) => dispatch(setUser(obj))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps), 
  withRouter)(Signout) ;
