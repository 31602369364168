// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState } from 'react';
import { PlasmicModalDocView } from "./plasmic/imbewu_mobile_first/PlasmicModalDocView";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showDocModal } from "../redux/reducers/view";
function ModalDocView_(props, ref) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const fileUrl = props.url;
  const fileType = fileUrl.split('.').pop().toLowerCase();

  const isImage = fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif';
  const isPDF = fileType === 'pdf';

  const content = isImage ?
    <img src={fileUrl} alt="Modal Content" width="70%" height="70%" /> :
    isPDF ?
      <iframe title="Modal Content" src={fileUrl} width="100%" height="500px" /> :
      null;

      if(content==null){
        window.open(fileUrl);
      }

  const openFileInNewWindow = () => {
    window.open(fileUrl);
  }
  console.log("CLAIM MODAL",content)
  return <div>
  {props.view.showDocModal && content ? (
    <div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}>
     <PlasmicModalDocView root={{ ref }} {...props} 

     imageHolder = {{
      children: content
     }}
     
    
  backBtn = {{
    onClick: (e) => {
      props.showDocModalNow(false)
    }
  }}



      
      />
      
    </div>
  ) : (
    <button onClick={openFileInNewWindow}>Open in New Window</button>
  )}
</div>;
}

const ModalDocView = React.forwardRef(ModalDocView_);


const mapStateToProps = state => ({
  view: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  showDocModalNow: (obj) => dispatch(showDocModal(obj)),
 
})

export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalDocView);

