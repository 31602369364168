import React,{ useEffect, useState } from 'react';
import Routes from './Components/Routes';
import SideNav from './Components/SideNav';
import TopNav from './Components/TopNav';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setBeneficary, setClaims, setDependants, setPayments, setPayouts, setPlan, setUserMain, toggleSideMenu } from './redux/reducers/view';
import ModalError from './Components/ModalError';
import ModalDependant from './Components/ModalDependant';
import Menu from './Components/Menu';
import { otp, setAdminUser, setUserName } from './redux/reducers/otp';
import LoadingData from './Components/LoadingData';
import ModalNotify from './Components/ModalNotify';
import ModalSuccess from './Components/ModalSuccess';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from './aws-exports';
import {Analytics} from '@aws-amplify/analytics';
import { setSession, setUser, setVerified } from "./redux/reducers/otp";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from './graphql/queries';
import { onDependantCreate, onUserUpdate, onPaymentCreate, onDependantDelete, onBeneficiaryCreate, onDeleteBeneficiary, onClaimCreate, onDependantUpdate, onBeneficiaryUpdate, onPlanCreate, onClaimUpdate, onPayoutCreate, onPayoutUpdate} from './graphql/subscriptions';
import ModalClaimSuccess from './Components/ModalClaimSuccess';
import ModalEditDependant from './Components/ModalEditDependant';
import ModalAddBenficiary from './Components/ModalAddBenficiary';
import ModalEditBenificiary from './Components/ModalEditBenificiary';
import ViewClaim from './Components/ViewClaim';
import ModalErrorA from './Components/ModalErrorA';
import TopNavA from './Components/TopNavA';
import axios from 'axios'
import qs from 'qs'
import { Link, useLocation } from 'react-router-dom';
import ModalCreditCard from './Components/ModalCreditCard';
import ModalRegisterAdmin from './Components/ModalRegisterAdmin';
import ModalCancelSuccess from './Components/ModalCancelSuccess';


Amplify.configure(awsconfig);
Analytics.configure(awsconfig);
Auth.configure(awsconfig);
API.configure(awsconfig); 



function App(props) {
  const [userId, setUserId] = useState(null);
  const [filter, setFilter] = useState(null);
  
  const [isAdmin, setIsAdmin] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    console.log('Ready to auth START', props.match);
    // Auth.currentCredentials();
   /* try {
      window.zE('messenger:set', 'zIndex', -10000);
      window.zE("messenger:on", "close", function () {
        window.zE('messenger:set', 'zIndex', -10000);
      })
    } catch (error) {
      
    }*/
   
    setTimeout(verifyAuth, 1500);
    Analytics.autoTrack('session', {
      enable: true,
    });
   //checkverify()
   
  }, []);
 
  var config2 = {
    method: 'post',
    url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/checkverifyhurt',
    headers: { 
      "x-api-key": process.env.API_IMBEWU2_GRAPHQLAPIKEYOUTPUT,
      'Content-Type': 'application/x-www-form-urlencoded', 
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
     }
  };
  
  const checkverify = async() => {
    const test = await axios(config2)
    .then(function (response) {
      console.log('Pay',JSON.stringify(response.data));
      window.setId(response.data.id)

    })
    .catch(function (error) {
      console.log(error);
    });
  }

  useEffect(() => {
    if(props.otp.adminUser){
      
    }else if(props.otp.user  && props.otp.user.id){
      console.log('user2 user')
      API.graphql({
        query: onDependantCreate,
        variables: {
          userId: props.otp.user.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN dependant create',props.otp)
          getDependants(props.otp.user.id)
        }
      })
      API.graphql({
        query: onDependantDelete,
        variables: {
          userId: props.otp.user.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN dependant delete',props.otp)
          getDependants(props.otp.user.id)
        }
      })
      API.graphql({
        query: onDependantUpdate,
        variables: {
          userId: props.otp.user.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN dependant update',data)
          getDependants(props.otp.user.id)
        }
      })
      API.graphql({
        query: onBeneficiaryCreate,
        variables: {
          userId: props.otp.user.id 
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN beneficiary create',data)
          getBenficiary(props.otp.user.id)
        }
      })
      API.graphql({
        query: onBeneficiaryUpdate,
        variables: {
          userId: props.otp.user.id 
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN beneficiary update',data)
          getBenficiary(props.otp.user.id)
        }
      })
      API.graphql({
        query: onDeleteBeneficiary,
        variables: {
          userId: props.otp.user.id 
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN delete benificiary',data)
          getBenficiary(props.otp.user.id)
        }
      })
      API.graphql({
        query: onPlanCreate,
        variables: {
          userId: props.otp.user.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN Plan create',data)
          getPlans(props.otp.user.id)
        }
      })
      API.graphql({
        query: onPaymentCreate,
        variables: {
          userId: props.otp.user.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN payment create',data)
          getPayments(props.otp.user.id)
        }
      })
      API.graphql({
        query: onPayoutCreate,
        variables: {
          userId: props.otp.user.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN payment create',data)
          getPayout(props.otp.user.id)
        }
      })
      API.graphql({
        query: onPayoutUpdate,
        variables: {
          userId: props.otp.user.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN payment update',data)
          getPayout(props.otp.user.id)
        }
      })
      API.graphql({
        query: onClaimCreate,
        variables: {
          userId: props.otp.user.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN Claim create',data)
          getClaims(props.otp.user.id)
        }
      })
      API.graphql({
        query: onClaimUpdate,
        variables: {
          userId: props.otp.user.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN Claim update',data)
          getClaims(props.otp.user.id)
        }
      })
      API.graphql({
        query: onUserUpdate,
        variables: {
          userId: props.otp.user.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN user update',data)
        retrieveUser(props.otp.user.id)
        }
      })
      setUserId(props.otp.user.id)
      setFilter({
        userId: {
            eq: props.otp.user.id
        }
      }
      )
    }
   

    
  }, [props.otp]);


  useEffect(() => {
    
      console.log('Ready to auth id', userId);
    
  }, [userId]);
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const retrieveUser = async(user) => {
   
    
    try {
      console.log('ready wrong',user)
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];


     if(groups.includes('shaunadmin')){
       
      setIsAdmin(true)
      props.setAdminUser(user)
      changePage('/admindashboard')
     }else{
      const userReceived = await API.graphql(graphqlOperation(queries.getUser, { id:user.username }));
      
     
      var loggedinUser =userReceived.data.getUser;
      props.setUser(userReceived);
      props.setSession(null);
      props.setVerified(true)
      console.log('ready to auth 5',loggedinUser)
      props.setDependants(loggedinUser.dependants.items)
      props.setBeneficary(loggedinUser.beneficiaries.items)
      props.setPayouts(loggedinUser.payouts.items)
      props.setPayments(loggedinUser.payments.items)
      props.setClaims(loggedinUser.claims.items)
      props.setPlan(loggedinUser.plan.items[0])
      props.setUser(loggedinUser);
      console.log('CHECK PAYMENT RESULT', loggedinUser)
      if(props.history.location.path=='/paymentresult'){
       
      }else if(loggedinUser.status=="Cancelled"){
        changePage('/plancancelled')
      }else if(loggedinUser.idNumber==null || loggedinUser.dob==null){
        changePage('/secure')
      }else if(loggedinUser.plan.items.length==0){
        changePage('/chooseplan')
      }else if(loggedinUser.payments.items.length==0){
        //changePage('/carddetails')
      }else if(loggedinUser){
        console.log('ready to auth 6',props.viewReducer)
        changePage('/home')
      }else{
        changePage('/home')
      }
     }
      
     
    } catch (error) {
      console.log('ready to auth error', error)
    }
    
    
   //er()
  
}

const getBenficiary = React.useCallback(async(id) => {
  try {
   
    const beneficiaryReceived = await API.graphql({ query: queries.listBeneficiaries, variables: { filter: {
      userId: {
          eq: props.otp.user.id
      }
    }}});
    console.log('useer', beneficiaryReceived.data.listBeneficiaries.items)
    props.setBeneficary(beneficiaryReceived.data.listBeneficiaries.items)
  } catch (error) {
    console.log('ready to auth error', error)
  }
},[props.otp.user]);

const getDependants = React.useCallback(async(id) => {
  try {
    console.log('user get dependants',  userId)
    const dependantsReceived = await API.graphql({ query: queries.listDependants, variables: { filter: {
      userId: {
          eq: props.otp.user.id
      }
    }}});
   
    props.setDependants(dependantsReceived.data.listDependants.items)
  } catch (error) {
    console.log('ready to auth error', error)
  }
},[props.otp.user]);

const getPayments = React.useCallback(async(id) => {
  try {
    const paymentsReceived = await API.graphql({ query: queries.listPayments, variables: { filter: {
      userId: {
          eq: props.otp.user.id
      }
    }}});
    props.setPayments(paymentsReceived.data.listPayments.items)
  } catch (error) {
    console.log('ready to auth error', error)
  }
},[props.otp.user]);

const getPayout = React.useCallback(async(id) => {
  try {
    const payoutsReceived = await API.graphql({ query: queries.listPayouts, variables: { filter: {
      userId: {
          eq: props.otp.user.id
      }
    }}});
    props.setPayouts(payoutsReceived.data.listPayouts.items)
  } catch (error) {
    console.log('ready to auth error', error)
  }
},[props.otp.user]);

const getPlans = React.useCallback(async(id) => {
  try {
    const plansReceived = await API.graphql({ query: queries.listUserPlans, variables: { filter: {
      userId: {
          eq: props.otp.user.id
      }
    }}});
    console.log('set plan 5', plansReceived.data)
    props.setPlan(plansReceived.data.listUserPlans.items[0])
  } catch (error) {
    console.log('ready to auth error plan', error)
  }
},[props.otp.user]);

const getClaims = React.useCallback(async(id) => {
  try {
    console.log('ready to get claim', id)
    const claimsReceived=await API.graphql({ query: queries.listClaims, variables: { filter: {
      userId: {
          eq: props.otp.user.id
      }
    }}});
    props.setClaims(claimsReceived.data.listClaims.items)
  } catch (error) {
    console.log('ready to auth error', error)
  }
},[props.otp.user]);

  const verifyAuth = () => {
    console.log('ready to auth 2')
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log('ready to auth 3', user)
        props.setUser(user);
        props.setUserName(user.username)
        props.setSession(null);
        retrieveUser(user)
      })
      .catch((err) => {
        console.error(err);
        changePage('/welcome')
      });
  };


  return (
    <div>
      {console.log('ppp', isAdmin )}
      <div style={{position:'absolute', top:70, left:0, width:'100%'}}> <Routes/></div>
      <div>
        <TopNav/> 
        <SideNav/>
      </div>
      

      <div style={{position:'fixed', bottom:0, width:'100%', zIndex:10}}>
        {console.log('XZC',props.otp.user)}
        {props.otp.user && props.otp.user.payments && props.otp.user.payments.items.length>0 && isAdmin==false && <Menu/>}
      </div>
      
      
      <ModalClaimSuccess/>
      {props.otp.adminUser && <ModalRegisterAdmin/>}
      {props.otp.adminUser && <ModalDependant/>}
      {props.otp.adminUser &&<ModalEditDependant/>}
      {props.otp.adminUser &&<ModalAddBenficiary/>}
      {props.otp.adminUser &&<ModalEditBenificiary/>}
      {props.otp.adminUser &&<ModalCreditCard/>}
      {props.otp.adminUser &&<ViewClaim/>}
      {props.otp.adminUser &&<ModalErrorA/>}
      <ModalError/>
      <ModalNotify/>
      <ModalSuccess/>
      <ModalCancelSuccess/>
      <div style={{position:'fixed', top:0, width:'100%', zIndex:1001}}>
        {props.viewReducer.showLoading && <LoadingData/>}
        
      </div>
    
     </div>    
  );
}
const mapStateToProps = state => ({
  otp: state.otpReducer,
  viewReducer: state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  toggleSideMenu: () => dispatch(toggleSideMenu()),
  setSession: (string) => dispatch(setSession(string)),
  setVerified: (string) => dispatch(setVerified(string)),
  setUser: (obj) => dispatch(setUser(obj)),
  setAdminUser: (obj) => dispatch(setAdminUser(obj)),
  setUserName: (obj) => dispatch(setUserName(obj)),
  setUserMain: (obj) => dispatch(setUserMain(obj)),
  setDependants: (obj) => dispatch(setDependants(obj)),
  setBeneficary: (obj) => dispatch(setBeneficary(obj)),
  setPayments: (obj) => dispatch(setPayments(obj)),
  setPayouts: (obj) => dispatch(setPayouts(obj)),
  setClaims: (obj) => dispatch(setClaims(obj)),
  setPlan: (obj) => dispatch(setPlan(obj))
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(App);
