/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ac4e99a0-c280-4532-8ee4-d3181e464097",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_psESX4GZL",
    "aws_user_pools_web_client_id": "429mmf1irpm6h4hclb9kou0su3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GENDER",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://6bz32jis82.execute-api.us-east-1.amazonaws.com/hurt",
            "region": "us-east-1"
        },
        {
            "name": "finalapi",
            "endpoint": "https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://wvqysnwu7bcvjilnayfcrjv6ri.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mbeb4idwcrb5pgcd2cdoyp5z7i",
    "aws_user_files_s3_bucket": "claimsbucket90419-hurt",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
