export const API_PWA_REQUEST = 'API_PWA_REQUEST';
export const API_PWA_SUCCESS = 'API_PWA_SUCCESS';
export const API_PWA_FAILURE = 'API_PWA_FAILURE';
export const RESET_PWA = 'RESET_PWA';

export const PWARequest = () => ({
  type: API_PWA_REQUEST,
});

export const PWASuccess = payload => ({
  type: API_PWA_SUCCESS,
  payload,
});

export const PWAFailure = payload => ({
  type: API_PWA_FAILURE,
  payload,
});

const initialState = {
  isFetched: false,
  fetching: false,
  error: null,
  PWAcurrent: '4.8.10',
  PWANew: '4.8.10',
};

export function PWAReducer(state = initialState, action) {
  switch (action.type) {
  
    case API_PWA_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case API_PWA_SUCCESS:
      return {
        ...state,
        isFetched: true,
        fetching: false,
        PWANew: action.payload,
      };
    case API_PWA_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        isFetched: true,
      };
    default:
      return state;
  }
}
