import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import createStore from './redux/store';

import { BrowserRouter as Router } from 'react-router-dom';

import history from "./history";
ReactDOM.render(
  <Provider store={createStore(window.__INITIAL_STATE__)}>
    <Router history={history}>
      <App />
    </Router>
    </Provider>,
  document.getElementById('root')
);

serviceWorker.register();