import React from 'react';

  import { usePaystackPayment } from 'react-paystack';
import { setUserName } from '../redux/reducers/otp';
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
  
  const config = {
      reference: (new Date()).getTime().toString(),
      email: "user@example.com",
      amount: 20000,
      currency: "ZAR",
      publicKey: 'pk_test_9e51c1550eaaf52040999ccabc1d53ff0835cf3b',
  };
  
  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log('Paystack',reference);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }

  const Paystack= () => {
      
      const initializePayment = usePaystackPayment(config);
      return (
        <div>
            <button onClick={() => {
                initializePayment(onSuccess, onClose)
            }}>Paystack Hooks Implementation</button>
        </div>
      );
  };

  const mapStateToProps = state => ({
    otpReducer: state.otpReducer,
  });
  
  const mapDispatchToProps = dispatch => ({
    setUserName: (string) => dispatch(setUserName(string)),
    
  })
  
  
  
  export default compose( connect(
    mapStateToProps,
    mapDispatchToProps,
  ),withRouter)(Paystack);
  
  