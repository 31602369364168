import * as React from "react";
import { PlasmicSelectA__OptionGroupA } from "./plasmic/copy_of_imbewu_admin/PlasmicSelectA__OptionGroupA";

function SelectA__OptionGroupA(props) {
  const { plasmicProps } = PlasmicSelectA__OptionGroupA.useBehavior(props);
  return <PlasmicSelectA__OptionGroupA {...plasmicProps} />;
}

export default Object.assign(SelectA__OptionGroupA, {
  __plumeType: "select-option-group"
});
