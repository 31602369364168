// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicImbewuHomeBase } from "./plasmic/imbewu_mobile_first/PlasmicImbewuHomeBase";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { toggleSideMenu } from "../redux/reducers/view";
import Testimonial from "./Testimonial";
import Flickity from "react-flickity-component";
function ImbewuHomeBase_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const testimonials = [{quote:'I used to struggle with call centers and email support for my funeral cover. But with Imbewu Stokvest™, I can see and change everything on my policy easily on my phone whenever I need to.',name:'Jabulani'},
{quote:'My annual pay-out is exactly what I need in January after a fun festive season. And I get it in my bank account without having to do anything',name: 'Resiloe'},
{quote:'I’ve always wanted a simple funeral cover product that is easy to understand. And that’s exactly what I’ve found with Imbewu Stokvest™',name:'Mmabath'}]

var flickityOptions = {
  initialIndex: 0,
  pageDots: true,
  prevNextButtons:false
}
  return <div className="content"><PlasmicImbewuHomeBase root={{ ref }} {...props} 
        className="content--inner"
          btnHow = {{
            onClick:(e) =>{
              changePage('/howto')
            }
          }}

          btnSignup2 = {{
            onClick:(e) =>{
              changePage('/register')
            }
          }}

          btnAbout = {{
            btnReadMore: {onClick:(e) =>{
              changePage('/about')
            }
            }
          }}


          btnSignup = {{
            onClick:(e) =>{
              changePage('/register')
            }
          }}

          testimonialHolder = {{
    
            children: <Flickity
            className={'carousel'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
            prevNextButtons= {false}
          >
            {testimonials.map((item, index)=>(
             <React.Fragment key={index}>
             <Testimonial
              name={item.name}
              quote={item.quote}
              subName = ' '
             />
         </React.Fragment>
          ))}
          </Flickity>
          }}

/></div>;
}

const ImbewuHomeBase = React.forwardRef(ImbewuHomeBase_);

const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  toggleSideMenu: () => dispatch(toggleSideMenu()),
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ImbewuHomeBase);
