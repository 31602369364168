export const SET_SESSION = 'SET_SESSION';
export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_VERIFIED = 'SET_VERIFIED';
export const SET_ADMIN_USER = 'SET_ADMIN_USER';
export const setSession = (payload) => ({
  type: SET_SESSION,
  payload
});

export const setUser = (payload) => ({
  type: SET_USER,
  payload
});
export const setAdminUser = (payload) => ({
  type: SET_ADMIN_USER,
  payload
});
export const getUser = (payload) => ({
  type: GET_USER,
  payload
});

export const setUserName = (payload) => ({
  type: SET_USERNAME,
  payload
});

export const setVerified = (payload) => ({
  type: SET_VERIFIED,
  payload
});



const initialState = {
  session: null,
  user: null,
  username:null,
  verified:null,
  getUser:false,
  adminUser:null
};

export function otpReducer(state = initialState, action) {
  console.log('LLL', action)
  switch (action.type) {
  
    case SET_SESSION:
      console.log('Session Reducer', action.payload)
      return {
        ...state,
        session: action.payload
      }
    case SET_USER:
      console.log('ready to auth user', action.payload)
      return {
        ...state,
        user: action.payload
      };
  case SET_ADMIN_USER:
      return {
        ...state,
        adminUser: action.payload
      };
    case GET_USER:
      return {
        ...state,
        getUser: action.payload
      };
     
      case SET_USERNAME:
        return {
          ...state,
          username: action.payload
        };
    case SET_VERIFIED:
      return {
        ...state,
        verified: action.payload
      };
    default:
      return state;
  }
}
