// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { showAdminClaimsModal } from "../redux/reducers/view";
import PaymentListItem from "./PaymentListItem";
import { PlasmicAdminPayouts } from "./plasmic/admin_imbewu/PlasmicAdminPayouts";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from "moment";
function AdminPayouts_(props, ref) {
 console.log('Payout X', props.view.payouts)
  return <PlasmicAdminPayouts root={{ ref }} {...props}
  nextPayment = {props.view.payments.length>0 && moment(props.view.payments[props.view.payments.length-1].createdAt).add(1, 'months').format('DD-MM-YYYY')}
  latestPayment = {props.view.payments.length>0 && moment(props.view.payments[props.view.payments.length-1].createdAt).add(0, 'months').format('DD-MM-YYYY')}
  latestPayout = {props.view.payments.length>0 && moment(props.view.payments[0].createdAt).add(1, 'years').format('DD-MM-YYYY')}
  scroller = {{
    children: props.view.payouts.map((item, index)=>(
      <React.Fragment key={index}>
        <PaymentListItem
        txtDate={new moment(item.createdAt).format('YYYY-MM-DD')}
        txtRef = {item.reference}
        txtAmount = {String('R'+props.otp.adminUser.plan.items[0].annualPayout)}
        status={item.status}
        />
      </React.Fragment>
   ))
  }}
  
  />;
}

const AdminPayouts = React.forwardRef(AdminPayouts_);


const mapStateToProps = state => ({
  otp: state.otpReducer,
  view: state.viewReducer
});


const mapDispatchToProps = dispatch => ({
  
  showAdminClaimsModal: (string) => dispatch(showAdminClaimsModal(string)),
 
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(AdminPayouts);

