// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicAdminRequests } from "./plasmic/admin_imbewu/PlasmicAdminRequests";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import ListUsers from "./ListUsers";
import * as queries from '../graphql/queries';

import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setBeneficary, setClaims, setDependant, setDependants, setPayments, setPayouts, setPlan, setUserMain, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import { setAdminUser, setSession, setUser, setUserName, setVerified } from "../redux/reducers/otp";


function AdminRequests_(props, ref) {
  const [users, setUsers] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(null);
  
  React.useEffect(() => {
   listAllUsers()
   
  }, []);

  const onChangeSearch = React.useCallback((e) => {
    console.log(e)
    setSearchTerm(e);
  },[searchTerm])

 

  const listAllUsers = React.useCallback(async(id) => {
    try {
     
      const usersReceived = await API.graphql({ query: queries.listUsers});
      console.log('useer', usersReceived)
      var userArray = []
      for (let index = 0; index < usersReceived.data.listUsers.items.length; index++) {
        const element = usersReceived.data.listUsers.items[index];
        const userReceived = await API.graphql(graphqlOperation(queries.getUser, { id:element.id }));
        console.log('useer2', userReceived.data.getUser)
        for (let j = 0; j < userReceived.data.getUser.claims.items.length; j++) {
          const element2 = userReceived.data.getUser.claims.items[j];
          if(element2.status=="Submitted"){
            userArray.push(element)
          }
        }
        
      }
      setUsers(userArray)
    } catch (error) {
      console.log('ready to auth error', error)
    }
  },[]);

  // async function listAllUsers(limit){
  //   let apiName = 'AdminQueries';
  //   let path = '/listUsersInGroup';
  //   let myInit = { 
  //       queryStringParameters: {
  //         "groupname": "shaunusers",
  //         "limit": limit,
  //         "token": nextToken
  //       },
  //       headers: {
  //         'Content-Type' : 'application/json',
  //         Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
  //       }
  //   }
  //   const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
  //   nextToken = NextToken;

  //   console.log('admin', rest.Users)
  //   setUsers(rest.Users);
  //   props.showLoadingModal(false)
  // }
  async function searchUsers(){
var arr = []
    for (let index = 0; index < users.length; index++) {
      const element = users[index];
      if(searchTerm==element.email){
        arr.push(element)
      }
    }
    setUsers(arr)
    
  }

  function getName(attributes){
    let firstName
    let lastName
    for (let index = 0; index < attributes.length; index++) {
      const element = attributes[index];
      if(element.Name=='name'){
        firstName = element.Value
      }
      if(element.Name=='family_name'){
        lastName = element.Value
      }
      
    }
    return firstName+' '+lastName
  }

  function getCell(attributes){
    let cell
   
    for (let index = 0; index < attributes.length; index++) {
      const element = attributes[index];
      if(element.Name=='phone_number'){
        cell = element.Value
      }
     
      
    }
    return cell
  }

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  async function showUser(user){
    props.showLoadingModal(true)
    const userReceived = await API.graphql(graphqlOperation(queries.getUser, { id:user.id }));
      
     
      var loggedinUser =userReceived.data.getUser;
      props.setUser(userReceived);
      props.setSession(null);
      props.setVerified(true)
      console.log('admin user get',loggedinUser)
      props.setDependants(loggedinUser.dependants.items)
      props.setBeneficary(loggedinUser.beneficiaries.items)
      props.setPayouts(loggedinUser.account.items)
      props.setPayments(loggedinUser.payments.items)
      props.setClaims(loggedinUser.claims.items)
      props.setPlan(loggedinUser.plan.items[0])
      props.setUser(loggedinUser);
      props.showLoadingModal(false)
      changePage('/adminuserprofile/'+user.Username)
      
  }
  return <PlasmicAdminRequests root={{ ref }} {...props} 

      navPageTitle = {{
       title:"Dashboard"
      }} 

      inputSearch = {{
        txtValue : {
          onChange: (e) => onChangeSearch(e.target.value),
          value: searchTerm,
          placeHolder: 'Search by email'
        }
      }}



      btnSearch = {{
        onClick:(e)=>{
          searchUsers()
        }
      }}

      userList = {{
          children: users.map((item, index)=>(
            <React.Fragment key={index}>
              <ListUsers 
              nameTxt = {item.firstName +' '+item.lastName} 
              idTxt = {item.email}
              btnEdit = {{
                onClick:(e)=>{
                  showUser(item)
                }
              }}
              />
            </React.Fragment>
         ))
        }}
  
  />;
}

const AdminRequests = React.forwardRef(AdminRequests_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  showErrorModal: (obj) => dispatch(showErrorModal(obj)),
  setSession: (string) => dispatch(setSession(string)),
  setVerified: (string) => dispatch(setVerified(string)),
  setUser: (obj) => dispatch(setAdminUser(obj)),
  setUserName: (obj) => dispatch(setUserName(obj)),
  setUserMain: (obj) => dispatch(setUserMain(obj)),
  setDependants: (obj) => dispatch(setDependants(obj)),
  setBeneficary: (obj) => dispatch(setBeneficary(obj)),
  setPayments: (obj) => dispatch(setPayments(obj)),
  setPayouts: (obj) => dispatch(setPayouts(obj)),
  setClaims: (obj) => dispatch(setClaims(obj)),
  setPlan: (obj) => dispatch(setPlan(obj))
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(AdminRequests);

