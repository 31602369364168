import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../utils/ScrollToTop';
import About from './About';
import AddBeneficiary from './AddBeneficiary';
import Contact from './Contact';
import Dashboard from './Dashboard';
import Faq from './Faq';
import ForgottenOtp from './ForgottenOTP';
import ForgottenPasssword from './ForgottenPasssword';
import Howto from './Howto';
import ImbewuHomeBase from './ImbewuHomeBase';
import Login from './Login';
import LoginOTP from './LoginOTP';
import Otp from './Otp';
import Plans from './Plans';
import Registration from './Registration';
import Signout from './Signout';
import Startup from './Startup';
import Register1Success from './Register1Success'
import Register2Secure from './Register2Secure'
import Register3ChoosePlan from './Register3ChoosePlan'
import Register4Payment from './Register4Payment'
import Register5AddPayout from './Register5AddPayout'
import Register6AccountSuccess from './Register6AccountSuccess'
import AddDependant from './AddDependant';
import EditDependant from './EditDependant';
import Payout from './Payout';
import Payment from './Payment';
import Claims1 from './Claims1';
import Claims2 from './Claims2';
import Claims3 from './Claims3';
import Paystack from './Paystack';
import ClaimList from './ClaimList';
import MyProfile from './MyProfile';
import EditBenificiary from './EditBenificiary';
import PayoutAfter from './PayoutAfter';
import PlansBenfits from './PlansBenfits';
import AdminDashboard from './AdminDashboard';
import AdminUserPageUserProfile from './AdminUserPageUserProfile';
import AdminRequests from './AdminRequests';
import AdminPayoutPage from './AdminPayoutPage';
import ModalPaymentOutcome from './ModalPaymentOutcome';
import Register4CardDetails from './Register4CardDetails';
import NewPassword from './NewPassword';
import ModalConfirmation from './ModalConfirmation';
import Register3ChoosePlanAfterCancel from './Register3ChoosePlanAfterCancel';
import LatePayment from './LatePayment';
import Register4PaymentResult from './Register4PaymentResult';
import Claims12 from './Claims12';


const Routes = () => (
  <ScrollToTop>
  <Switch>
    <Route path="/admindashboard" component={AdminDashboard} exact/>
    <Route path="/adminuserprofile/:id" component={AdminUserPageUserProfile} exact/>
    <Route path="/adminreqests" component={AdminRequests} exact/>
    <Route path="/adminpayouts" component={AdminPayoutPage} exact/>
    <Route path="/paystack" component={Paystack} exact/>
    <Route path="/welcome" component={ImbewuHomeBase} exact/>
    <Route path="/cancelClaim" component={ModalConfirmation} exact/>
    <Route path="/plans" component={Plans} exact/>
    <Route path="/carddetails/" component={Register4CardDetails} exact/>
    <Route path="/payouts" component={Payout} exact/>
    <Route path="/payments" component={Payment} exact/>
    <Route path="/paymentresult" component={Register4PaymentResult} exact/>
    <Route path="/claims" component={ClaimList} exact/>
    <Route path="/startclaim" component={Claims1} exact/>
    <Route path="/claimotp" component={Claims2} exact/>
    <Route path="/claimsubmitted" component={Claims3} exact/>
    <Route path="/howto" component={Howto} exact/>
    <Route path="/addbenificiary" component={AddBeneficiary} exact/>
    <Route path="/adddependant" component={AddDependant} exact/>
    <Route path="/editdependant" component={EditDependant} exact/>
    <Route path="/editbeneficiary" component={EditBenificiary} exact/>
    <Route path="/contact" component={Contact} exact/>
    <Route path="/about" component={About} exact/>
    <Route path="/plansbenefits" component={PlansBenfits} exact/>
    <Route path="/faq" component={Faq} exact/>    
    <Route path="/pin" component={Otp} exact/>    
    <Route path="/forgotOTP" component={ForgottenOtp} exact/>    
    <Route path="/loginpin" component={LoginOTP} exact/>   
    <Route path="/forgot" component={ForgottenPasssword} exact/>
    <Route path="/newpassword" component={NewPassword} exact/>
    <Route path="/login" component={Login} />
    <Route path="/home" component={Dashboard} />
    <Route path="/register" component={Registration} />
    <Route path="/success" component={Register1Success} />
    <Route path="/secure" component={Register2Secure} />
    <Route path="/chooseplan" component={Register3ChoosePlan} />
    <Route path="/payment" component={Register4Payment} />
    <Route path="/payout" component={Register5AddPayout} />
    <Route path="/payoutafter" component={PayoutAfter} />
    <Route path="/profile" component={MyProfile} />
    <Route path="/accountsuccess" component={Register6AccountSuccess} />
    <Route path="/plancancelled" component={Register3ChoosePlanAfterCancel} />
    <Route path="/latepayment" component={LatePayment} />

    <Route path="/logout" exact component={Signout} />
    <Route path="/" exact component={Startup} />
  </Switch>
  </ScrollToTop>
 
);

export default Routes;
