// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicRegistration } from "./plasmic/imbewu_mobile_first/PlasmicRegistration";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import { setSession, setUser, setUserName } from "../redux/reducers/otp";
import { showErrorModal, showLoadingModal } from "../redux/reducers/view";
import Select__Option from "./Select__Option";
import { API } from 'aws-amplify'
import { createUser } from '../graphql/mutations'
Amplify.configure(awsconfig);


function Registration_(props, ref) {
  const inputElement1 = React.useRef(null);
  const inputElement2 = React.useRef(null);
  const inputElement3 = React.useRef(null);
  const inputFirstName = React.useRef(null);
  const inputLastName = React.useRef(null);
  const inputGender = React.useRef(null);
  const inputCell = React.useRef(null);
  const inputEmail = React.useRef(null);
  const [cellNumber1, setCellNumber1] = React.useState(null);
  const [cellNumber2, setCellNumber2] = React.useState(null);
  const [cellNumber3, setCellNumber3] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [valid, setValid] = React.useState(true);
  
  
  const [gender, setGender] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [errorFirstNameTxt, setErrorFirstNameTxt] = React.useState(true);
  const [errorFirstName, setErrorFirstName] = React.useState(false);
  const [errorLastNameTxt, setErrorLastNameTxt] = React.useState(true);
  const [errorLastName, setErrorLastName] = React.useState(false);
  const [errorGender, setErrorGender] = React.useState(true);
  const [errorGenderTxt, setErrorGenderTxt] = React.useState("");
  const [errorCell, setErrorCell] = React.useState(true);
  const [errorCellTxt, setErrorCellTxt] = React.useState("");
  const [email, setEmail] = React.useState(null);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorEmailTxt, setErrorEmailTxt] = React.useState(true);
  const [errorPasswordTxt, setErrorPasswordTxt] = React.useState("");
  const [errorPassword, setErrorPassword] = React.useState(true);
  const [errorConfirmPassword, setErrorConfirmPassword] = React.useState(true);
  const [errorConfirmPasswordTxt, setErrorConfirmPasswordTxt] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const onChangeCell1 =  React.useCallback((e) => {
    if(e.length<3){
      setCellNumber1(e); 
    }
    console.log('tab', e.length)
    if(e.length==2){
     inputElement2.current.focus()
    }
   
  },[cellNumber1])

  const onChangeCell2 =  React.useCallback((e) => {
    if(e.length<4){
    setCellNumber2(e); 
    }
    if(e.length==3){
      inputElement3.current.focus()
     }
  },[cellNumber2])

  const onChangeCell3 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber3(e); 
    }
  },[cellNumber3])
  
  const onChangeShowPassword=  React.useCallback((e) => {
    console.log('LLL', showPassword)
    setShowPassword(!showPassword); 
  },[showPassword])

  const onChangeShowConfirmPassword=  React.useCallback((e) => {
    console.log('LLL2', showConfirmPassword)
    setShowConfirmPassword(!showConfirmPassword); 
  },[showConfirmPassword])


  const onChangeFirstName = React.useCallback((e) => {
    setFirstName(e);
  },[firstName])

  const onChangeLastName =  React.useCallback((e) => {
    console.log('LLL4', firstName)
    setLastName(e);
  },[lastName])

  const onChangeEmail = React.useCallback((e) => {
    setEmail(e); 
  },[email])



  const onChangeGender =  React.useCallback((e) => {
    setGender(e);
  },[gender])

  const onChangePassword =  React.useCallback((e) => {
    setPassword(e);
  },[password])


  const onChangeConfirmPassword =  React.useCallback((e) => {
    setConfirmPassword(e);
  },[confirmPassword])



  const validate =  React.useCallback((e) => {
    
     if(firstName==null || firstName.length<2){
      setErrorFirstNameTxt('Required')
      window.scrollTo(0, inputFirstName.current.offsetTop)
      setErrorFirstName(true)
      return false
    }else{
      setErrorFirstName(false)
    }
    if(lastName==null || lastName.length<2){
      
      setErrorLastNameTxt('Required')
      window.scrollTo(0, inputLastName.current.offsetTop)
      setErrorLastName(true)
      return false
    }else{
      setErrorLastNameTxt(false)
    }
    console.log('GEnder', gender)
    if(gender==null){
     
      setErrorGenderTxt('Required')
      setErrorGender(false)
      window.scrollTo(0, inputGender.current.offsetTop)
      return false
    }else{
      setErrorGender(true)
    }
    if(cellNumber1== null || cellNumber2==null || cellNumber3==null || (cellNumber1+cellNumber2+cellNumber3).length<9){
      
      setErrorCellTxt('A Valid cell number is required')
      setErrorCell(false)
      return false
    }else{
      setErrorCell(true)
    }

 
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
     
      setErrorEmail(false)
    }else{
      setErrorEmailTxt('A valid email address is required')
      setErrorEmail(true)
      return false
    }
 
    if (password &&
      /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password)
      ) {
        setErrorPassword(true) 
    }else{
     
      setErrorPasswordTxt('Invalid password. Minimum eight characters, at least one letter, one number and one special character'   )   
      setErrorPassword(false)       
      return false
    }
  
    if(confirmPassword==null || confirmPassword!=password){
     
      setErrorConfirmPasswordTxt('Your passwords do not match')
      setErrorConfirmPassword(false)
      return false
    }else{
      setErrorConfirmPassword(true)
    }
    
    return true
  },[firstName, lastName, gender, password, confirmPassword, email, cellNumber1,cellNumber2,cellNumber3])

  


  const signIn = () => {
    console.log('Signing In')
    Auth.signIn({username:'+27'+cellNumber1+cellNumber2+cellNumber3, password: password,autoSignIn: {
      enabled: true
    }}
)
      .then((result) => {
        props.setSession(result)
        changePage('/pin')
        props.showLoadingModal(false)
      })
      .catch((e) => {
        props.showLoadingModal(false)
        if (e.code === 'UserNotFoundException') {
          props.showErrorModal('Error: '+e.code)
        } else if (e.code === 'UsernameExistsException') {
          props.showErrorModal('Error: '+e.code)
        } else {
          props.showErrorModal('Error: '+e.code)
        }
      });
     
  };


  const signUp = async () => {
    console.log('IS Valid', validate())
   if(validate()){

   props.showLoadingModal(true)
    const result = await Auth.signUp({
      username: email,
      password,
      attributes: {
        phone_number: '+27'+cellNumber1+cellNumber2+cellNumber3,
        gender: gender,
        name: firstName,
        family_name: lastName,
        email: email

      },
      autoSignIn: {
        enabled: true
      }
    }).then((result) => {
      console.log('Reg', result)
      props.setUsername(email)
      sendRegistration(result.userSub)
    }).catch((e) => {
      console.log('ERROR', e)
      if (e.code === 'UsernameExistsException') {
        props.showErrorModal('Error: A user already exists with this cell number')
      } else {
        props.showErrorModal('Error: '+e.code)
        console.error(e);
      }
      props.showLoadingModal(false)
    }); 
    
    return result;
  }
  }
  //"1b7f68e8-e16d-4509-ada4-13172c582650"
  //e59a55cc-7330-4f99-93fe-fe17605eaf4d

  const sendRegistration = async(id) => {
    if(validate()){
    //  props.showLoadingModal(true)
      var name = firstName + ' ' + lastName;
      var cell = '+27'+cellNumber1+cellNumber2+cellNumber3
      var type = 'user'
      const user = await API.graphql({
        query: createUser,
        variables: {
          input: {
            id,
            firstName,
            lastName,
            email,
            cell,
            gender,
            type
          },
        },
      })
      console.log('USER@',user.data)
      props.setUser(user.data.createUser)
      props.showLoadingModal(false)
      changePage('/success')
    }
    
  }

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  return <PlasmicRegistration root={{ ref }} {...props} 
  inputCell = {{
    txtCell1: {
      onChange: (e) => onChangeCell1(e.target.value),
      value: cellNumber1,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:2,
      ref: inputElement1
    },
    txtCell2:{
      onChange: (e) => onChangeCell2(e.target.value),
      value: cellNumber2,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:3,
      ref: inputElement2
    },
    txtCell3:{
      onChange: (e) => onChangeCell3(e.target.value),
      value: cellNumber3,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxlLength:4,
      ref: inputElement3
    },
    txtError: errorCellTxt,
    showError:errorCell
  }}
  
  inputFirstName = {{
    txtValue : {
      onChange: (e) => onChangeFirstName(e.target.value),
      value: firstName,
      placeHolder: 'First Name'
    },
    txtError: errorFirstNameTxt,
    showError:errorFirstName,
    ref: inputFirstName
  }}
  inputLastName = {{
    txtValue : {
      onChange: (e) => onChangeLastName(e.target.value),
      value: lastName,
      placeHolder: 'Last Name'
    },
    showError:true,
    txtError: errorLastNameTxt,
    showError:errorLastName,
    ref: inputLastName
  }}

  inputEmail = {{
    txtValue : {
      onChange: (e) => onChangeEmail(e.target.value),
      value: email,
      placeHolder: 'Email address'
    },
    txtError: errorEmailTxt,
    showError:errorEmail,
    ref: inputEmail
  }}


  inputGender = {{
    selectTxt:'Gender',
    txtError: errorGenderTxt,
    showError:errorGender,
    ref: inputGender,
   selectGender: {
     children:  <React.Fragment>
   <Select__Option
     value={"male"}
   >
     {"Male"}
   </Select__Option>

   <Select__Option
     value={"female"}
   >
     {"Female"}
   </Select__Option>
 </React.Fragment>,
    onChange: (e) => onChangeGender(e),
    selectedContent: gender
  }}
  }

  inputPassword = {{
    txtValue : {
      onChange: (e) => onChangePassword(e.target.value),
      value: password,
      placeHolder: 'Password'
    },
    
    showPassword: showPassword,
    btnEye: {onClick:(e)=>onChangeShowPassword()},
    btnEyeClosed: {onClick:(e)=>onChangeShowPassword()},
    txtError: errorPasswordTxt,
    showError:errorPassword
  }}

  inputConfirm = {{
    txtValue : {
      onChange: (e) => onChangeConfirmPassword(e.target.value),
      value: confirmPassword,
      placeHolder: 'Confirm Password'
    },
    showPassword: showConfirmPassword,
    btnEye: {onClick:(e)=>onChangeShowConfirmPassword()},
    btnEyeClosed: {onClick:(e)=>onChangeShowConfirmPassword()},
    txtError: errorConfirmPasswordTxt,
    showError:errorConfirmPassword
  }}

  
    submitBtn = {{
      onClick: (e) => {
        signUp()
      }
    }}
    backBtn = {{
      onClick: (e) => {
        changePage('/login')
      }
    }}
  />;
}

const Registration = React.forwardRef(Registration_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setUsername: (string) => dispatch(setUserName(string)),
  setUser: (obj) => dispatch(setUser(obj)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string))
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Registration);
