// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { showAdminClaimsModal, showAdminCreditCard } from "../redux/reducers/view";
import PaymentListItem from "./PaymentListItem";
import { PlasmicAdminPayments } from "./plasmic/admin_imbewu/PlasmicAdminPayments";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from "moment";
function AdminPayments_(props, ref) {
  
  return <PlasmicAdminPayments root={{ ref }} {...props}
  nextPayment = {props.view.payments.length>0 && moment(props.view.payments[props.view.payments.length-1].createdAt).add(1, 'months').format('DD-MM-YYYY')}
  latestPayment = {props.view.payments.length>0 && moment(props.view.payments[props.view.payments.length-1].createdAt).add(0, 'months').format('DD-MM-YYYY')}
  latestPayout = {props.view.payments.length>0 && moment(props.view.payments[0].createdAt).add(1, 'years').format('DD-MM-YYYY')}
  addPayment = {{
    onClick:(e)=>{
      console.log('Pay')
      props.showAdminCreditCard(true)
    }
  }}
  scroller = {{
    children: props.view.payments.map((item, index)=>(
      <React.Fragment key={index}>
        {console.log('Payout X',item.status)}
        <PaymentListItem
        txtDate={new moment(item.paymentDate).format('YYYY-MM-DD')}
        txtRef = {item.reference}
        txtAmount = {String('R'+item.amount)}
        status={item.status}
        />
      </React.Fragment>
   ))
  }}
  
  />;
}

const AdminPayments = React.forwardRef(AdminPayments_);


const mapStateToProps = state => ({
  otp: state.otpReducer,
  view: state.viewReducer
});


const mapDispatchToProps = dispatch => ({
  
  showAdminCreditCard: (string) => dispatch(showAdminCreditCard(string)),
 
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(AdminPayments);

