// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useCallback} from "react"; 
import { setUserView, toggleSideMenu } from "../redux/reducers/view";
import { PlasmicSideNavA } from "./plasmic/admin_imbewu/PlasmicSideNavA";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';

function SideNavA_(props, ref) {
 
  const changePage =  useCallback((e) => {

    props.history.push(e);
   
  }, []);

  return <PlasmicSideNavA root={{ ref }} {...props}
           userBtn = {{
             onClick:(e)=>{
               console.log('admin Change')
               props.setUserView('userProfileTab')
               changePage('/admindashboard')
             }
           }}
           requestsBtn = {{
            onClick:(e)=>{
              console.log('admin Change')
              changePage('/adminreqests')
            }
          }}
          paymentBtn = {{
            onClick:(e)=>{
              console.log('admin Change')
              changePage('/adminpayouts')
            }
          }}
          signout = {{
            onClick:(e)=>{
              console.log('admin Change')
              changePage('/logout')
            }
          }}
         
        />
  
}

const SideNavA = React.forwardRef(SideNavA_);

const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  toggleSideMenu: () => dispatch(toggleSideMenu()),
  setUserView:(string)=>dispatch(setUserView(string))
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(SideNavA);


