
import * as React from "react";
import { PlasmicRegister1Success } from "./plasmic/imbewu_mobile_first/PlasmicRegister1Success";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import { setSession, setUser, setVerified } from "../redux/reducers/otp";
import { setStep, showErrorModal, showNotifyModal } from "../redux/reducers/view";

Amplify.configure(awsconfig);

function Register1Success_(props, ref) {
  const [pin, setPin] = React.useState(null);

  const resendConfirmationCode= async() => {
    try {
        await Auth.resendSignUp(props.otpReducer.username);
        console.log('code resent successfully');
        props.showNotifyModal('Your one time password has been resent.')
    } catch (err) {
        console.log('error resending code: ', err);
    }
}

  const verifyOtp = () => {
    console.log('Verifying pin:',  pin)
    console.log('With Session:', props.otpReducer.username)
    // Auth.sendCustomChallengeAnswer(props.otpReducer.session, pin)
    //   
    try {
      Auth.confirmSignUp(props.otpReducer.username, pin)
      .then((user) => {
            
            changePage('/secure');
            props.setStep(2)
            props.setVerified(true)
          })
          .catch((err) => {
            props.showErrorModal('Error: Incorrect pin')
            setPin('');
            console.log(err);
          });
    } catch (error) {
        console.log('error confirming sign up', error);
    }
  };
  

  const onChangePin =  React.useCallback((e) => {
    console.log(e)
    setPin(e);
  },[])

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  let cellMessage = 'Please enter the one time pin sent to the email address '+props.otpReducer.username
  return <PlasmicRegister1Success root={{ ref }} {...props} 
            submitBtn = {{
              onClick: (e) => {
                verifyOtp()
              }
            }}
            txtMessage = {cellMessage}

            otp = {{
              txtValue : {
                onChange: (e) =>{
                  onChangePin(e.target.value)
                },
                placeHolder: 'One Time Pin',
                value: pin,
              },
             
              showError:false
            }}

            btnResendOtp = {{
              onClick: (e) => {
                console.log('RESEND')
                resendConfirmationCode()
              }
            }}
           
            
  />;
}

const Register1Success = React.forwardRef(Register1Success_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setVerified: (string) => dispatch(setVerified(string)),
  setSession: (string) => dispatch(setSession(string)),
  setUser: (obj) => dispatch(setUser(obj)),
  setStep: (obj) => dispatch(setStep(obj)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showNotifyModal:(string) => dispatch(showNotifyModal(string))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps), 
  withRouter)(Register1Success) ;
