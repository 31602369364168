// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicAdminDependants } from "./plasmic/admin_imbewu/PlasmicAdminDependants";

import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setDependant, setDependants, showAdminDependantModal, showAdminDependantModalEdit, showLoadingModal } from "../redux/reducers/view";
import moment from "moment";
import ListUsers from "./ListUsers";
import ListItemDependentBeneficicaryA from "./ListItemDependentBeneficicaryA";
import { API } from 'aws-amplify'
import { deleteDependant } from '../graphql/mutations'
import * as queries from '../graphql/queries';
import { onDependantDelete } from "../graphql/subscriptions";
function AdminDependants_(props, ref) {
  const [subscription, setSubscription] = React.useState(null);
  React.useEffect(() => {
    if(subscription==null){
      setSubscription(API.graphql({
        query: onDependantDelete,
        variables: {
          userId: props.otp.adminUser.id
        }
      })
      .subscribe({
        next: data => {
          console.log('LISTEN dependant delete',props.otp)
          getDependants(props.otp.adminUser.id)
        }
      }))
    }
    
   
  }, [subscription]);
  
  const getDependants = React.useCallback(async(id) => {
    try {
      
      const dependantsReceived = await API.graphql({ query: queries.listDependants, variables: { filter: {
        userId: {
            eq: props.otp.adminUser.id
        }
      }}});
      console.log('user get dependants2', dependantsReceived.data.listDependants.items)
      props.setDependants(dependantsReceived.data.listDependants.items)
      subscription.unsubscribe()
    } catch (error) {
      console.log('ready to auth error', error)
    }
  },[props.otp.adminUser.id]);
 
  const removeDependant = async(item) => {
    
    props.showLoadingModal(true)
    const user = await API.graphql({
      query: deleteDependant,
      variables: {
        input: {
          id: item.id
        },
      },
    })
    getDependants(props.otp.adminUser.id)
    props.showLoadingModal(false)
   //retrieveUser()
  }
  return <PlasmicAdminDependants root={{ ref }} {...props} 
  nextPayment = {props.view.payments.length>0 && moment(props.view.payments[props.view.payments.length-1].createdAt).add(1, 'months').format('DD-MM-YYYY')}
  latestPayment = {props.view.payments.length>0 && moment(props.view.payments[props.view.payments.length-1].createdAt).add(0, 'months').format('DD-MM-YYYY')}
  latestPayout = {props.view.payments.length>0 && moment(props.view.payments[0].createdAt).add(1, 'years').format('DD-MM-YYYY')}
      addDependant = {{
        onClick:(e)=>{
            props.showAdminDependantModal(true)
        }
      }}
      scroller = {{
        children: props.view.dependants.map((item, index)=>(
          <React.Fragment key={index}>
            <ListItemDependentBeneficicaryA
            nameTxt = {item.firstName +' '+item.lastName} 
            btnEdit = {{
              onClick:(e)=>{
                console.log('admin show edit')
                props.setDependant(item)
                props.showAdminDependantModalEdit(true)
              }
             
            }}
            btnDelete = {{
              onClick:(e)=>{
                removeDependant(item)
              }
            }}
            />
          </React.Fragment>
       ))
      }}
  
  />;
}

const AdminDependants = React.forwardRef(AdminDependants_);

const mapStateToProps = state => ({
  otp: state.otpReducer,
  view: state.viewReducer
});


const mapDispatchToProps = dispatch => ({
  
  showAdminDependantModal: (string) => dispatch(showAdminDependantModal(string)),
  setDependant: (obj) => dispatch(setDependant(obj)),
  showAdminDependantModalEdit:(bool)=>dispatch(showAdminDependantModalEdit(bool)),
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  setDependants: (obj) => dispatch(setDependants(obj)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(AdminDependants);