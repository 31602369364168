// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { hideErrorModal, setClaims, showAdminClaimsModal, showAdminDecline, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import { PlasmicModalErrorA } from "./plasmic/copy_of_imbewu_admin/PlasmicModalErrorA";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { API,graphqlOperation } from 'aws-amplify'
import { updateClaim} from "../graphql/mutations";
import { onClaimUpdate } from "../graphql/subscriptions";
import * as queries from '../graphql/queries';

function ModalErrorA_(props, ref) {
  const [subscription, setSubscription] = React.useState(null);
  const inputElement1 = React.useRef(null);
  const [reason, setReason] = React.useState(null);

  React.useEffect(() => {
  
    if(subscription==null){
      console.log('Listen benef setup')
      try {
        setSubscription(API.graphql({
          query: onClaimUpdate,
          variables: {
            userId: props.otpReducer.adminUser.id
          }
        })
        .subscribe({
          next: data => {
            console.log('LISTEN benef create',props.otpReducer)
            getBeneficiaries(props.otpReducer.adminUser.id)
          }
        }))
      } catch (error) {
        
      }
      
    }
    
   
  }, [subscription]);

  const getBeneficiaries = React.useCallback(async(id) => {
    try {
      
      const dependantsReceived = await API.graphql({ query: queries.listClaims, variables: { filter: {
        userId: {
            eq: props.otpReducer.adminUser.id
        }
      }}});
      console.log('admin get benef', dependantsReceived.data.listClaims.items)
      props.setClaims(dependantsReceived.data.listClaims.items);
     
      subscription.unsubscribe()
    } catch (error) {
      console.log('ready to auth error', error)
    }
  },[props.otpReducer.adminUser.id]);

  const updateClaimNow = async() => {
   
    
      props.showLoadingModal(true)
    
       const claim = await API.graphql({
         query: updateClaim,
         variables: {
           input: {
             id:props.viewReducer.claim.id,
             status:"failure",
             reasonForDecline:reason
           },
         },
       })
       getBeneficiaries(props.otpReducer.adminUser.id)
       props.showLoadingModal(false)
      props.showAdminDecline(false)
      props.showAdminClaimsModal(false)
     
 };
  

  const onChangeReason = React.useCallback((e) => {
    console.log('TEst', e)
    setReason(e);
  },[reason])

  
  return <div>
    {props.viewReducer.showDeclineModal ? <div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}>
    <PlasmicModalErrorA root={{ ref }} {...props} 
      txtReason = {{
        onChange: (e) => onChangeReason(e.target.value),
        value: reason,
      }}
      btnClose = {{
        onClick: (e) => {
          props.showAdminDecline(false)
        }
      }}
      btnDecline = {{
        onClick: (e) => {
         updateClaimNow()
        }
      }}
    />;
  </div> : null}
  </div>
}

const ModalErrorA = React.forwardRef(ModalErrorA_);
const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otpReducer:state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  hideErrorModal: () => dispatch(hideErrorModal()),
  showAdminDecline:(string) => dispatch(showAdminDecline(string)),
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  setClaims: (obj) => dispatch(setClaims(obj)),
  showAdminClaimsModal: (bool) => dispatch(showAdminClaimsModal(bool)),
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalErrorA);

