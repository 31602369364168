// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicForgottenPasssword } from "./plasmic/imbewu_mobile_first/PlasmicForgottenPasssword";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showErrorModal, showLoadingModal, toggleSideMenu } from "../redux/reducers/view";
import { setSession, setUserName } from "../redux/reducers/otp";

import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

function ForgottenPasssword_(props, ref) {
  const inputElement1 = React.useRef(null);
  const inputElement2 = React.useRef(null);
  const inputElement3 = React.useRef(null);
  const [cellNumber1, setCellNumber1] = React.useState(null);
  const [cellNumber2, setCellNumber2] = React.useState(null);
  const [cellNumber3, setCellNumber3] = React.useState(null);
  const [errorCell, setErrorCell] = React.useState(true);
  const [email, setEmail] = React.useState(null);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorEmailTxt, setErrorEmailTxt] = React.useState(true);

  const onChangeEmail = React.useCallback((e) => {
    setEmail(e); 
  },[email])
  
  const goBack = ()=>{
    props.history.goBack()
  }

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const onChangeCell1 =  React.useCallback((e) => {
    if(e.length<3){
      setCellNumber1(e); 
    }
    console.log('tab', e.length)
    if(e.length==2){
     inputElement2.current.focus()
    }
   
  },[cellNumber1])

  const onChangeCell2 =  React.useCallback((e) => {
    if(e.length<4){
    setCellNumber2(e); 
    }
    if(e.length==3){
      inputElement3.current.focus()
     }
  },[cellNumber2])

  const onChangeCell3 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber3(e); 
    }
  },[cellNumber3])

  const validate =  React.useCallback((e) => {
    
   
  console.log('Forgot',email)
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
     
    setErrorEmail(false)
  }else{
    setErrorEmailTxt('A valid email address is required')
    setErrorEmail(true)
    return false
  }
  
   return true;

 },[email])

 async function handleSendCodeClick(event) {
   if(validate()){
     console.log('LLLL')
     props.setUsername(email)
    props.showLoadingModal(true)
    try {
      
      await Auth.forgotPassword(email);
      props.showLoadingModal(false)
      changePage('/forgotOTP')
    } catch (error) {
      
    }
   }
  
}

// cellNumber = {{
//   txtCell1: {
//     onChange: (e) => onChangeCell1(e.target.value),
//     value: cellNumber1,
//     type:'number',
//     inputMode:'numeric',
//     pattern:"[0-9]*",
//     maxLength:2,
//     ref: inputElement1
//   },
//   txtCell2:{
//     onChange: (e) => onChangeCell2(e.target.value),
//     value: cellNumber2,
//     type:'number',
//     inputMode:'numeric',
//     pattern:"[0-9]*",
//     maxLength:3,
//     ref: inputElement2
//   },
//   txtCell3:{
//     onChange: (e) => onChangeCell3(e.target.value),
//     value: cellNumber3,
//     type:'number',
//     inputMode:'numeric',
//     pattern:"[0-9]*",
//     maxlLength:4,
//     ref: inputElement3
//   },
//   txtError: errorCell,
//   showError:errorCell
// }}

  return <PlasmicForgottenPasssword root={{ ref }} {...props} 
  inputEmail = {{
    txtValue : {
      onChange: (e) => onChangeEmail(e.target.value),
      value: email,
      placeHolder: 'Email address'
    },
    txtError: errorEmailTxt,
    showError:errorEmail,

  }}
            btnBack =  {{
              onClick:(e) => {
                goBack();  
              }
            }}
            submitBtn = {{
              onClick:(e)=>{
                handleSendCodeClick(e)
              }
            }}
           
  />;
}

const ForgottenPasssword = React.forwardRef(ForgottenPasssword_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setSession: (string) => dispatch(setSession(string)),
  setUsername: (string) => dispatch(setUserName(string)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string))
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ForgottenPasssword);

