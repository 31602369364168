// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicHowto } from "./plasmic/imbewu_mobile_first/PlasmicHowto";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { toggleSideMenu } from "../redux/reducers/view";
function Howto_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  return <PlasmicHowto root={{ ref }} {...props} 

  slider={{
    btnSignup: {
      onClick:(e) =>{
        changePage('/register')
      }
    }
  }}
  
  />;
}

const Howto = React.forwardRef(Howto_);

const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  toggleSideMenu: () => dispatch(toggleSideMenu()),
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Howto);

