// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicModalAddBenficiary } from "./plasmic/imbewu_mobile_first/PlasmicModalAddBenficiary";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select__Option from "./Select__Option";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { API, sectionHeaderHint } from 'aws-amplify'
import {createBeneficiary } from '../graphql/mutations'
import { setBeneficary, showAdminBeneficiaryModal, showAdminBeneficiaryModalEdit, showDependantModal, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import { onBeneficiaryCreate } from "../graphql/subscriptions";
import * as queries from '../graphql/queries';
import axios from 'axios'
import qs from 'qs'

function ModalAddBeneficiary_(props, ref) {
  const [subscription, setSubscription] = React.useState(null);
  const inputElement1 = React.useRef(null);
  const inputElement2 = React.useRef(null);
  const inputElement3 = React.useRef(null);
  const inputFirstName = React.useRef(null);
  const inputLastName = React.useRef(null);
  const inputGender = React.useRef(null);
  const [monthReal, setMonthReal] = React.useState(null);
  const [cellNumber1, setCellNumber1] = React.useState(null);
  const [cellNumber2, setCellNumber2] = React.useState(null);
  const [cellNumber3, setCellNumber3] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [codeTxt, setCode] = React.useState(null);
  const [errorCodeTxt, setErrorCodeTxt] = React.useState(true);
  const [errorCode, setErrorCode] = React.useState(false);
  
  const [gender, setGender] = React.useState(null);
  
  const [errorFirstNameTxt, setErrorFirstNameTxt] = React.useState(true);
  const [errorFirstName, setErrorFirstName] = React.useState(false);
  const [errorLastNameTxt, setErrorLastNameTxt] = React.useState(true);
  const [errorLastName, setErrorLastName] = React.useState(false);
  const [errorGender, setErrorGender] = React.useState(true);

  const [idNumber, setSAId] = React.useState(null);
  const [errorSAId, setErrorSAId] = React.useState(false);
  const [errorSAIdTxt, setErrorSAIdTxt] = React.useState(null)

  
  const [errorDob, setErrorDob] = React.useState(false);
  const [errorDobTxt, setErrorDobTxt] = React.useState(true);
  const [errorCell, setErrorCell] = React.useState(true);

  const [isOpenYear, setIsOpenYear] = React.useState(false);
  const [isOpenMonth, setIsOpenMonth] = React.useState(false);
  const [isOpenDay, setIsOpenDay] = React.useState(false);

  const [startDate, setStartDate] = React.useState(new Date());
  const [year, setYear] = React.useState(null);
  const [month, setMonth] = React.useState(null);
  const [day, setDay] = React.useState(null);

  React.useEffect(() => {
  
    if(subscription==null){
      console.log('Listen benef setup')
      try {
        setSubscription(API.graphql({
          query: onBeneficiaryCreate,
          variables: {
            userId: props.otpReducer.adminUser.id
          }
        })
        .subscribe({
          next: data => {
            console.log('LISTEN benef create',props.otpReducer)
            getBeneficiaries(props.otpReducer.adminUser.id)
          }
        }))
      } catch (error) {
        
      }
      
    }
    
   
  }, [subscription]);

  const onChangeCode = React.useCallback((e) => {
    setCode(e);
  },[codeTxt])

  const getBeneficiaries = React.useCallback(async(id) => {
    try {
      
      const dependantsReceived = await API.graphql({ query: queries.listBeneficiaries, variables: { filter: {
        userId: {
            eq: props.otpReducer.adminUser.id
        }
      }}});
      console.log('admin get benef', dependantsReceived.data.listBeneficiaries.items)
      props.setBeneficary(dependantsReceived.data.listBeneficiaries.items);
     
      subscription.unsubscribe()
    } catch (error) {
      console.log('ready to auth error', error)
    }
  },[props.otpReducer.adminUser.id]);

  const onChangeCell1 =  React.useCallback((e) => {
    if(e.length<3){
      setCellNumber1(e); 
    }
    console.log('tab', e.length)
    if(e.length==2){
     inputElement2.current.focus()
    }
   
  },[cellNumber1])

  const onChangeCell2 =  React.useCallback((e) => {
    if(e.length<4){
    setCellNumber2(e); 
    }
    if(e.length==3){
      inputElement3.current.focus()
     }
  },[cellNumber2])

  const onChangeCell3 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber3(e); 
    }
  },[cellNumber3])
  
 


  const onChangeFirstName = React.useCallback((e) => {
    console.log('TEst', e)
    setFirstName(e);
  },[firstName])

  const onChangeLastName =  React.useCallback((e) => {
    console.log('LLL4', firstName)
    setLastName(e);
  },[lastName])





  const onChangeGender =  React.useCallback((e) => {
    setGender(e);
  },[gender])

 

  const onChangeSAId= React.useCallback((e) => {
    var yearSplit = String(e).substr(0,2)
    var monthSplit = String(e).substr(2,2)
    var daySplit = String(e).substr(4,2)
   
    if(parseInt(yearSplit) < 30){
      yearSplit = "20"+yearSplit
    }else{
      yearSplit = "19"+yearSplit
    }
    console.log('SAID', yearSplit, monthSplit, daySplit)
    setYear(yearSplit)
    setMonth(monthSplit)
    setDay(daySplit)
    setSAId(e); 
  },[idNumber])

  const handleYearOpen = () => {
    console.log('Year open')
    //setIsOpenYear(true)
  }
  
  const handleMonthOpen = () => {
    console.log('Year open')
    //setIsOpenMonth(true)
  }
  
  
  const handleDayOpen = () => {
  
    //setIsOpenDay(true)
  }
  
  
  const handleYear = (e) => {
    var d = moment(e).format('YYYY')
    console.log('Momenyt', d)
    setIsOpenYear(false)
    setYear(d)
  }
  const handleMonth = (e) => {
    var d = moment(e).format('MMM')
    var c = moment(e).format('MM')
    console.log('Momenyt', d)
    setIsOpenMonth(false)
    setMonth(d)
    setMonthReal(c)
  }
  const handleDay = (e) => {
    var d = moment(e).format('DD')
    console.log('Momenyt', d)
    setIsOpenDay(false)
    setDay(d)
  }




  const validate =  React.useCallback((e) => {
    
     if(firstName==null || firstName.length<2){
      setErrorFirstNameTxt('Required')
      window.scrollTo(0, inputFirstName.current.offsetTop)
      setErrorFirstName(true)
      return false
    }else{
      setErrorFirstName(false)
    }
    if(lastName==null || lastName.length<2){
      
      setErrorLastNameTxt('Required')
      window.scrollTo(0, inputLastName.current.offsetTop)
      setErrorLastName(true)
      return false
    }else{
      setErrorLastNameTxt(false)
    }
    if(cellNumber1== null || cellNumber2==null || cellNumber3==null || (cellNumber1+cellNumber2+cellNumber3).length<9){
      
      setErrorCell('A Valid cell number is required')
      return false
    }else{
      setErrorCell(true)
    }

    if(gender==null){
     
      setErrorGender('Required')
      window.scrollTo(0, inputGender.current.offsetTop)
      return false
    }else{
      setErrorGender(true)
    }

    if(!idNumber || !validateID()){
     
      setErrorSAIdTxt('Not a valid South African ID Number')
      setErrorSAId(true)
      return false
    }else{
      setErrorSAId(false)
    }
   
    if(!month || !year || !day){
    
      setErrorDobTxt('Please anter a valid birth date')
     setErrorDob(true)
     return false
    }else{
     setErrorDob(false)
    }

    var years = moment().diff(year+'-'+month+'-'+day, 'years');

    console.log('years', years)
   if(!month || !year || !day){
    
      setErrorDobTxt('Minimum age of the beneficiary must be 18.')
     setErrorDob(true)
     return false
    }else{
     setErrorDob(false)
    }
 
    
 
    

    
    return true
  },[firstName, lastName, gender, cellNumber1,cellNumber2,cellNumber3])

  

  const validate2 =  React.useCallback((e) => {
   
    if(firstName==null || firstName.length<2){
     setErrorFirstNameTxt('Required')
     window.scrollTo(0, inputFirstName.current.offsetTop)
     setErrorFirstName(true)
     return false
   }else{
     setErrorFirstName(false)
   }
   if(lastName==null || lastName.length<2){
     
     setErrorLastNameTxt('Required')
     window.scrollTo(0, inputLastName.current.offsetTop)
     setErrorLastName(true)
     return false
   }else{
     setErrorLastNameTxt(false)
   }
   if(cellNumber1== null || cellNumber2==null || cellNumber3==null || (cellNumber1+cellNumber2+cellNumber3).length<9){
     
     setErrorCell('A Valid cell number is required')
     return false
   }else{
     setErrorCell(true)
   }

   if(gender==null){
    
     setErrorGender('Required')
     window.scrollTo(0, inputGender.current.offsetTop)
     return false
   }else{
     setErrorGender(true)
   }

   if(!idNumber || !validateID()){
    
     setErrorSAIdTxt('Not a valid South African ID Number')
     setErrorSAId(true)
     return false
   }else{
     setErrorSAId(false)
   }
  
   if(!month || !year || !day){
   
     setErrorDobTxt('Please anter a valid birth date')
    setErrorDob(true)
    return false
   }else{
    setErrorDob(false)
   }

   var years = moment().diff(year+'-'+month+'-'+day, 'years');

   console.log('years', years)
  if(!month || !year || !day){
   
     setErrorDobTxt('Minimum age of the beneficiary must be 18.')
    setErrorDob(true)
    return false
   }else{
    setErrorDob(false)
   }

   

   

   
   return true
 },[firstName, lastName, gender, cellNumber1,cellNumber2,cellNumber3, codeTxt])

 

  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
  
  const  validateID = () => {
  
    // assume everything is correct and if it later turns out not to be, just set this to false
    var correct = true;

    //Ref: http://www.sadev.co.za/content/what-south-african-id-number-made
    // SA ID Number have to be 13 digits, so check the length
    if (idNumber.length != 13 || !isNumber(idNumber)) {
        correct = false;
    }

    // get first 6 digits as a valid date
    var tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));

    var id_date = tempDate.getDate();
    var id_month = tempDate.getMonth();
    var id_year = tempDate.getFullYear();

    var fullDate = id_date + "-" + id_month + 1 + "-" + id_year;

    if (!((tempDate.getYear() == idNumber.substring(0, 2)) && (id_month == idNumber.substring(2, 4) - 1) && (id_date == idNumber.substring(4, 6)))) {
        correct = false;
    }

    // get the gender
    var genderCode = idNumber.substring(6, 10);
    var gender = parseInt(genderCode) < 5000 ? "Female" : "Male";

    // get country ID for citzenship
    var citzenship = parseInt(idNumber.substring(10, 11)) == 0 ? "Yes" : "No";

    // apply Luhn formula for check-digits
    var tempTotal = 0;
    var checkSum = 0;
    var multiplier = 1;
    for (var i = 0; i < 13; ++i) {
        tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
        if (tempTotal > 9) {
            tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
        }
        checkSum = checkSum + tempTotal;
        multiplier = (multiplier % 2 == 0) ? 1 : 2;
    }
    if ((checkSum % 10) != 0) {
        correct = false;
    };
    return correct;
}

  const addBeneficary = async() => {
    if(validate()){
      props.showLoadingModal(true)
      const user = await API.graphql({
        query: createBeneficiary,
        variables: {
          input: {
            firstName: firstName,
            lastName: lastName,
            gender:gender,
            idNumber: parseInt(idNumber),
            dateOfBirth: year+'-'+month+'-'+day,
            contactNumber:'+27'+cellNumber1+cellNumber2+cellNumber3,
            userId: props.otpReducer.adminUser.id
          },
        },
      })
      getBeneficiaries(props.otpReducer.adminUser.id)
      resetFields()
      props.showLoadingModal(false)
      props.showAdminBeneficiaryModal(false)
      
    }
    
  }
  const sendCode = async()=>{
    console.log('wet 1',props.otpReducer.adminUser)
    var data = qs.stringify({
      'cell':props.otpReducer.adminUser.cell
    });
    var config = {
      method: 'post',
      url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/checkverifyhurt',
      headers: { 
        "x-api-key": process.env.API_IMBEWU2_GRAPHQLAPIKEYOUTPUT,
        'Content-Type': 'application/x-www-form-urlencoded', 
       },
       data:data
    };
   
      const test = await axios(config)
      .then(function (response) {
        //console.log('Pay',JSON.parse(response.data));
        //setPay(JSON.parse(response.data))
        props.showErrorModal("OTP Pin was sent successfuly")
      })
      .catch(function (error) {
        console.log('Pay error',error);
      });
      console.log('Wet', test)

  }

  const checkCode = async()=>{
    console.log('wet 1',props.otpReducer.adminUser)
    var data = qs.stringify({
      'cell':props.otpReducer.adminUser.cell,
      'code':codeTxt
    });
    var config = {
      method: 'post',
      url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/checkcodehurt',
      headers: { 
        "x-api-key": process.env.API_IMBEWU2_GRAPHQLAPIKEYOUTPUT,
        'Content-Type': 'application/x-www-form-urlencoded', 
       },
       data:data
    };
   
      const test = await axios(config)
      .then(function (response) {
        console.log('Cehck Code',response.data);
        if(response.data.status=='approved'){
          addBeneficary()
        }else{
          props.showErrorModal('Invalid Pin')
        }
  
      })
      .catch(function (error) {
        console.log('Pay error',error);
      });
      console.log('Wet', test)

  }

  const resetFields = React.useCallback(() =>{
    console.log('RESET')
      setLastName('');
      setFirstName('');
      setMonth('');
      setYear('');
      setSAId('')
      setDay('');
      setCellNumber1('');
      setCellNumber2('');
      setCellNumber3('');
      setCode('')
  },[firstName, lastName, gender, cellNumber1, year, month, day, cellNumber2, cellNumber3, codeTxt])




  
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  
  return <div>{props.view.showAdminBeneficiaryModal ?<div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}><PlasmicModalAddBenficiary root={{ ref }} {...props} 
  code = {{
    txtValue : {
      onChange: (e) => onChangeCode(e.target.value),
      value: codeTxt,
      placeHolder: 'OTP Pin'
    },
    txtError: errorCodeTxt,
    showError:errorCode
  }}
  
  
  
  sendCodeBtn = {{
    onClick:(e)=>{
      sendCode()
    }
  }}

  
    submitBtn = {{
      onClick: (e) => {
        if(validate2()){
          addBeneficary()
        }
      }
    }}
  backBtn = {{
    onClick: (e) => {
      resetFields()
      props.showAdminBeneficiaryModal(false)
    }
  }}
  inputCellNumber = {{
    txtCell1: {
      onChange: (e) => onChangeCell1(e.target.value),
      value: cellNumber1,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:2,
      ref: inputElement1
    },
    txtCell2:{
      onChange: (e) => onChangeCell2(e.target.value),
      value: cellNumber2,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:3,
      ref: inputElement2
    },
    txtCell3:{
      onChange: (e) => onChangeCell3(e.target.value),
      value: cellNumber3,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxlLength:4,
      ref: inputElement3
    },
    txtError: errorCell,
    showError:errorCell
  }}
  inputFirstName = {{
    txtValue : {
      onChange: (e) => onChangeFirstName(e.target.value),
      value: firstName,
      placeHolder: 'First Name'
    },
    txtError: errorFirstNameTxt,
    showError:errorFirstName,
    ref: inputFirstName
  }}
  inputLastName = {{
    txtValue : {
      onChange: (e) => onChangeLastName(e.target.value),
      value: lastName,
      placeHolder: 'Last Name'
    },
    showError:true,
    txtError: errorLastNameTxt,
    showError:errorLastName,
    ref: inputLastName
  }}

  dateOfBirth={{
    txtError: errorDobTxt,
    showError:errorDob,
    year:{
      onClick:(e)=>{
        console.log('year')
          handleYearOpen()
      },
      value:year
      
    },
    month:{
      onClick:(e)=>{
        console.log('year')
          handleMonthOpen()
      },

      value:month
    },
    day:{
      onClick:(e)=>{
        console.log('year')
          handleDayOpen()
      },
      value:day
      
    },
  
    
    
  }}
  
  inputId = {{
    txtValue: {
      onChange: (e) => onChangeSAId(e.target.value),
      placeHolder:'SA Id Number',
      value: idNumber
    },
    txtError: errorSAIdTxt,
    showError:errorSAId
  }}


  inputGender = {{
    selectTxt:'Gender',
    txtError: errorGender,
    showError:errorGender,
    ref: inputGender,
   selectGender: {
     children:  <React.Fragment>
   <Select__Option
     value={"male"}
   >
     {"Male"}
   </Select__Option>

   <Select__Option
     value={"female"}
   >
     {"Female"}
   </Select__Option>
 </React.Fragment>,
    onChange: (e) => onChangeGender(e),
    selectedContent: gender
  }}
  }
/>{isOpenDay && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'absolute', top:0}}>
  <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
      <DatePicker selected={startDate} onChange={handleDay} inline
      dateFormat="dd"
              
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          
        }) => (<div></div>)}/>
        </div>
        </div>}
        {isOpenMonth && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'absolute', top:0}}>
  <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
      <DatePicker selected={startDate} onChange={handleMonth} inline  dateFormat="MM"
        showMonthYearPicker renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          
        }) => (<div></div>)}/>
        </div>
        </div>}
        {isOpenYear && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'absolute', top:0}}>
  <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
      <DatePicker selected={startDate} onChange={handleYear} inline showYearPicker
        dateFormat="yyyy"
        yearItemNumber={16}/>
        </div>
        </div>}

        </div>:null}

      </div>;
}

const ModalAddBeneficiary = React.forwardRef(ModalAddBeneficiary_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  showDependantModal: () => dispatch(showDependantModal()),
  setBeneficary: (obj) => dispatch(setBeneficary(obj)),
  showAdminBeneficiaryModal: (string) => dispatch(showAdminBeneficiaryModal(string)),
  showAdminBeneficiaryModalEdit:(bool)=>dispatch(showAdminBeneficiaryModalEdit(bool)),
  showErrorModal: (obj) => dispatch(showErrorModal(obj)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalAddBeneficiary);

