// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicPayout } from "./plasmic/imbewu_mobile_first/PlasmicPayout";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setUserName } from "../redux/reducers/otp";
import { setStep, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { API } from 'aws-amplify'
import { updateUser } from '../graphql/mutations'
import {  listPayouts } from "../graphql/queries";
import PaymentListItem from "./PaymentListItem";
function Payout_(props, ref) {

  React.useEffect(() => {
   console.log('TEST payout',props.otpReducer)
    if(props.otpReducer.user.account.items.length==0){
      //changePage('/payoutafter')
    }
  }, [props.viewReducer]);


const getPayoutDetails = async() => {
    
    
  // const plans = await API.graphql({
  //   query: getPayoutByUser
  // })
  // console.log('Payouts', plans)
  //setPlans(_.orderBy(plans.data.listPlans.items, ['annualPayout'], ['asc']))
  
}

const changePage =  React.useCallback((e) => {

  props.history.push(e);
 
}, []);
  console.log('PAYOUTXC', props.viewReducer.payouts)
  return <PlasmicPayout root={{ ref }} {...props} 
  backBtn = {{
    onClick:(e)=>{
      changePage('/home')
    }
  }}

  supportBtn = {{
    onClick:(e)=>{
      window.zE('messenger', 'open');
      window.zE('messenger:set', 'zIndex', 10000);
    }
  }}
  payoutList = {{
    children: props.otpReducer.user.payouts.items && props.otpReducer.user.payouts.items.map((item, index)=>(
      <React.Fragment key={index}>
    <PaymentListItem txtDate={new moment(item.createdAt).format('YYYY-MM-DD')}
        txtRef = {item.reference}
        txtAmount = {String('R'+item.amount)}
        status={item.status}
/>
  </React.Fragment>
   ))
  }}
  />;
}


const Payout = React.forwardRef(Payout_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  viewReducer: state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  setUsername: (string) => dispatch(setUserName(string)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  setStep: (int) => dispatch(setStep(int)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Payout);

