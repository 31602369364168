// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicRegister4PaymentResult } from "./plasmic/imbewu_mobile_first/PlasmicRegister4PaymentResult";
import axios from 'axios'
import qs from 'qs'

function Register4PaymentResult_(props, ref) {
  const urlParams = new URLSearchParams(window.location.search);
  const paymentId = urlParams.get('id');
  
  let paymentMade = false
  // call the API to check the payment status
  if(paymentMade==false){
    console.log('CREDIT CARD 1', paymentId)
    const messageData = {
      action: 'paymentStatus',
      paymentId: paymentId
    };
    console.log('PAYMENT PAGE3', messageData)
    // send a message to the parent window
    window.parent.postMessage(messageData, '*');
    paymentMade = true
    
  }
 
  
  return <PlasmicRegister4PaymentResult root={{ ref }} {...props} />;
}

const Register4PaymentResult = React.forwardRef(Register4PaymentResult_);

export default Register4PaymentResult;
