// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { Component , useState, useCallback, useEffect } from "react";
import { PlasmicTopNav } from "./plasmic/imbewu_mobile_first/PlasmicTopNav";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { toggleSideMenu } from "../redux/reducers/view";

function TopNav_(props, ref) {
  const [menuState, setMenuState] = useState(false);

  

  const changeMenuState =  useCallback((e) => {

   props.toggleSideMenu()
   
  }, []);

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);


  return <div style={{position:'fixed', top:0, left:0, width:"100%", zIndex:150}}>
    <PlasmicTopNav root={{ ref }} {...props} 
   logo = {{
    onClick: (e) => {
      changePage('/')
    }
   }}

    menuBtn = {{
      onClick: (e) => {
        changeMenuState()
      }
    }}
  />
    </div>
    
   
}

const TopNav = React.forwardRef(TopNav_);
const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  toggleSideMenu: () => dispatch(toggleSideMenu()),
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(TopNav);
