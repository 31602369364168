// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicRegister6AccountSuccess } from "./plasmic/imbewu_mobile_first/PlasmicRegister6AccountSuccess";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import { setSession, setUser, setVerified } from "../redux/reducers/otp";
import { showErrorModal } from "../redux/reducers/view";
import { API } from 'aws-amplify'
import { getUser } from '../graphql/queries'
function Register6AccountSuccess_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  
  

  return <PlasmicRegister6AccountSuccess root={{ ref }} {...props} 
      btnDashboard = {{
        onClick:(e)=>{
          console.log(props.otpReducer.user)
          changePage('/home')
        }
      }}
  />;
}

const Register6AccountSuccess = React.forwardRef(Register6AccountSuccess_);

const mapStateToProps = state => ({
  view: state.viewReducer,
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setSession: (string) => dispatch(setSession(string)),
  setVerified: (string) => dispatch(setVerified(string)),
  setUser: (int) => dispatch(setUser(int)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Register6AccountSuccess);
