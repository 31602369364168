// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicPayment } from "./plasmic/imbewu_mobile_first/PlasmicPayment";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setUserName } from "../redux/reducers/otp";
import { setStep, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from 'aws-amplify'
import { updateUser } from '../graphql/mutations'
import PaymentListItem from "./PaymentListItem";
import moment from "moment";
function Payment_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  return <PlasmicPayment root={{ ref }} {...props} 
  name={props.otpReducer.user.firstName+' '+props.otpReducer.user.lastName}

  backBtn = {{
    onClick:(e)=>{
      changePage('/home')
    }
  }}
  supportBtn = {{
    onClick:(e)=>{
      window.zE('messenger', 'open');
      window.zE('messenger:set', 'zIndex', 10000);
    }
  }}
  paymentList = {{
    children: props.viewReducer.payments && props.viewReducer.payments.map((item, index)=>(
      <React.Fragment key={index}>
    <PaymentListItem txtDate={new moment(item.createdAt).format('YYYY-MM-DD')}
        txtRef = {item.reference}
        txtAmount = {String('R'+item.amount)}
        status={item.status}
/>
  </React.Fragment>
   ))
  }}
  />;
}

const Payment = React.forwardRef(Payment_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  viewReducer: state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  setUsername: (string) => dispatch(setUserName(string)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  setStep: (int) => dispatch(setStep(int))
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Payment);

