// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicDashboard } from "./plasmic/imbewu_mobile_first/PlasmicDashboard";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setBeneficary, setDependant, showLoadingModal, toggleSideMenu } from "../redux/reducers/view";
import ListItemDependentBeneficicary from "./ListItemDependentBeneficicary";
import { API } from 'aws-amplify'
import {deleteBeneficiary, deleteDependant } from '../graphql/mutations'
import { getUserByCell } from '../graphql/queries'
import moment from "moment";
function Dashboard_(props, ref) {
  const [showPayment, setShowPayment] = React.useState('active');
  const [policyStatus, setPolicyStatus] = React.useState('Your Policy is active.');

  const gotoAddBenficiary = () => {
    changePage('/addbenificiary');
  }
  const gotoAddDependant = () => {
    changePage('/adddependant');
  }

  const gotoEditDependant = (item) => {
    props.setDependant(item)
    changePage('/editdependant');
  }

  const gotoEditBenificiary = (item) => {
    props.setBeneficiary(item)
    changePage('/editbeneficiary');
  }

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const removeDependant = async(item) => {
    
  
      props.showLoadingModal(true)
      const user = await API.graphql({
        query: deleteDependant,
        variables: {
          input: {
            id: item.id
          },
        },
      })
      props.showLoadingModal(false)
     //retrieveUser()
    }

    const removeBeneficiary = async(item) => {
  
      props.showLoadingModal(true)
      const user = await API.graphql({
        query: deleteBeneficiary,
        variables: {
          input: {
            id: item.id
          },
        },
      })
      props.showLoadingModal(false)
     //retrieveUser()
    }

    const checkPayment = (date) =>{
        for (let index = 0; index < props.viewReducer.payments.length; index++) {
          const element = props.viewReducer.payments[index];
          if(moment(element.paymentDate).format('MMM')==date && element.status=='approved'){
            return 'success'
          }else if(moment(element.paymentDate).format('MMM')==date && element.status=='failed'){
           
            return 'failed'
            
          }
        }
        return 'inactive'
    }

    const checkShowPayment = () =>{
      for (let index = 0; index < props.otpReducer.user.payments.items.length; index++) {
        const element = props.viewReducer.payments[index];
        console.log('Failed', element.status)
        if(element.status=='failed'){
          setPolicyStatus("Your policy is in arrears.")
          setShowPayment('failed')
        }
      }
    //setShowPayment('active')
  }

  const checkCover= (item) =>{

    var age = moment().diff(item.dateOfBirth, 'years')
 
    var plan = props.otpReducer.user.plan.items[0]

    console.log('CHECKCOVER', age, plan)
    if(item.relationship=='Son/daughter' && age<22){
      return 'Cover: R'+plan.familyCover
    }
    return 'Cover: R'+plan.extendedCover
}



  React.useEffect(() => {
 
    checkShowPayment()
    
  }, []);
    
  
  console.log('USEERNAME',props.otpReducer)
  return <div>{props.otpReducer.user && props.otpReducer.user.plan && props.otpReducer.user.payments.items.length>0 && <PlasmicDashboard root={{ ref }} {...props} 
           activeTxt = {policyStatus}
            cashPayoutValue ={ props.otpReducer.user && 'R'+String(props.otpReducer.user.plan.items[0].annualPayout)}
            policyPaymentDate = {moment(props.viewReducer.payments[props.viewReducer.payments.length-1].createdAt).add(1, 'months').format('DD-MM-YYYY')}
            yearDateBlocks = {{
              btnPayNow:{
                onClick:(e)=>{
                  changePage('/latepayment')
                }
              },
              
              status:showPayment,
              jan: {
                status: checkPayment('Jan'),
              },
              feb: {
                status: checkPayment('Feb')
              },
              mar: {
                status: checkPayment('Mar')
              },
              apr: {
                status: checkPayment('Apr')
              },
              may: {
                status: checkPayment('May')
              },
              jun: {
                status: checkPayment('Jun')
              },
              jul: {
                status: checkPayment('Jul')
              },
              aug: {
                status: checkPayment('Aug')
              },
              sep: {
                status: checkPayment('Sep')
              },
              oct: {
                status: checkPayment('Oct')
              },
              nov: {
                status: checkPayment('Nov')
              },
              dec: {
                status: checkPayment('Dec')
              }
            }}
            bonusDate = {moment(props.viewReducer.payments[0].createdAt).add(1, 'years').format('DD-MM-YYYY')}
            dependantList = {{
              children: props.viewReducer.dependants && props.viewReducer.dependants.map((item, index)=>(
                <React.Fragment key={index}>
              <ListItemDependentBeneficicary nameTxt={item.firstName+' '+item.lastName}
              coverTxt = {checkCover(item)}
              btnEdit = {{
                onClick:(e)=>{
                  gotoEditDependant(item)
                }
              }}
              
              btnDelete = {{
                onClick:(e)=>{
                  removeDependant(item)
                }
              }}
/>
            </React.Fragment>
             ))
            }}
            beneficiaryList = {{
              children:  props.viewReducer.beneficiary && Array.isArray(props.viewReducer.beneficiary) && props.viewReducer.beneficiary.map((item, index)=>(
                <React.Fragment key={index}>
              <ListItemDependentBeneficicary nameTxt={item.firstName+' '+item.lastName}
              coverTxt = ' '
              btnEdit = {{
                onClick:(e)=>{
                  gotoEditBenificiary(item)
                }
              }}
              
              btnDelete = {{
                onClick:(e)=>{
                  removeBeneficiary(item)
                }
              }}
/>
            </React.Fragment>
             ))
            }}
            btnAddDependant = {{
              onClick: (e) => {
                gotoAddDependant()
              }
            }}
            btnAddBeneficiary = {{
              disabled: props.viewReducer.beneficiary.length>0 ? true :false,
              onClick: (e) => {
                props.viewReducer.beneficiary.length==0 && gotoAddBenficiary()
              }
            }}
  />}
  </div>
}

const Dashboard = React.forwardRef(Dashboard_);


const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  toggleSideMenu: () => dispatch(toggleSideMenu()),
  setDependant:(obj) => dispatch(setDependant(obj)),
  setBeneficiary:(obj) => dispatch(setBeneficary(obj)),
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Dashboard);


