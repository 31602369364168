// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicMenuItem } from "./plasmic/imbewu_mobile_first/PlasmicMenuItem";

function MenuItem_(props, ref) {
  
  return <PlasmicMenuItem root={{ ref }} {...props} 
  />;
}

const MenuItem = React.forwardRef(MenuItem_);

export default MenuItem;
