// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicClaims1 } from "./plasmic/imbewu_mobile_first/PlasmicClaims1";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setUserName } from "../redux/reducers/otp";
import { setStep, showClaimModal, showErrorModal, showLoadingModal, showNotifyModal } from "../redux/reducers/view";
import "react-datepicker/dist/react-datepicker.css";
import { createClaim, createAddress } from '../graphql/mutations'
import Select__Option from "./Select__Option";
import SelectSearch,{ fuzzySearch } from 'react-select-search';
import Select from 'react-select'
import { API, graphqlOperation } from 'aws-amplify';
import moment from "moment";
import DatePicker from "react-datepicker";


import config from '../aws-exports';
import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import Storage from '@aws-amplify/storage';
import '../assets/search.css'
import cities from '../assets/cities';
import provinces from '../assets/provinces.json';
import death from '../assets/death.json';
import { useCookies } from 'react-cookie';

Storage.configure({
  region: config.aws_user_files_s3_bucket_region,
  bucket: config.aws_user_files_s3_bucket,
  identityPoolId: config.aws_user_pools_id,
  level: "public",
});

function Claims1_(props, ref) {

  const [cookies, setCookie] = useCookies(['imbewuClaim']);

  const deceasedUpload = React.useRef(null);
  const bankUpload = React.useRef(null);
  const claimUpload = React.useRef(null);
  const memberUpload = React.useRef(null);
  const deathUpload = React.useRef(null);
  const deathNoticeUpload = React.useRef(null);
  const inputStreetRef = React.useRef(null);
  const inputResidentialRef = React.useRef(null);
  const inputDependant = React.useRef(null);
  const inputNature = React.useRef(null);
  const inputCity = React.useRef(null);
  const inputProvince = React.useRef(null);
  const [terms, setTerms] = React.useState(null);
  const [startDate, setStartDate] = React.useState(new Date());

  const [errorTerms, setErrorTerms] = React.useState(true);
  const [errorTermsTxt, setErrorTermsTxt] = React.useState(false);

  const [dependant, setDependant] = React.useState(null);
  const [dependantFull, setDependantFull] = React.useState(null);
  const [errorDependant, setErrorDependant] = React.useState(true)
  const [errorDependantTxt, setErrorDependantTxt] = React.useState(null);

  const [residentialStreet, setResidentialStreet] = React.useState(null);
  const [errorresidentialStreet, setErrorresidentialStreet] = React.useState(false)
  const [errorresidentialStreetTxt, setErrorresidentialStreetTxt] = React.useState(null);

  const [street, setStreet] = React.useState(null);
  const [errorStreet, setErrorStreet] = React.useState(false)
  const [errorStreetTxt, setErroStreetTxt] = React.useState(null);

  const [province, setProvince] = React.useState(null);
  const [errorProvince, setErrorProvince] = React.useState(true)
  const [errorProvinceTxt, setErrorProvinceTxt] = React.useState(null);

  const [city, setCity] = React.useState(null);
  const [errorCity, setErrorCity] = React.useState(true)
  const [errorCityTxt, setErrorCityTxt] = React.useState(null);

  const [residentialProvince, setResProvince] = React.useState(null);
  const [errorResProvince, setErrorResProvince] = React.useState(true)
  const [errorResProvinceTxt, setErrorResProvinceTxt] = React.useState(null);

  const [residentialCity, setResCity] = React.useState(null);
  const [errorResCity, setErrorResCity] = React.useState(true)
  const [errorResCityTxt, setErrorResCityTxt] = React.useState(null);

  const [deathCertificateDoc, setDeathCertificate] = React.useState(null);
  const [errorDeathCert, setErrorDeathCert] = React.useState(false)
  const [errorDeathCertTxt, setErrorDeathCertTxt] = React.useState(null);
  const [deathCertComplete, setDeathCertComplete] = React.useState(false)
  const [deathProgress, setDeathProgress] = React.useState(0)

  const [deathNoticeCertificateDoc, setDeathNoticeCertificate] = React.useState(null);
  const [errorDeathNotice, setErrorDeathNotice] = React.useState(false)
  const [errorDeathNoticeTxt, setErrorDeathNoticeTxt] = React.useState(null);
  const [deathNoticeComplete, setDeathNoticeComplete] = React.useState(false)
  const [deathNoticeProgress, setDeathNoticeProgress] = React.useState(0)

  const [memberIdDoc, setMemberId] = React.useState(null);
  const [errorMemberId, setErrorMemberId] = React.useState(false)
  const [errorMemberIdTxt, setErrorMemberIdTxt] = React.useState(null);
  const [memberIdComplete, setMemberIdComplete] = React.useState(false)
  const [memberProgress, setMemberProgress] = React.useState(0)

  const [bankDoc, setBank] = React.useState(null);
  const [errorBank, setErrorBank] = React.useState(false)
  const [errorBankTxt, setErrorBankTxt] = React.useState(null);
  const [bankComplete, setBankComplete] = React.useState(false)
  const [bankProgress, setBankProgress] = React.useState(0)

  const [claimDoc, setClaim] = React.useState(null);
  const [errorClaim, setErrorClaim] = React.useState(false)
  const [errorClaimTxt, setErrorClaimTxt] = React.useState(null);
  const [claimComplete, setClaimComplete] = React.useState(false)
  const [claimProgress, setClaimProgress] = React.useState(0)

  const [deathIdDoc, setDeathId] = React.useState(null);
  const [errorDeathId, setErrorDeathId] = React.useState(false)
  const [errorDeathIdTxt, setErrorDeathIdTxt] = React.useState(null);
  const [deathIdComplete, setDeathIdComplete] = React.useState(false)
  const [deathIdProgress, setDeathIdProgress] = React.useState(0)


  const [nature, setNature] = React.useState(null);
  const [errorNature, setErrorNature] = React.useState(true)
  const [errorNatureTxt, setErrorNatureTxt] = React.useState(null);

  const [isOpenYear, setIsOpenYear] = React.useState(false);
  const [isOpenMonth, setIsOpenMonth] = React.useState(false);
  const [isOpenDay, setIsOpenDay] = React.useState(false);

  const [year, setYear] = React.useState(null);
  const [month, setMonth] = React.useState(null);
  const [monthReal, setMonthReal] = React.useState(null);
  const [day, setDay] = React.useState(null);

  const [errorDod, setErrorDod] = React.useState(false);
  const [errorDodTxt, setErrorDodTxt] = React.useState("");

  const cities_new = [];
  let oldObj = {};
for (let index = 0; index < cities.length; index++) {
  const element = cities[index];
  var obj = {"label": element.city, "value":element.city}
  cities_new.push(obj)
}


React.useEffect(() => {
console.log('COOKIES', cookies)
if(cookies.imbewuClaim){
  cookies.imbewuClaim.nature && setNature(cookies.imbewuClaim.nature)
  cookies.imbewuClaim.city && setCity(cookies.imbewuClaim.city)
  cookies.imbewuClaim.province && setProvince(cookies.imbewuClaim.province)
  cookies.imbewuClaim.resProvince && setProvince(cookies.imbewuClaim.resProvince)
  cookies.imbewuClaim.resCity && setProvince(cookies.imbewuClaim.resCity)
  cookies.imbewuClaim.resedentialStreet && setProvince(cookies.imbewuClaim.resedentialStreet)
  cookies.imbewuClaim.street && setProvince(cookies.imbewuClaim.street)
  oldObj = cookies.imbewuClaim;
}  
}, []);


const onChangeTerms =  React.useCallback((e) => {
  console.log('Terms', e)

  
  setTerms(e);
},[terms])

  const onChangeDependant=  React.useCallback((e) => {
   
    for (let index = 0; index < props.viewReducer.dependants.length; index++) {
      const element = props.viewReducer.dependants[index];
      if(element.firstName+' '+element.lastName == e){
        console.log('Ch nge', element)
        setDependantFull(element)

      }
      
    }
    setDependant(e);
  },[dependant])

  const onChangeNature=  React.useCallback((e) => {
    setNature(e.value);
    oldObj.nature = e.value;
    setCookie('imbewuClaim', oldObj);

  },[nature])

  const onChangeCity=  React.useCallback((e) => {
    console.log('CHANGE', e)
    oldObj.city = e;
    setCookie('imbewuClaim', oldObj);
    setCity(e);
  },[city])
  const onChangeCityResidential=  React.useCallback((e) => {
    console.log('CHANGE', e)
    oldObj.resCity = e;
    setCookie('imbewuClaim', oldObj);
    setResCity(e);
  },[city])

  const onChangeProvince = React.useCallback((e) => {
    console.log('CHANGE', e)
    oldObj.province = e;
    setCookie('imbewuClaim', oldObj);
    setProvince(e)
  },[province]);

  const onChangeResProvince = React.useCallback((e) => {
    console.log('CHANGE', e)
    oldObj.resProvince = e;
    setCookie('imbewuClaim', oldObj);
    setResProvince(e)
  },[residentialProvince]);

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const handleDeceasedClick = event => {
    console.log('RESUB', deceasedUpload)
    deceasedUpload.current.click();
  };

  const handleBankClick = event => {
    bankUpload.current.click();
  };

  const handleDeathClick = event => {
    console.log('RESUB', deceasedUpload)
    deathUpload.current.click();
  };
  const handleClaimClick = event => {
    claimUpload.current.click();
  };

  const handleDeathNoticeClick = event => {
    deathNoticeUpload.current.click();
  };

  const changePostal =  React.useCallback((e) => {

      if(e==true){
        console.log(street)
        setResProvince(province)
        setResidentialStreet(street)
        setResCity(city)
        oldObj.resProvince = province;
        oldObj.resedentialStreet = street;
        oldObj.resCity = city;
        setCookie('imbewuClaim', oldObj);
      }else{
        setResProvince(null)
        setResidentialStreet('')
        setResCity('')
        oldObj.resProvince = null;
        oldObj.resedentialStreet = "";
        oldObj.resCity = "";
        setCookie('imbewuClaim', oldObj);
      }
   
  }, [street, province, city]);


  const handleMemberClick = event => {
    console.log('RESUB', memberUpload)
    memberUpload.current.click();
  };

  

  const validate =  React.useCallback((e) => {
    
  
    
    if(dependant==null){
     setErrorDependantTxt('Required')
     window.scrollTo(0, inputDependant.current.offsetTop)
     setErrorDependant(false)
     return false
   }else{
    setErrorDependant(true)
   }
   if(nature==null){
    setErrorNatureTxt('Required')
    setErrorNature(true)
    return false
  }else{
    setErrorNature(false)
  }

  if(!month || !year || !day){
    
    setErrorDodTxt('Please anter a valid date of death')
   setErrorDod(true)
   return false
  }else{
    const date = moment(day+' '+monthReal+' '+year, "DD MM YYYY");
    const now = moment();
    const dif = now.diff(date, 'days');

    if(dif<30){
      console.log('Dif now', dif, date)
      props.showNotifyModal("The date of death must be at least 30 days ago.")
    }
   setErrorDod(false)
  }
 


  
  if(street==null){
    console.log('Valid nor', street)
    setErroStreetTxt('Required')
    window.scrollTo(0, inputStreetRef.current.offsetTop)
    setErrorStreet(true)
    return false
  }else{
    setErrorStreet(false)
  }
  if(city==null || city==''){
    console.log('Valid city', city)
    setErrorCityTxt('Required')
    window.scrollTo(0, inputCity.current.offsetTop)
    setErrorCity(false)
    return false
  }else{
    setErrorCity(true)
  }
  if(province==null){
    console.log('Valid province', province)
    setErrorProvinceTxt('Required')
    window.scrollTo(0, inputProvince.current.offsetTop)
    setErrorProvince(false)
    return false
  }else{
    setErrorProvince(true)
  }
  if(residentialStreet==null){
    console.log('Valid street', residentialStreet)
    setErrorresidentialStreetTxt('Required')
    window.scrollTo(0, inputStreetRef.current.offsetTop)
    setErrorresidentialStreet(true)
    return false
  }else{
    setErrorresidentialStreet(false)
  }

  if(residentialCity==null){
    console.log('Valid res city', residentialCity)
    setErrorResCityTxt('Required')
    window.scrollTo(0, inputCity.current.offsetTop)
    setErrorResCity(false)
    return false
  }else{
    setErrorResCity(true)
  }
  if(residentialProvince==null){
    console.log('Valid res province', residentialProvince)
    setErrorResProvinceTxt('Required')
    window.scrollTo(0, inputProvince.current.offsetTop)
    setErrorResProvince(false)
    return false
  }else{
    setErrorResProvince(true)
  }
  if(deathIdDoc==null){
    console.log('SHOW ERROR FOR DEATH ID')
    setErrorDeathIdTxt('Required')
    setErrorDeathId(true)
    return false
  }else{
    setErrorDeathId(false)
  }
  if(bankDoc==null){
    console.log('SHOW ERROR FOR DEATH ID')
    setErrorBankTxt('Required')
    setErrorBank(true)
    return false
  }else{
    setErrorBank(false)
  }

  if(claimDoc==null){
    console.log('SHOW ERROR FOR DEATH ID')
    setErrorClaimTxt('Required')
    setErrorClaim(true)
    return false
  }else{
    setErrorClaim(false)
  }

  if(memberIdDoc==null){
    console.log('SHOW ERROR FOR ID')
    setErrorMemberIdTxt('Required')
    setErrorMemberId(true)
    return false
  }else{
    setErrorMemberId(false)
  }

  if(deathCertificateDoc==null){
    setErrorDeathCertTxt('Required')
    setErrorDeathCert(true)
    return false
  }else{
    setErrorDeathCert(false)
  }

  if(deathNoticeCertificateDoc==null){
    setErrorDeathNoticeTxt('Required')
    setErrorDeathNotice(true)
    return false
  }else{
    setErrorDeathNotice(false)
  }

  // if(terms==null || terms!=true){
  //   console.log('Error Terms', terms)
  //    setErrorTermsTxt('Your must accept the declaration in order to submit a claim.')
  //    setErrorTerms(false)
  //    return false
  //  }else{
  //    setErrorTerms(true)
  //  }

   
   return true
 },[dependant, nature, street, city, province,residentialCity, residentialProvince, residentialStreet, deathIdDoc, memberIdDoc, deathCertificateDoc, terms, bankDoc, deathNoticeCertificateDoc, claimDoc])

//  userId: ID!
//  dependant: Dependant!
//  natureOfDeath:String!
//  postalAddress: String!
//  residentialAddress:String!
//  copyOfDeath:String!
//  copyOfIdMain:String!
//  copyOfIdDependaant:String!
//  status:String!
 const addClaim = async() => {
  if(validate()){
    props.showLoadingModal(true)
    
   
    const user = await API.graphql({
      query: createClaim,
      variables: {
        input: {
          userId:props.otpReducer.user.id,
          dependantId: dependantFull.id,
          dependantName: dependantFull.firstName+' '+dependantFull.lastName,
          postalStreet:street,
          postalCity:city.value,
          postalProvince:province.value,
          resedentialStreet:residentialStreet,
          resedentialCity:residentialCity.value,
          resedentialProvince:residentialProvince.value,
          copyOfDeath: deathCertificateDoc,
          copyOfIdMain: memberIdDoc,
          copyOfIdDependant:deathIdDoc,
          copyOfDeathNotice: deathNoticeCertificateDoc,
          copyBankStatements: bankDoc,
          copyOfClaim: claimDoc,
          status: 'Submitted'
        },
      },
    })
    props.showLoadingModal(false)
    props.showClaimModal(true)
  }
  
}


 
  const uploadDeceased =  React.useCallback(async (e) => {

    const file = e.target.files[0];
    const fileName = file.name;
  try {
    await Storage.put(`docs/${props.otpReducer.user.id}/idOfDeceased/${fileName}`, file,{
      progressCallback(progress) {
        const progressInPercentage = 
          (progress.loaded / progress.total) 
        ;
        setDeathIdProgress(progressInPercentage)
      }} ).then((result) => {
        setDeathIdComplete(true)
        setDeathId(result.key)
        setErrorDeathId(false)
      });
  } catch (error) {
    console.log('Upload error', error)
  }
   
   
  }, []);

  const uploadBank =  React.useCallback(async (e) => {

    const file = e.target.files[0];
    const fileName = file.name;
  try {
    await Storage.put(`docs/${props.otpReducer.user.id}/bankStatements/${fileName}`, file,{
      progressCallback(progress) {
        const progressInPercentage = 
          (progress.loaded / progress.total) 
        ;
        setBankProgress(progressInPercentage)
      }} ).then((result) => {
        setBankComplete(true)
        setBank(result.key)
        setErrorBank(false)
      });
  } catch (error) {
    console.log('Upload error', error)
  }
   
   
  }, []);

  const uploadClaim =  React.useCallback(async (e) => {

    const file = e.target.files[0];
    const fileName = file.name;
  try {
    await Storage.put(`docs/${props.otpReducer.user.id}/claims/${fileName}`, file,{
      progressCallback(progress) {
        const progressInPercentage = 
          (progress.loaded / progress.total) 
        ;
        setClaimProgress(progressInPercentage)
      }} ).then((result) => {
        setClaimComplete(true)
        setClaim(result.key)
        setErrorClaim(false)
      });
  } catch (error) {
    console.log('Upload error', error)
  }
   
   
  }, []);
  
  const uploadDeath =  React.useCallback(async (e) => {

    const file = e.target.files[0];
    const fileName = file.name;
  try {
    await Storage.put(`docs/${props.otpReducer.user.id}/deathCertificate/${fileName}`, file, {
      progressCallback(progress) {
        const progressInPercentage = 
          (progress.loaded / progress.total) 
        ;
        setDeathProgress(progressInPercentage)
      }} ).then((result) => {
      setDeathCertificate(result.key)
      setDeathCertComplete(true)
      setErrorDeathCert(false)
    });
  } catch (error) {
    console.log('Upload error', error)
  }
   
   
  }, []);

  const uploadDeathNotice =  React.useCallback(async (e) => {

    const file = e.target.files[0];
    const fileName = file.name;
  try {
    await Storage.put(`docs/${props.otpReducer.user.id}/deathNoticeCertificate/${fileName}`, file, {
      progressCallback(progress) {
        const progressInPercentage = 
          (progress.loaded / progress.total) 
        ;
        setDeathNoticeProgress(progressInPercentage)
      }} ).then((result) => {
      setDeathNoticeCertificate(result.key)
      setDeathNoticeComplete(true)
      setErrorDeathNotice(false)
    });
  } catch (error) {
    console.log('Upload error', error)
  }
   
   
  }, []);


  const handleYearOpen = () => {
    console.log('Year open')
    setIsOpenYear(true)
  }
  
  const handleMonthOpen = () => {
    console.log('Year open')
    setIsOpenMonth(true)
  }
  
  
  const handleDayOpen = () => {
  
    setIsOpenDay(true)
  }
  
  
  const handleYear = (e) => {
    var d = moment(e).format('YYYY')
    const date = moment(day+' '+monthReal+' '+year, "DD MM YYYY");
    const now = moment();
    const dif = now.diff(date, 'days');
    if(dif<30){
      console.log('Dif now', dif, date)
      props.showNotifyModal("The date of death must be at least 30 days ago.")
    }
    setIsOpenYear(false)
    setYear(d)
  }
  const handleMonth = (e) => {
    var d = moment(e).format('MMM')
    var c = moment(e).format('MM')
    console.log('Momenyt', d)
    setIsOpenMonth(false)
    setMonth(d)
    setMonthReal(c)
  }
  const handleDay = (e) => {
    var d = moment(e).format('DD')
    console.log('Momenyt', d)
    setIsOpenDay(false)
    setDay(d)
  }

  

  const uploadMember =  React.useCallback(async (e) => {

    const file = e.target.files[0];
    const fileName = file.name;
  try {
    await Storage.put(`docs/${props.otpReducer.user.id}/idOfMember/${fileName}`, file,  {
      progressCallback(progress) {
        const progressInPercentage = 
          (progress.loaded / progress.total) 
        ;
        setMemberProgress(progressInPercentage)
      }} ).then((result) => {
      setMemberIdComplete(true)
      setMemberId(result.key)
      setErrorMemberId(false)
    });
  } catch (error) {
    console.log('Upload error', error)
  }
   
   
  }, []);

  const onChangeResidentialStreet = React.useCallback((e) => {
    setResidentialStreet(e);
  },[residentialStreet])

  const onChangeStreet = React.useCallback((e) => {
    setStreet(e);
  },[street])
console.log('Death', death)
  return <div> <input type="file"
  ref={deceasedUpload}
  style={{display:'none', position:'absolute', top:0, left:0}} 
  onChange={uploadDeceased}
/> <input type="file"
  ref={memberUpload}
  style={{display:'none', position:'absolute', top:0, left:0}} 
  onChange={uploadMember}
/>
<input type="file"
  ref={deathUpload}
  style={{display:'none', position:'absolute', top:0, left:0}} 
  onChange={uploadDeath}
/>
<input type="file"
  ref={deathNoticeUpload}
  style={{display:'none', position:'absolute', top:0, left:0}} 
  onChange={uploadDeathNotice}
/>
<input type="file"
  ref={bankUpload}
  style={{display:'none', position:'absolute', top:0, left:0}} 
  onChange={uploadBank}
/>
<input type="file"
  ref={claimUpload}
  style={{display:'none', position:'absolute', top:0, left:0}} 
  onChange={uploadClaim}
/>

<PlasmicClaims1 root={{ ref }} {...props} 
  postalCheck = {{
    checkBoxTerms: {
      onChange: (e) => changePostal(e)
    }
  }}

deceasedId= {{
  
  
  txtError: errorDeathIdTxt,
  showError:errorDeathId,
  completed: deathIdComplete,
  progress:{
    style: {width:300*deathIdProgress}
  },
  btnUpload:{onClick:(e=>{
    handleDeceasedClick(e)
}),
}}}

bankStatements= {{
  
  
  txtError: errorBankTxt,
  showError:errorBank,
  completed: bankComplete,
  progress:{
    style: {width:300*bankProgress}
  },
  btnUpload:{onClick:(e=>{
    handleBankClick(e)
}),
}}}

claimForm = {{
  
  
  txtError: errorClaimTxt,
  showError:errorClaim,
  completed: claimComplete,
  progress:{
    style: {width:300*claimProgress}
  },
  btnUpload:{onClick:(e=>{
    handleClaimClick(e)
}),
}}}

dod={{
  txtError: errorDodTxt,
  showError:errorDod,
  showHeader:true,
  year:{
    onClick:(e)=>{
      console.log('year')
        handleYearOpen()
    },
    value:year
    
  },
  month:{
    onClick:(e)=>{
      console.log('year')
        handleMonthOpen()
    },

    value:month
  },
  day:{
    onClick:(e)=>{
      console.log('year')
        handleDayOpen()
    },
    value:day
    
  },

  
  
}}

memberId= {{
 
  
  txtError: errorMemberIdTxt,
  showError:errorMemberId,
  completed: memberIdComplete,
  progress:{
    style: {width:300*memberProgress}
  },
  btnUpload:{onClick:(e=>{
    console.log('Pick')
      handleMemberClick(e)
  }),
}}}

deathCertificate= {{
  txtError: errorDeathCertTxt,
  showError:errorDeathCert,
  completed: deathCertComplete,
  progress:{
    style: {width:300*deathProgress}
  },
  btnUpload:{onClick:(e=>{
      handleDeathClick(e)
  })
}}}

deathNotice= {{
  txtError: errorDeathNoticeTxt,
  showError:errorDeathNotice,
  completed: deathNoticeComplete,
  progress:{
    style: {width:300*deathNoticeProgress}
  },
  btnUpload:{onClick:(e=>{
      handleDeathNoticeClick(e)
  })
}}}


selectDependant = {{
  txtError: errorDependantTxt,
  showError:errorDependant,
  selectTxt:'',
  showHeader:true,
 selectGender: {
   children:  <React.Fragment>
     {props.viewReducer.dependants && props.viewReducer.dependants.map((item, index)=>(
        <Select__Option
        value={item.firstName+' '+item.lastName}
        >
        {item.firstName+' '+item.lastName}
        </Select__Option>
     ))}
</React.Fragment>,
  onChange: (e) => onChangeDependant(e),
  selectedContent: dependant,
  ref: inputDependant
}
}

}

selectNatureofDeath = {{
  selectTxt:'Nature of Death',
  ref: inputNature,
  txtError: errorNatureTxt,
 showError:errorNature,
 showHeader:true,
 selectHolder:{
   children:    <div  style={{zIndex:120, width:"100%"}}>
   <Select
     value={{ "label": nature, "value":nature}}
     options={death}
     placeholder="Select nature of death"
     onChange = {onChangeNature}
 />
</div>
}
}}

supportBtn = {{
  onClick:(e)=>{
    window.zE('messenger', 'open');
    window.zE('messenger:set', 'zIndex', 10000);
  }
}}

declaration = {{
  checkBoxTerms: {
    onChange: (e) => onChangeTerms(e)
  },
 
  txtError: errorTermsTxt,
  showError:errorTerms
}}

inputStreet = {{
  txtValue : {
    onChange: (e) => onChangeStreet(e.target.value),
    value: street,
    placeHolder: 'Street or PO Box'
  },
  txtError: errorStreetTxt,
  showError:errorStreet,
  ref: inputStreetRef
}}
selectCity = {{
   selectTxt:'City',
   ref: inputCity,
   txtError: errorCityTxt,
  showError:errorCity,
  selectHolder:{
    children:    <div  style={{zIndex:120, width:"100%"}}>
    <Select
      value={city}
      options={cities_new}
      isSearchable
      placeholder="Select your city"
      onChange = {onChangeCity}
  />
</div>
 }
}}

residentialStreet = {{
  txtValue : {
    onChange: (e) => onChangeResidentialStreet(e.target.value),
    value: residentialStreet,
    placeHolder: 'Street'
  },
  txtError: errorresidentialStreetTxt,
  showError:errorresidentialStreet,
  ref: inputResidentialRef
}}

selectProvince = {{
  txtError: errorProvinceTxt,
  showError:errorProvince,
  
   selectTxt:'Province',
   ref: inputProvince,
   selectHolder:{
  children:  <div  style={{zIndex:100, width:"100%"}}>
  <Select
    value={province}
    options={provinces}
    isSearchable
    placeholder="Select your province"
    onChange = {onChangeProvince}
/>
</div>}
}}

residentialCity = {{
   selectTxt:'City',
   selectHolder:{
  children:  <div  style={{zIndex:80, width:"100%"}}>
  <Select
    value = {residentialCity}
    options={cities_new}
    isSearchable
    placeholder="Select your city"
    onChange = {onChangeCityResidential}
/>
</div>}
}}
residentialProvince = {{
   style: {zIndex:70},
   selectTxt:'Province',
   selectHolder:{
  children:  <div  style={{zIndex:9, width:"100%"}}>
  <Select
    value = {residentialProvince}
    options={provinces}
    isSearchable
    placeholder="Select your province"
    onChange = {onChangeResProvince}
/>
</div>}
}}
backBtn = {{
  onClick: (e) => {
    changePage('/home')
  }
}}
btnInfo = {{
  onClick: (e) => {
    props.showNotifyModal("“Unnatural death” refers to a death that occurs as a result of external factors, such as accidents, homicide, suicides, or drug overdose. These deaths are often unexpected and can be investigated further.\n\r\n\r“Natural death” occurs when a person dies due to natural causes, typically from age-related illnesses or medical conditions like heart disease, cancer, or organ failure. These deaths are not the result of external factors and are considered a normal part of aging process or the progression of a disease."
      )
  }
}}


submitBtn = {{
  onClick:(e)=>{
    addClaim()
  }
}}
  />{isOpenDay && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'fixed', top:0, zIndex:100000}}>
  <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
      <DatePicker selected={startDate} onChange={handleDay} inline
      dateFormat="dd"
              
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          
        }) => (<div></div>)}/>
        </div>
        </div>}
        {isOpenMonth && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'fixed', top:0}}>
  <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
      <DatePicker selected={startDate} onChange={handleMonth} inline  dateFormat="MM"
        showMonthYearPicker renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          
        }) => (<div></div>)}/>
        </div>
        </div>}
        {isOpenYear && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'fixed', top:0}}>
  <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
      <DatePicker selected={startDate} onChange={handleYear} inline showYearPicker
        dateFormat="yyyy"
        yearItemNumber={16}/>
        </div>
        </div>}
</div>;
}

const Claims1 = React.forwardRef(Claims1_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  viewReducer:state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  setUsername: (string) => dispatch(setUserName(string)),
  showClaimModal:(string) => dispatch(showClaimModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  showNotifyModal:(bool) => dispatch(showNotifyModal(bool)),
  setStep: (int) => dispatch(setStep(int)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Claims1);


