// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicContact } from "./plasmic/imbewu_mobile_first/PlasmicContact";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showLoadingModal, toggleSideMenu } from "../redux/reducers/view";

import Loader from '../assets/loader.svg';

import { API } from 'aws-amplify'
import { createCandidate } from '../graphql/mutations'

function Contact_(props, ref) {
  const inputElement1 = React.useRef(null);
  const inputElement2 = React.useRef(null);
  const inputElement3 = React.useRef(null);
  const inputFirstName = React.useRef(null);
  const inputLastName = React.useRef(null);
  const [cellNumber1, setCellNumber1] = React.useState(null);
  const [cellNumber2, setCellNumber2] = React.useState(null);
  const [cellNumber3, setCellNumber3] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [comment, setComment] = React.useState(null);
  const [pageState, setPageState] = React.useState('start');

  const [errorFirstName, setErrorFirstName] = React.useState(false);
  const [errorFirstNameTxt, setErrorFirstNameTxt] = React.useState(null);
  const [errorLastName, setErrorLastName] = React.useState(false);
  const [errorLastNameTxt, setErrorLastNameTxt] = React.useState(null);
  const [errorCell, setErrorCell] = React.useState(true);
  const [errorCellTxt, setErrorCellTxt] = React.useState(null);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorEmailTxt, setErrorEmailTxt] = React.useState(null);
  
  const onChangeCell1 =  React.useCallback((e) => {
    if(e.length<3){
      setCellNumber1(e); 
    }
    console.log('tab', e.length)
    if(e.length==2){
     inputElement2.current.focus()
    }
   
  },[cellNumber1])

  const onChangeCell2 =  React.useCallback((e) => {
    if(e.length<4){
    setCellNumber2(e); 
    }
    if(e.length==3){
      inputElement3.current.focus()
     }
  },[cellNumber2])

  const onChangeCell3 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber3(e); 
    }
  },[cellNumber3])

  const onChangeFirstName =  React.useCallback((e) => {
    setFirstName(e);
  },[firstName])

  const onChangeLastName =  React.useCallback((e) => {
    setLastName(e);
  },[lastName])

  const onChangeComment =  React.useCallback((e) => {
    setComment(e);
  },[])


  const onChangeEmail = (e) => {
    setEmail(e); 
  }

  
  const sendMail = async() => {
    if(validate()){
      props.showLoadingModal(true)
      var name = firstName + ' ' + lastName;
      var cell = '+27'+cellNumber1+cellNumber2+cellNumber3
      await API.graphql({
        query: createCandidate,
        variables: {
          input: {
            name,
            email,
            cell,
            comment
          },
        },
      })
      props.showLoadingModal(false)
      setPageState('complete')
    }
    
  }

  const validate =  React.useCallback((e) => {
    
    if(firstName==null || firstName.length<2){
     setErrorFirstNameTxt('Required')
     setErrorFirstName(true)
     window.scrollTo(0, inputFirstName.current.offsetTop)
     return false
   }else{
     setErrorFirstName(false)
   }
   if(lastName==null || lastName.length<2){
     
     setErrorLastNameTxt('Required')
     setErrorLastName(true)
     window.scrollTo(0, inputLastName.current.offsetTop)
     return false
   }else{
     setErrorLastName(false)
   }
   if(cellNumber1== null || cellNumber2==null || cellNumber3==null || (cellNumber1+cellNumber2+cellNumber3).length<9){
     
     setErrorCellTxt('A Valid cell number is required')
     setErrorCell(false)
     return false
   }else{
     setErrorCell(true)
   }

   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
    
     setErrorEmail(false)
     
   }else{
    setErrorEmail(true)
     setErrorEmailTxt('A valid email address is required')
     return false
   }
   

   
   return true;

 },[firstName, lastName,  email, cellNumber1,cellNumber2,cellNumber3])


  return <PlasmicContact root={{ ref }} {...props} 

  btnSend = {{
    onClick: (e) => {
      sendMail()
    }
  }}

  status = {pageState}


  
  firstName = {{
    txtValue : {
      onChange: (e) => onChangeFirstName(e.target.value),
      value: firstName,
      placeHolder: 'First Name'
    },
    txtError: errorFirstNameTxt,
    showError:errorFirstName,
    ref: inputFirstName
  }}
  lastName = {{
    txtValue : {
      onChange: (e) => onChangeLastName(e.target.value),
      value: lastName,
      placeHolder: 'Last Name'
    },
    showError:true,
    txtError: errorLastNameTxt,
    showError:errorLastName,
    ref: inputLastName
  }}

  btnFacebook = {{
    onClick:(e)=>{
      window.open('https://www.google.com/url?q=https://www.facebook.com/ImbewuStokvest/&source=gmail-imap&ust=1643622489000000&usg=AOvVaw3RPf8v8X8dugAB00CFZqQH')
    }
  }}
  btnTwitter = {{
    onClick:(e)=>{
      window.open('https://www.google.com/url?q=https://mobile.twitter.com/imbewustokvest&source=gmail-imap&ust=1643622472000000&usg=AOvVaw3OnF2KSb35Pt4ZWCX8ywe-')
    }
  }}
  btnInstagram = {{
    onClick:(e)=>{
      window.open('https://www.google.com/url?q=https://instagram.com/imbewustokvest?utm_medium%3Dcopy_link&source=gmail-imap&ust=1643622558000000&usg=AOvVaw3_c4BSOOWM9Zq-bokWC9pI')
    }
  }}
  btnFacebookK = {{
    onClick:(e)=>{
      window.open('https://www.google.com/url?q=https://www.facebook.com/kmrisksolutions/&source=gmail-imap&ust=1643622590000000&usg=AOvVaw0frpDmr_xFN4lZRBY0-JDG')
    }
  }}
  btnTwitterK = {{
    onClick:(e)=>{
      window.open('https://www.google.com/url?q=https://mobile.twitter.com/home&source=gmail-imap&ust=1643622573000000&usg=AOvVaw0qx-vKu1KC2wgkdUONpo3t')
    }
  }}
  btnInstagramK = {{
    onClick:(e)=>{
      window.open('https://www.google.com/url?q=https://instagram.com/kmrs_za?utm_medium%3Dcopy_link&source=gmail-imap&ust=1643622558000000&usg=AOvVaw3BAiVNTtvYmqYI3B5wJNKX')
    }
  }}

  email = {{
    txtValue : {
      onChange: (e) => onChangeEmail(e.target.value),
      value: email,
      placeHolder: 'Email address'
    },
    txtError: errorEmailTxt,
    showError:errorEmail
  }}

  cellNumber = {{
    txtCell1: {
      onChange: (e) => onChangeCell1(e.target.value),
      value: cellNumber1,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:2,
      ref: inputElement1
    },
    txtCell2:{
      onChange: (e) => onChangeCell2(e.target.value),
      value: cellNumber2,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:3,
      ref: inputElement2
    },
    txtCell3:{
      onChange: (e) => onChangeCell3(e.target.value),
      value: cellNumber3,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxlLength:4,
      ref: inputElement3
    },
    txtError: errorCellTxt,
    showError:errorCell
  }}

  

  inputComment= {{
    onChange: (e) => onChangeComment(e.target.value),
    value: comment
  }}

  
  />;
}

const Contact = React.forwardRef(Contact_);

const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  toggleSideMenu: () => dispatch(toggleSideMenu()),
  showLoadingModal:(string) => dispatch(showLoadingModal(string))
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Contact);

