// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { changeStep, setPlan, setStep, showLoadingModal } from "../redux/reducers/view";
import CardPlanA from "./CardPlanA";
import CardPlanB from "./CardPlanB";
import CardPlanC from "./CardPlanC";
import { PlasmicRegister3ChoosePlan } from "./plasmic/imbewu_mobile_first/PlasmicRegister3ChoosePlan";
import Select__Option from "./Select__Option";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { API } from 'aws-amplify'
import {createPayment, createPlan ,createUserPlan} from '../graphql/mutations'
import { listPlans } from '../graphql/queries'
import * as _ from "lodash";
import { usePaystackPayment } from 'react-paystack';
import moment from "moment";


function Register3ChoosePlan_(props, ref) {
  const [planView, setPlanView] = React.useState(<CardPlanA/>);
  const [plan, setPlan] = React.useState(null);
  const [plans, setPlans] = React.useState(null);
  const [errorPlanTxt, setErrorPlanTxt] = React.useState(null);
  const [errorPlan, setErrorPlan] = React.useState(true);

  const config = {
    reference: (new Date()).getTime().toString(),
    email: "user@example.com",
    amount: props.view.selectedPlan && parseInt(props.view.selectedPlan.cost*100),
    currency: "ZAR",
    publicKey: 'pk_test_9e51c1550eaaf52040999ccabc1d53ff0835cf3b',
};

// you can call this function anything
const onSuccess = (reference) => {
  console.log('reference2',reference)
  setPay(reference)
  sendPlan()
};

const setPay= async(reference) => {
  props.showLoadingModal(true)
      const user = await API.graphql({
        query: createPayment,
        variables: {
          input: {
            userId: props.otpReducer.user.id,
            message: reference.message,
            reference: reference.reference,
            status: reference.status,
            trans: reference.trans,
            transaction: reference.transaction,
            trxref: reference.trxref,
            amount: parseFloat(props.view.selectedPlan.cost)
            
          },
        },
      })
      props.showLoadingModal(false)
  changePage('/payout')
};

// you can call this function anything
const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log('closed')
}
const initializePayment = usePaystackPayment(config);


  React.useEffect(() => {
    getPlans()
  }, [props.viewReducer]);
  const getPlans = async() => {
    
    
    const plans = await API.graphql({
      query: listPlans,
        sort: {
          direction: 'ASC',
          field: 'cost'
        }
    })
    console.log('Plans', plans)
    setPlans(_.orderBy(plans.data.listPlans.items, ['annualPayout'], ['asc']))
  }

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const onChangePlan =  React.useCallback((e) => {
   setPlan(e);
  
    switch (e) {
      case 'Plan A':
        setPlanView(<CardPlanA/>)
        console.log('Plan set', plans[0])
        props.setPlanObj(plans[0])
        break;
      case 'Plan B':
        setPlanView(<CardPlanB/>)
        console.log('Plan set', plans[1])
        props.setPlanObj(plans[1])
        break;
      case 'Plan C':
        console.log('Plan set', plans[2])
        setPlanView(<CardPlanC/>)
        props.setPlanObj(plans[2])
        break;

    
    
      default:
        break;
    }
  },[plan, plans])

  const sendPlan= async() => {

    if(validate()){
      console.log('Plan', props.otpReducer.user)
      props.showLoadingModal(true)
      const user = await API.graphql({
        query: createUserPlan,
        variables: {
          input: {
            cost: parseFloat(props.view.selectedPlan.cost),
            name: props.view.selectedPlan.name,
            annualPayout:parseFloat(props.view.selectedPlan.annualPayout),
            familyCover: parseFloat(props.view.selectedPlan.familyCover),
            extendedCover:parseFloat(props.view.selectedPlan.extendedCover),
            userId: props.otpReducer.user.id,
            payoutDate: moment().format('YYYY-MM-DD')
          },
        },
      })
      
      props.changeStep(3)
      props.showLoadingModal(false)
      changePage('/payment')
    }
    
  }

  const validate =  React.useCallback((e) => {
   
    console.log('Error Terms', plan)
    if(plan==null){
     
      setErrorPlanTxt('Please choose a plan.')
      setErrorPlan(false)
      return false
    }else{
      setErrorPlan(true)
    }
   
    return true;
 
  },[ plan])

  return <PlasmicRegister3ChoosePlan root={{ ref }} {...props} 
  planHolder={{
    children: <React.Fragment>{planView}</React.Fragment>
  }}
  submitBtn = {{
    onClick:(e)=>{
      if(validate()){
        props.setStep(4)
       // initializePayment(onSuccess, onClose)
        sendPlan()
      }
      
    }
  }}
  planSelector = {{
    txtError: errorPlanTxt,
    showError:errorPlan,
    selectTxt:' ',
   selectGender: {
     children:  <React.Fragment>
   <Select__Option
     value={"Plan A"}
   >
     {"Plan A"}
   </Select__Option>

   <Select__Option
     value={"Plan B"}
   >
     {"Plan B"}
   </Select__Option>
   <Select__Option
     value={"Plan C"}
   >
     {"Plan C"}
   </Select__Option>
 </React.Fragment>,
    onChange: (e) => onChangePlan(e),
    selectedContent: plan
  }}
  }
  />;
}

const Register3ChoosePlan = React.forwardRef(Register3ChoosePlan_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  setPlanObj: (string) => dispatch(setPlan(string)),
  setStep: (obj) => dispatch(setStep(obj)),
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  changeStep: (int) => dispatch(changeStep(int)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Register3ChoosePlan);