// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicInputString } from "./plasmic/imbewu_mobile_first/PlasmicInputString";

function InputString_(props, ref) {
  
  return <PlasmicInputString root={{ ref }} {...props} />;
}

const InputString = React.forwardRef(InputString_);

export default InputString;
