// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicNavPageTitleA } from "./plasmic/copy_of_imbewu_admin/PlasmicNavPageTitleA";

function NavPageTitleA_(props, ref) {
  return <PlasmicNavPageTitleA root={{ ref }} {...props} />;
}

const NavPageTitleA = React.forwardRef(NavPageTitleA_);

export default NavPageTitleA;
