// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicModalConfirmation } from "./plasmic/imbewu_mobile_first/PlasmicModalConfirmation";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { hideErrorModal, showCancelSuccess, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import { useHistory } from "react-router-dom";
import { listProratas } from "../graphql/queries";
import { API } from "aws-amplify";
import { deleteAccount, deleteAuthorizations, deleteUserPlan, updateUser } from "../graphql/mutations";



function ModalConfirmation_(props, ref) {
  let history = useHistory();
  const [rates, setRates] = React.useState(null);
  React.useEffect(() => {
    getProrata()
  }, [props.viewReducer]);

  const getProrata = async() => {
    const rates = await API.graphql({
      query: listProratas,
      variables: { filter: {plan: {eq:props.otp.user.plan.items[0].name}, payment1: {eq: String(props.otp.user.payments.items.length)}} }
    })
   
    setRates(rates.data.listProratas.items[0])
  }

  const updateInfo = async() => {
   
      props.showLoadingModal(true)
       const user = await API.graphql({
         query: updateUser,
         variables: {
           input: {
             id:props.otp.user.id ? props.otp.user.id : props.otp.user.username,
            status: "Cancelled"
           },
         },
       })
       const removeAccount = await API.graphql({
        query: deleteAccount,
        variables: {
          input: {
            id:props.otp.user.account.items[0].id
          },
        },
      })
      const removeAuth = await API.graphql({
        query: deleteAuthorizations,
        variables: {
          input: {
            id:props.otp.user.authorization.items[0].id
          },
        },
      })
      const removePlan = await API.graphql({
        query: deleteUserPlan,
        variables: {
          input: {
            id:props.otp.user.plan.items[0].id
          },
        },
      })
        props.showLoadingModal(false)
        props.showCancelSuccess(true)
 };

  return <div>
    <div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}>
   { console.log('Proratas', props)}
    <PlasmicModalConfirmation root={{ ref }} {...props}
     errorTxt = {"If you cancel your plan now you will receive a prorata payout of R "+rates?.amount+" according to the payout schedule."}
     btnClose = {{
       onClick: (e) => {
         props.history.push('/home')
       }
     }}

     btnConfirm = {{
      onClick: (e) => {
        updateInfo()
      }
    }}
    />;
    </div>
    </div>
}

const ModalConfirmation = React.forwardRef(ModalConfirmation_);
const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otp: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  showLoadingModal:(bool) => dispatch(showLoadingModal(bool)),
  showCancelSuccess:(bool) => dispatch(showCancelSuccess(bool))
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalConfirmation);

