/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate(
    $input: CreateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    createCandidate(input: $input, condition: $condition) {
      id
      name
      email
      cell
      comment
      createdAt
      updatedAt
    }
  }
`;
export const updateCandidate = /* GraphQL */ `
  mutation UpdateCandidate(
    $input: UpdateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    updateCandidate(input: $input, condition: $condition) {
      id
      name
      email
      cell
      comment
      createdAt
      updatedAt
    }
  }
`;
export const deleteCandidate = /* GraphQL */ `
  mutation DeleteCandidate(
    $input: DeleteCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    deleteCandidate(input: $input, condition: $condition) {
      id
      name
      email
      cell
      comment
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      firstName
      lastName
      id
      dependants {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          relationship
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          contactNumber
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          userId
          reference
          status
          amount
          paymentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      payouts {
        items {
          id
          userId
          amount
          reference
          status
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          userId
          accountName
          accountNo
          bank
          accountType
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      authorization {
        items {
          id
          authorization_code
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        items {
          id
          cost
          name
          annualPayout
          familyCover
          extendedCover
          payoutDate
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      claims {
        items {
          id
          userId
          dependantId
          dependantName
          postalStreet
          postalCity
          postalProvince
          resedentialStreet
          resedentialCity
          resedentialProvince
          copyOfDeath
          copyOfIdMain
          copyOfIdDependant
          copyOfDeathNotice
          copyOfClaim
          copyBankStatements
          status
          reasonForDecline
          createdAt
          updatedAt
        }
        nextToken
      }
      paymentDate
      gender
      email
      cell
      idNumber
      status
      dob
      marketingApproved
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      firstName
      lastName
      id
      dependants {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          relationship
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          contactNumber
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          userId
          reference
          status
          amount
          paymentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      payouts {
        items {
          id
          userId
          amount
          reference
          status
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          userId
          accountName
          accountNo
          bank
          accountType
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      authorization {
        items {
          id
          authorization_code
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        items {
          id
          cost
          name
          annualPayout
          familyCover
          extendedCover
          payoutDate
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      claims {
        items {
          id
          userId
          dependantId
          dependantName
          postalStreet
          postalCity
          postalProvince
          resedentialStreet
          resedentialCity
          resedentialProvince
          copyOfDeath
          copyOfIdMain
          copyOfIdDependant
          copyOfDeathNotice
          copyOfClaim
          copyBankStatements
          status
          reasonForDecline
          createdAt
          updatedAt
        }
        nextToken
      }
      paymentDate
      gender
      email
      cell
      idNumber
      status
      dob
      marketingApproved
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      firstName
      lastName
      id
      dependants {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          relationship
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          contactNumber
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          userId
          reference
          status
          amount
          paymentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      payouts {
        items {
          id
          userId
          amount
          reference
          status
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          userId
          accountName
          accountNo
          bank
          accountType
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      authorization {
        items {
          id
          authorization_code
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        items {
          id
          cost
          name
          annualPayout
          familyCover
          extendedCover
          payoutDate
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      claims {
        items {
          id
          userId
          dependantId
          dependantName
          postalStreet
          postalCity
          postalProvince
          resedentialStreet
          resedentialCity
          resedentialProvince
          copyOfDeath
          copyOfIdMain
          copyOfIdDependant
          copyOfDeathNotice
          copyOfClaim
          copyBankStatements
          status
          reasonForDecline
          createdAt
          updatedAt
        }
        nextToken
      }
      paymentDate
      gender
      email
      cell
      idNumber
      status
      dob
      marketingApproved
      type
      createdAt
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      userId
      accountName
      accountNo
      bank
      accountType
      code
      createdAt
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      userId
      accountName
      accountNo
      bank
      accountType
      code
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      userId
      accountName
      accountNo
      bank
      accountType
      code
      createdAt
      updatedAt
    }
  }
`;
export const createAuthorizations = /* GraphQL */ `
  mutation CreateAuthorizations(
    $input: CreateAuthorizationsInput!
    $condition: ModelAuthorizationsConditionInput
  ) {
    createAuthorizations(input: $input, condition: $condition) {
      id
      authorization_code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateAuthorizations = /* GraphQL */ `
  mutation UpdateAuthorizations(
    $input: UpdateAuthorizationsInput!
    $condition: ModelAuthorizationsConditionInput
  ) {
    updateAuthorizations(input: $input, condition: $condition) {
      id
      authorization_code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteAuthorizations = /* GraphQL */ `
  mutation DeleteAuthorizations(
    $input: DeleteAuthorizationsInput!
    $condition: ModelAuthorizationsConditionInput
  ) {
    deleteAuthorizations(input: $input, condition: $condition) {
      id
      authorization_code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createDependant = /* GraphQL */ `
  mutation CreateDependant(
    $input: CreateDependantInput!
    $condition: ModelDependantConditionInput
  ) {
    createDependant(input: $input, condition: $condition) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      relationship
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateDependant = /* GraphQL */ `
  mutation UpdateDependant(
    $input: UpdateDependantInput!
    $condition: ModelDependantConditionInput
  ) {
    updateDependant(input: $input, condition: $condition) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      relationship
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteDependant = /* GraphQL */ `
  mutation DeleteDependant(
    $input: DeleteDependantInput!
    $condition: ModelDependantConditionInput
  ) {
    deleteDependant(input: $input, condition: $condition) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      relationship
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createBeneficiary = /* GraphQL */ `
  mutation CreateBeneficiary(
    $input: CreateBeneficiaryInput!
    $condition: ModelBeneficiaryConditionInput
  ) {
    createBeneficiary(input: $input, condition: $condition) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      contactNumber
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateBeneficiary = /* GraphQL */ `
  mutation UpdateBeneficiary(
    $input: UpdateBeneficiaryInput!
    $condition: ModelBeneficiaryConditionInput
  ) {
    updateBeneficiary(input: $input, condition: $condition) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      contactNumber
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteBeneficiary = /* GraphQL */ `
  mutation DeleteBeneficiary(
    $input: DeleteBeneficiaryInput!
    $condition: ModelBeneficiaryConditionInput
  ) {
    deleteBeneficiary(input: $input, condition: $condition) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      contactNumber
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      createdAt
      updatedAt
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      createdAt
      updatedAt
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      createdAt
      updatedAt
    }
  }
`;
export const createProrata = /* GraphQL */ `
  mutation CreateProrata(
    $input: CreateProrataInput!
    $condition: ModelProrataConditionInput
  ) {
    createProrata(input: $input, condition: $condition) {
      id
      plan
      payment1
      amount
      createdAt
      updatedAt
    }
  }
`;
export const updateProrata = /* GraphQL */ `
  mutation UpdateProrata(
    $input: UpdateProrataInput!
    $condition: ModelProrataConditionInput
  ) {
    updateProrata(input: $input, condition: $condition) {
      id
      plan
      payment1
      amount
      createdAt
      updatedAt
    }
  }
`;
export const deleteProrata = /* GraphQL */ `
  mutation DeleteProrata(
    $input: DeleteProrataInput!
    $condition: ModelProrataConditionInput
  ) {
    deleteProrata(input: $input, condition: $condition) {
      id
      plan
      payment1
      amount
      createdAt
      updatedAt
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      street
      city
      province
      createdAt
      updatedAt
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      street
      city
      province
      createdAt
      updatedAt
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      street
      city
      province
      createdAt
      updatedAt
    }
  }
`;
export const createUserPlan = /* GraphQL */ `
  mutation CreateUserPlan(
    $input: CreateUserPlanInput!
    $condition: ModelUserPlanConditionInput
  ) {
    createUserPlan(input: $input, condition: $condition) {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      payoutDate
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateUserPlan = /* GraphQL */ `
  mutation UpdateUserPlan(
    $input: UpdateUserPlanInput!
    $condition: ModelUserPlanConditionInput
  ) {
    updateUserPlan(input: $input, condition: $condition) {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      payoutDate
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserPlan = /* GraphQL */ `
  mutation DeleteUserPlan(
    $input: DeleteUserPlanInput!
    $condition: ModelUserPlanConditionInput
  ) {
    deleteUserPlan(input: $input, condition: $condition) {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      payoutDate
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      userId
      reference
      status
      amount
      paymentDate
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      userId
      reference
      status
      amount
      paymentDate
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      userId
      reference
      status
      amount
      paymentDate
      createdAt
      updatedAt
    }
  }
`;
export const createPayout = /* GraphQL */ `
  mutation CreatePayout(
    $input: CreatePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    createPayout(input: $input, condition: $condition) {
      id
      userId
      amount
      reference
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const updatePayout = /* GraphQL */ `
  mutation UpdatePayout(
    $input: UpdatePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    updatePayout(input: $input, condition: $condition) {
      id
      userId
      amount
      reference
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const deletePayout = /* GraphQL */ `
  mutation DeletePayout(
    $input: DeletePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    deletePayout(input: $input, condition: $condition) {
      id
      userId
      amount
      reference
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const createClaim = /* GraphQL */ `
  mutation CreateClaim(
    $input: CreateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    createClaim(input: $input, condition: $condition) {
      id
      userId
      dependantId
      dependantName
      postalStreet
      postalCity
      postalProvince
      resedentialStreet
      resedentialCity
      resedentialProvince
      copyOfDeath
      copyOfIdMain
      copyOfIdDependant
      copyOfDeathNotice
      copyOfClaim
      copyBankStatements
      status
      reasonForDecline
      createdAt
      updatedAt
    }
  }
`;
export const updateClaim = /* GraphQL */ `
  mutation UpdateClaim(
    $input: UpdateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    updateClaim(input: $input, condition: $condition) {
      id
      userId
      dependantId
      dependantName
      postalStreet
      postalCity
      postalProvince
      resedentialStreet
      resedentialCity
      resedentialProvince
      copyOfDeath
      copyOfIdMain
      copyOfIdDependant
      copyOfDeathNotice
      copyOfClaim
      copyBankStatements
      status
      reasonForDecline
      createdAt
      updatedAt
    }
  }
`;
export const deleteClaim = /* GraphQL */ `
  mutation DeleteClaim(
    $input: DeleteClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    deleteClaim(input: $input, condition: $condition) {
      id
      userId
      dependantId
      dependantName
      postalStreet
      postalCity
      postalProvince
      resedentialStreet
      resedentialCity
      resedentialProvince
      copyOfDeath
      copyOfIdMain
      copyOfIdDependant
      copyOfDeathNotice
      copyOfClaim
      copyBankStatements
      status
      reasonForDecline
      createdAt
      updatedAt
    }
  }
`;
