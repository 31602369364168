// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PlasmicRegister2Secure } from "./plasmic/imbewu_mobile_first/PlasmicRegister2Secure";
import { setUserName } from "../redux/reducers/otp";
import { changeStep, setStep, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { API } from 'aws-amplify'
import { updateUser } from '../graphql/mutations'

function Register2Secure_(props, ref) {
  const [terms, setTerms] = React.useState(null);
  
  const [isOpenYear, setIsOpenYear] = React.useState(false);
  const [isOpenMonth, setIsOpenMonth] = React.useState(false);
  const [isOpenDay, setIsOpenDay] = React.useState(false);
  const [time, setTime] = React.useState(new Date());
  const [idNumber, setSAId] = React.useState(null);
  const [errorSAId, setErrorSAId] = React.useState(false);
  const [errorSAIdTxt, setErrorSAIdTxt] = React.useState(null);
  const [errorDOB, setErrorDOB] = React.useState(false);
  const [errorDOBTxt, setErrorDOBTxt] = React.useState(false);
  const [errorTerms, setErrorTerms] = React.useState(true);
  const [errorTermsTxt, setErrorTermsTxt] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [year, setYear] = React.useState(null);
  const [month, setMonth] = React.useState(null);
  const [day, setDay] = React.useState(null);
  
  const onChangeTerms =  React.useCallback((e) => {
   
    setTerms(e);
   
  },[terms])

  const onChangeSAId= React.useCallback((e) => {
    var yearSplit = String(e).substr(0,2)
    var monthSplit = String(e).substr(2,2)
    var daySplit = String(e).substr(4,2)
   
    if(parseInt(yearSplit) < 30){
      yearSplit = "20"+yearSplit
    }else{
      yearSplit = "19"+yearSplit
    }
    console.log('SAID', yearSplit, monthSplit, daySplit)
    setYear(yearSplit)
    setMonth(monthSplit)
    setDay(daySplit)
    setSAId(e); 
  },[idNumber])

  const validate =  React.useCallback((e) => {
   
   if(!idNumber || !validateID()){
     
     setErrorSAIdTxt('Not a valid South African ID Number')
     setErrorSAId(true)
     return false
   }else{
     setErrorSAId(false)
   }
  
   if(!month || !year || !day){
   
    setErrorDOBTxt('Please anter a valid birth date')
    setErrorDOB(true)
    return false
   }else{
    setErrorDOB(false)
   }

   if(terms==null || terms!=true){
    console.log('Error Terms', terms)
     setErrorTermsTxt('Your must accept our conditions to register.')
     setErrorTerms(false)
     return false
   }else{
     setErrorTerms(true)
   }
  
   return true;

 },[ terms, idNumber, month, year, day])

 const changePage =  React.useCallback((e) => {

  props.history.push(e);
 
}, []);


const handleChangeDate =  React.useCallback((e) => {

 setStartDate(e)
 
}, []);


const handleYearOpen = () => {
  console.log('Year open')
  //setIsOpenYear(true)
}

const handleMonthOpen = () => {
  console.log('Year open')
 //setIsOpenMonth(true)
}


const handleDayOpen = () => {

 // setIsOpenDay(true)
}


const handleYear = (e) => {
  var d = moment(e).format('YYYY')
  console.log('Momenyt', d)
  setIsOpenYear(false)
  setYear(d)
}
const handleMonth = (e) => {
  var d = moment(e).format('MMM')
  console.log('Momenyt', d)
  setIsOpenMonth(false)
  setMonth(d)
}
const handleDay = (e) => {
  var d = moment(e).format('DD')
  console.log('Momenyt', d)
  setIsOpenDay(false)
  setDay(d)
}


  const  validateID = () => {
  
    // assume everything is correct and if it later turns out not to be, just set this to false
    var correct = true;

    //Ref: http://www.sadev.co.za/content/what-south-african-id-number-made
    // SA ID Number have to be 13 digits, so check the length
    if (idNumber.length != 13 || !isNumber(idNumber)) {
        correct = false;
    }

    // get first 6 digits as a valid date
    var tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));

    var id_date = tempDate.getDate();
    var id_month = tempDate.getMonth();
    var id_year = tempDate.getFullYear();

    var fullDate = id_date + "-" + id_month + 1 + "-" + id_year;

    if (!((tempDate.getYear() == idNumber.substring(0, 2)) && (id_month == idNumber.substring(2, 4) - 1) && (id_date == idNumber.substring(4, 6)))) {
        correct = false;
    }

    // get the gender
    var genderCode = idNumber.substring(6, 10);
    var gender = parseInt(genderCode) < 5000 ? "Female" : "Male";

    // get country ID for citzenship
    var citzenship = parseInt(idNumber.substring(10, 11)) == 0 ? "Yes" : "No";

    // apply Luhn formula for check-digits
    var tempTotal = 0;
    var checkSum = 0;
    var multiplier = 1;
    for (var i = 0; i < 13; ++i) {
        tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
        if (tempTotal > 9) {
            tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
        }
        checkSum = checkSum + tempTotal;
        multiplier = (multiplier % 2 == 0) ? 1 : 2;
    }
    if ((checkSum % 10) != 0) {
        correct = false;
    };
    return correct;
}

const sendUpdate = async() => {
  console.log('STEP CHECK 2',props.otpReducer)
  if(validate()){
   props.showLoadingModal(true)
 
    var dob = day+'-'+month+'-'+year
    const user = await API.graphql({
      query: updateUser,
      variables: {
        input: {
          id:props.otpReducer.user.id ? props.otpReducer.user.id : props.otpReducer.user.username,
          idNumber,
          dob,
        },
      },
    })
    props.showLoadingModal(false)
    props.changeStep(2)
    changePage('/chooseplan')
  }
  
}

const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
console.log('STEP CHECK0',props.view)
  return <div className="content"><PlasmicRegister2Secure root={{ ref }} {...props} 
  className="content--inner"
  checkTerms = {{
    checkBoxTerms: {
      onChange: (e) => onChangeTerms(e)
    },
   
    txtError: errorTermsTxt,
    showError:errorTerms
  }}
  dateOfBirth={{
    txtError: errorDOBTxt,
    showError:errorDOB,
    year:{
      onClick:(e)=>{
        console.log('year')
          handleYearOpen()
      },
      value:year
      
    },
    month:{
      onClick:(e)=>{
        console.log('year')
          handleMonthOpen()
      },

      value:month
    },
    day:{
      onClick:(e)=>{
        console.log('year')
          handleDayOpen()
      },
      value:day
      
    },
  
    
    
  }}
  
  inputId = {{
    txtValue: {
      onChange: (e) => onChangeSAId(e.target.value),
      placeHolder:'SA Id Number',
      value: idNumber
    },
    txtError: errorSAIdTxt,
    showError:errorSAId
  }}
  submitBtn = {{
    onClick:(e)=>{
      if(validate()){
        sendUpdate()
      }
      
    }
  }}
  /> 
  {isOpenDay && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'absolute', top:0}}>
  <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
      <DatePicker selected={startDate} onChange={handleDay} inline
      dateFormat="dd"
              
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          
        }) => (<div></div>)}/>
        </div>
        </div>}
        {isOpenMonth && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'absolute', top:0}}>
  <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
      <DatePicker selected={startDate} onChange={handleMonth} inline  dateFormat="MM"
        showMonthYearPicker renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          
        }) => (<div></div>)}/>
        </div>
        </div>}
        {isOpenYear && <div style={{backgroundColor:'rgba(0, 0,0, 0.8)', width:'100%', height:'100%', position:'absolute', top:0}}>
  <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
      <DatePicker selected={startDate} onChange={handleYear} inline showYearPicker
        dateFormat="yyyy"
        yearItemNumber={16}/>
        </div>
        </div>}



      </div>;
}

const Register2Secure = React.forwardRef(Register2Secure_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer,
});

const mapDispatchToProps = dispatch => ({
  setUsername: (string) => dispatch(setUserName(string)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  changeStep: (int) => dispatch(changeStep(int)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Register2Secure);
