import * as React from "react";
import { PlasmicSelectA__OptionA } from "./plasmic/copy_of_imbewu_admin/PlasmicSelectA__OptionA";

function SelectA__OptionA_(props, ref) {
  const { plasmicProps } = PlasmicSelectA__OptionA.useBehavior(props, ref);
  return <PlasmicSelectA__OptionA {...plasmicProps} />;
}

const SelectA__OptionA = React.forwardRef(SelectA__OptionA_);

export default Object.assign(SelectA__OptionA, {
  __plumeType: "select-option"
});
