// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { hideErrorModal, showErrorModal } from "../redux/reducers/view";
import { PlasmicModalError } from "./plasmic/imbewu_mobile_first/PlasmicModalError";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
function ModalError_(props, ref) {
  
  return <div>
    {props.viewReducer.showErrorModal ? <div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}>
    <PlasmicModalError root={{ ref }} {...props} 
      errorTxt = {props.viewReducer.errorMessage}
      btnClose = {{
        onClick: (e) => {
          props.hideErrorModal()
        }
      }}
    />;
  </div> : null}
  </div>
}

const ModalError = React.forwardRef(ModalError_);
const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
});

const mapDispatchToProps = dispatch => ({
  hideErrorModal: () => dispatch(hideErrorModal()),
  showErrorModal:(string) => dispatch(showErrorModal(string))
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalError);

