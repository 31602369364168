// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicCardHomeSliderCarousel } from "./plasmic/imbewu_mobile_first/PlasmicCardHomeSliderCarousel";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DotSliderItem from "./DotSliderItem";
import CardHomeSlideItem from "./CardHomeSlideItem";
// Commonjs
//const Flickity = require('flickity');
// Or for ES2015 module
import Flickity from 'react-flickity-component'
import CardHome01 from "./CardHome01";
import CardHome02 from "./CardHome02";
import CardHome03 from "./CardHome03";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

var flickityOptions = {
  initialIndex: 0,
  pageDots: true,
  prevNextButtons:false,
  autoPlay: 4000
}

function CardHomeSliderCarousel_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const plans = [<CardHome01 style={{marginRight:30}} btnReadMore = {{onClick:(e)=>{changePage('/plansbenefits')}}}/>, <CardHome02 style={{marginRight:30}} btnReadMore = {{onClick:(e)=>{changePage('/plansbenefits')}}}/>, <CardHome03 btnReadMore = {{onClick:(e)=>{changePage('/plansbenefits')}}}/>]
  return <PlasmicCardHomeSliderCarousel root={{ ref }} {...props} 
  cardHolder = {{
    
    children: <Flickity
    
    className={'carousel'} // default ''
    elementType={'div'} // default 'div'
    options={flickityOptions} // takes flickity options {}
    disableImagesLoaded={false} // default false
    reloadOnUpdate // default false
    static // default false
    prevNextButtons= {false}
  >
    {plans.map((item, index)=>(
     <React.Fragment key={index}>
     { item }
 </React.Fragment>
  ))}
  </Flickity>
  }}
  />;
}

const CardHomeSliderCarousel = React.forwardRef(CardHomeSliderCarousel_);


export default compose(
  withRouter)(CardHomeSliderCarousel) ;
