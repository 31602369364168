// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { showLoadingModal } from "../redux/reducers/view";
import { PlasmicFaq } from "./plasmic/imbewu_mobile_first/PlasmicFaq";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import faqs from '../assets/faq';
import FaqItem from "./FaqItem";
import FaqMainItem from "./FaqMainItem";


function Faq_(props, ref) {
  const [allFaqs, setAllFaqs] = React.useState([]);

  React.useEffect(() => {
    console.log('faq effect')
    setAllFaqs(faqs)
  }, [allFaqs])

  const openFaq = (item) =>{
    var tmp = allFaqs
    for (let index = 0; index < tmp.length; index++) {
      const element = tmp[index];
      for (let i = 0; i < element.items.length; i++) {
        const questions = element.items[i];
        if(questions.question==item.question){
          
          tmp[index].items[i].closed = !item.closed
     
         
        }
        
      }
    }
    const newArray = [...allFaqs, tmp];
    setAllFaqs(newArray)
  }
  
  return <PlasmicFaq root={{ ref }} {...props} 
      faqList = {{
        children: allFaqs.map((item, index) => (
          <React.Fragment key={index}>
            {console.log('faq item', item.items)}
          <FaqMainItem 
            itemList= {{
              children:item.items && item.items.map((faq, i)=>(
                <React.Fragment key={i}>
                
                <FaqItem 
                    closed={faq.closed}
                    headline={faq.question} 
                    content = {faq.answer}
                    btnOpen = {{
                      onClick :(e) =>{
                        openFaq(faq)
                    }}
                  }
                    />
                </React.Fragment>
              ))
            }}
            
            mainTxt={item.headline}
            closed={true}/>
          </React.Fragment>
        ))
      }}
  />;
}

const Faq = React.forwardRef(Faq_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({

  showLoadingModal:(string) => dispatch(showLoadingModal(string))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps), 
  withRouter)(Faq) ;
