// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicForgottenOtp } from "./plasmic/imbewu_mobile_first/PlasmicForgottenOtp";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import { setSession, setUser, setVerified } from "../redux/reducers/otp";
import { showErrorModal, showLoadingModal, showNotifyModal } from "../redux/reducers/view";

Amplify.configure(awsconfig);

function Otp_(props, ref) {
  const [pin, setPin] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState(true);
  const [password, setPassword] = React.useState(true);
  const [errorPin, setErrorPin] = React.useState(null);
  const resendConfirmationCode= async() => {
    try {
        await Auth.forgotPassword(props.otpReducer.username);
        console.log('code resent successfully');
        props.showNotifyModal('Your one time password has been resent.')
    } catch (err) {
        console.log('error resending code: ', err);
    }
}
  const  verifyOtp = async() => {
    console.log('Verifying pin:',  pin)
    console.log('With Session:', props.otpReducer)
    if(validate()){
      props.showLoadingModal(true)
      try {
        await Auth.forgotPasswordSubmit(
          props.otpReducer.username,
          pin,
          password
        );
        props.showLoadingModal(false)
        changePage('/login')
        props.showNotifyModal('You have successfully reset your password.')
      } catch (error) {
       props.showErrorModal('Error: '+error)
       props.showLoadingModal(false)
      }
    }
    
  };

  

  const onChangePin =  React.useCallback((e) => {
    console.log(e)
    setPin(e);
  },[pin])

  const onChangePassword =  React.useCallback((e) => {
    setPassword(e);
},[password])

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const onChangeShowPassword=  React.useCallback((e) => {
    console.log('LLL', showPassword)
    setShowPassword(!showPassword); 
  },[showPassword])

  const validate =  React.useCallback((e) => {
    
    if(pin==null || pin.length<6){
     setErrorPin('Required')
     return false
   }else{
     setErrorPin(true)
   }
   if (password &&
    /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password)
    ) {
      setErrorPassword(true) 
  }else{
   
    setErrorPassword('Invalid password. Minimum eight characters, at least one letter, one number and one special character'   )         
    return false
  }
 
   
   return true;

 },[pin, password])

  return <PlasmicForgottenOtp root={{ ref }} {...props} 
            submit = {{
              onClick: (e) => {
                verifyOtp()
              }
            }}

            inputPin = {{
              onChange: (e) =>{
                onChangePin(e.target.value)
              },
              value: pin
            }}

            inputPassword = {{
              txtValue : {
                onChange: (e) => onChangePassword(e.target.value),
                value: password,
                placeHolder: 'New Password'
              },
              
              showPassword: showPassword,
              btnEye: {onClick:(e)=>onChangeShowPassword()},
              btnEyeClosed: {onClick:(e)=>onChangeShowPassword()},
              txtError: errorPassword,
              showError:errorPassword
            }}

            btnResend = {{
              onClick: (e) => {
                resendConfirmationCode()
              }
            }}
            backBtn = {{
              onClick:(e)=>{
                changePage('/login')
              } 
            }}
            btnResendOtp = {{
              onClick: (e) => {
                console.log('RESEND')
                resendConfirmationCode()
              }
            }}
  />;
}

const ForgottenOTP = React.forwardRef(Otp_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setVerified: (string) => dispatch(setVerified(string)),
  setSession: (string) => dispatch(setSession(string)),
  setUser: (obj) => dispatch(setUser(obj)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showNotifyModal:(string) => dispatch(showNotifyModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps), 
  withRouter)(ForgottenOTP) ;
