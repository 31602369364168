// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicOtp } from "./plasmic/imbewu_mobile_first/PlasmicOtp";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import { setSession, setUser, setVerified } from "../redux/reducers/otp";
import { setBeneficary, setClaims, setDependant, setDependants, setPayments, setPayouts, showErrorModal } from "../redux/reducers/view";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
Amplify.configure(awsconfig);

function Otp_(props, ref) {
  const [pin, setPin] = React.useState(null);

  const retrieveUser = async(user) => {
   
    
    try {
      console.log('ready to auth 4',props)
      const userReceived = await API.graphql(graphqlOperation(queries.getUser, { id:user }));
      
     
      var loggedinUser =userReceived.data.getUser;
      props.setUser(userReceived);
      props.setSession(null);
      props.setVerified(true)
      console.log('ready to auth 5',loggedinUser)
      props.setDependants(loggedinUser.dependants.items)
      props.setBeneficary(loggedinUser.beneficiaries.items)
      props.setPayouts(loggedinUser.payouts.items)
      props.setPayments(loggedinUser.payments.items)
      props.setUser(loggedinUser);
      
      if(loggedinUser.idNumber==null || loggedinUser.dob==null){
        changePage('/secure')
      }else if(loggedinUser.plan.items.length==0){
        changePage('/chooseplan')
      }else{
        console.log('ready to auth 6',props.viewReducer)
        changePage('/home')
      }
     
    } catch (error) {
      console.log('ready to auth error', error)
    }
    
    
  
  
}

  const verifyOtp = () => {
    console.log('Verifying pin:',  pin)
    console.log('With Session:', props.otpReducer.user)
   
    try {
       Auth.confirmSignIn(props.otpReducer.user, pin)
      .then((user) => {
       
        retrieveUser(user)
       
      })
      .catch((err) => {
        props.showErrorModal('Error: Incorrect pin')
        setPin('');
        console.log(err);
      });
    } catch (error) {
        console.log('error confirming sign up', error);
        props.showErrorModal('Error: Cannot confirming sign up')
    }
  };

  const onChangePin =  React.useCallback((e) => {
    console.log(e)
    setPin(e);
  },[])

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  return <PlasmicOtp root={{ ref }} {...props} 
            submit = {{
              onClick: (e) => {
                verifyOtp()
              }
            }}

            inputPin = {{
              onChange: (e) =>{
                onChangePin(e.target.value)
              },
              value: pin
            }}

            btnResend = {{
              onClick: (e) => {
                verifyOtp()
              }
            }}
            backBtn = {{
              onClick:(e)=>{
                changePage('/login')
              } 
            }}
  />;
}

const LoginOtp = React.forwardRef(Otp_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setVerified: (string) => dispatch(setVerified(string)),
  setSession: (string) => dispatch(setSession(string)),
  setUser: (obj) => dispatch(setUser(obj)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  setDependants: (obj) => dispatch(setDependants(obj)),
  setBeneficary: (obj) => dispatch(setBeneficary(obj)),
  setPayments: (obj) => dispatch(setPayments(obj)),
  setPayouts: (obj) => dispatch(setPayouts(obj)),
  setClaims: (obj) => dispatch(setClaims(obj))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps), 
  withRouter)(LoginOtp) ;
