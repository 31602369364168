// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { showLoadingModal } from "../redux/reducers/view";
import { PlasmicMenu } from "./plasmic/imbewu_mobile_first/PlasmicMenu";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
function Menu_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  return <div style={{zIndex:100}}><PlasmicMenu root={{ ref }} {...props} 
  
  btnClaims = {{
    onClick:(e)=>{
     
      changePage('/claims')
    }
  }}

  btnPayment = {{
    onClick:(e)=>{
   
      changePage('/payments')
    }
  }}

  btnPayout = {{
    onClick:(e)=>{
      
      changePage('/payouts')
    }
  }}
  btnDash = {{
    onClick:(e)=>{
     
      changePage('/home')
    }
  }}
  /></div>
}

const Menu = React.forwardRef(Menu_);
const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer
});

const mapDispatchToProps = dispatch => ({
 
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
 
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Menu);

