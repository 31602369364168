// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicOtp } from "./plasmic/imbewu_mobile_first/PlasmicOtp";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import { setSession, setUser, setVerified } from "../redux/reducers/otp";
import { showErrorModal } from "../redux/reducers/view";

Amplify.configure(awsconfig);

function Otp_(props, ref) {
  const [pin, setPin] = React.useState(null);

  const verifyOtp = () => {
    console.log('Verifying pin:',  pin)
    console.log('With Session:', props.otpReducer.username)
    // Auth.sendCustomChallengeAnswer(props.otpReducer.session, pin)
    //   
    try {
      Auth.confirmSignUp(props.otpReducer.username, pin)
      .then((user) => {
            props.setUser(user);
            props.setSession(null);
            changePage('/home');
            props.setVerified(true)
          })
          .catch((err) => {
            props.showErrorModal('Error: Incorrect pin')
            setPin('');
            console.log(err);
          });
    } catch (error) {
        console.log('error confirming sign up', error);
    }
  };

  const onChangePin =  React.useCallback((e) => {
    console.log(e)
    setPin(e);
  },[])

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  return <PlasmicOtp root={{ ref }} {...props} 
            submit = {{
              onClick: (e) => {
                verifyOtp()
              }
            }}

            inputPin = {{
              onChange: (e) =>{
                onChangePin(e.target.value)
              },
              value: pin
            }}

            btnResend = {{
              onClick: (e) => {
                verifyOtp()
              }
            }}
            backBtn = {{
              onClick:(e)=>{
                changePage('/login')
              } 
            }}
  />;
}

const Otp = React.forwardRef(Otp_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setVerified: (string) => dispatch(setVerified(string)),
  setSession: (string) => dispatch(setSession(string)),
  setUser: (obj) => dispatch(setUser(obj)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps), 
  withRouter)(Otp) ;
