// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicProfileArea } from "./plasmic/imbewu_mobile_first/PlasmicProfileArea";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setUserName } from "../redux/reducers/otp";
function ProfileArea_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  return <PlasmicProfileArea root={{ ref }} {...props} 
  name={props.otpReducer.user && props.otpReducer.user.firstName+' '+props.otpReducer.user.lastName}
  btnProfile = {{
    onClick:(e)=>{

      changePage('/profile')
    }
  }}
  />;
}

const ProfileArea = React.forwardRef(ProfileArea_);
const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  viewReducer:state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  setUsername: (string) => dispatch(setUserName(string)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ProfileArea);
