// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicClaimList } from "./plasmic/imbewu_mobile_first/PlasmicClaimList";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showDependantModal, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import ClaimListItem from "./ClaimListItem";
import moment from "moment";
function ClaimList_(props, ref) {

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  return <PlasmicClaimList root={{ ref }} {...props}
  backBtn = {{
    onClick:(e)=>{
      changePage('/home')
    }
  }}
  btnAdd = {{
    onClick:(e)=>{
      changePage('/startclaim')
    }
  }}
  claimList = {{
    
    children: props.viewReducer.claims && props.viewReducer.claims.map((item, index)=>(
      <React.Fragment key={index}>
    <ClaimListItem txtDate={new moment(item.createdAt).format('YYYY-MM-DD')}
        txtRef = {' '}
        txtAmount = {'R '+props.viewReducer.selectedPlan.familyCover}
        status={String(item.status).toLowerCase()}
        btnDeclined = {{
          onClick:(e)=>{
           
              props.showErrorModal(item.reasonForDecline)
           
           
          }
         
        }}
/>
  </React.Fragment>
   ))
  }}/>;
}

const ClaimList = React.forwardRef(ClaimList_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  viewReducer: state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  showDependantModal: () => dispatch(showDependantModal()),
  showErrorModal: (obj) => dispatch(showErrorModal(obj)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ClaimList);
