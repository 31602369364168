// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { hideClaimModal, hideDependantModal } from "../redux/reducers/view";
import PlasmicModalClaimSuccess from "./plasmic/imbewu_mobile_first/PlasmicModalClaimSuccess";
function ModalClaimSuccess_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  return <div>
  {props.viewReducer.showClaimModal ?<div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}><PlasmicModalClaimSuccess root={{ ref }} {...props}
          btnClose = {{
            onClick:(e)=>{
              props.hideClaimModal()
              changePage('/home')
            }
          }}
          btnAdd = {{
            onClick:(e)=>{
              props.hideClaimModal()
            }
          }}
          btnCloseMain = {{
            onClick:(e)=>{
              props.hideClaimModal()
              changePage('/home')
            }
          }}
  
  /></div>:null}</div>;
}

const ModalClaimSuccess = React.forwardRef(ModalClaimSuccess_);

const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
});

const mapDispatchToProps = dispatch => ({
  hideClaimModal: () => dispatch(hideClaimModal())
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalClaimSuccess);