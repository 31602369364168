const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';
const SHOW_NOTIFY_MODAL = 'SHOW_NOTIFY_MODAL';
const HIDE_NOTIFY_MODAL = 'HIDE_NOTIFY_MODAL';
const SHOW_DEPENDANT_MODAL = 'SHOW_DEPENDANT_MODAL';
const HIDE_DEPENDANT_MODAL = 'HIDE_DEPENDANT_MODAL';

const SHOW_CLAIM_MODAL = 'SHOW_CLAIM_MODAL';
const HIDE_CLAIM_MODAL = 'HIDE_CLAIM_MODAL';

const SHOW_ADMIN_DECLINE = 'SHOW_ADMIN_DECLINE';
const SHOW_CANCEL_SUCCESS = 'SHOW_CANCEL_SUCCESS';

const SHOW_LOADING_MODAL = 'SHOW_LOADING_MODAL';
const SHOW_ADMIN_DEPENDANT_MODAL = 'SHOW_ADMIN_DEPENDANT_MODAL';
const SHOW_ADMIN_BENEFICIARY_MODAL = 'SHOW_ADMIN_BENEFICIARY_MODAL';
const SHOW_ADMIN_CREDIT_CARD = 'SHOW_ADMIN_CREDIT_CARD';
const SHOW_ADMIN_CLAIMS_MODAL = 'SHOW_ADMIN_CLAIMS_MODAL';
const SHOW_ADMIN_DEPENDANT_MODAL_EDIT = 'SHOW_ADMIN_DEPENDANT_MODAL_EDIT';
const SHOW_ADMIN_BENEFICIARY_MODAL_EDIT = 'SHOW_ADMIN_BENEFICIARY_MODAL_EDIT';
const SHOW_ADMIN_CREATE_MODAL = 'SHOW_ADMIN_CREATE_MODAL';
const SET_PLAN = 'SET_PLAN';
const SET_STEP = 'SET_STEP';
const SET_DEPENDANT = 'SET_DEPENDANT'
const CHANGE_STEP = 'CHANGE_STEP';

const SET_DEPENDANTS = 'SET_DEPENDANTS';
const SET_BENEFICARY = 'SET_BENEFICARY';
const SET_PAYMENTS = 'SET_PAYMENTS';
const SET_PAYOUTS = 'SET_PAYOUTS';
const SET_CLAIMS = 'SET_CLAIMS';
const SET_USER_MAIN = 'SET_USER_MAIN';

const SET_CLAIM = 'SET_CLAIM';

const SET_AMOUNT = 'SET_AMOUNT';
const SET_USER_VIEW = 'SET_USER_VIEW';

const SHOW_DOC_MODAL = 'SHOW_DOC_MODAL';

export const setPlan = (payload) => ({
  type: SET_PLAN,
  payload
});


export const showDocModal = (payload) => ({
  type: SHOW_DOC_MODAL,
  payload
});

export const showCancelSuccess = (payload) => ({
  type: SHOW_CANCEL_SUCCESS,
  payload
});

export const setClaim = (payload) => ({
  type: SET_CLAIM,
  payload
});

export const setAmount = (payload) => ({
  type: SET_AMOUNT,
  payload
});

export const showAdminDependantModal = (payload) => ({
  type: SHOW_ADMIN_DEPENDANT_MODAL,
  payload
});
export const showAdminCreateModal = (payload) => ({
  type: SHOW_ADMIN_CREATE_MODAL,
  payload
});
export const showAdminDecline = (payload) => ({
  type: SHOW_ADMIN_DECLINE,
  payload
});
export const showAdminBeneficiaryModal = (payload) => ({
  type: SHOW_ADMIN_BENEFICIARY_MODAL,
  payload
});
export const showAdminClaimsModal = (payload) => ({
  type: SHOW_ADMIN_CLAIMS_MODAL,
  payload
});
export const showAdminDependantModalEdit = (payload) => ({
  type: SHOW_ADMIN_DEPENDANT_MODAL_EDIT,
  payload
});
export const showAdminCreditCard = (payload) => ({
  type: SHOW_ADMIN_CREDIT_CARD,
  payload
});
export const showAdminBeneficiaryModalEdit = (payload) => ({
  type: SHOW_ADMIN_BENEFICIARY_MODAL_EDIT,
  payload
});
export const setUserView = (payload) => ({
  type: SET_USER_VIEW,
  payload
});

export const setDependant = (payload) => ({
  type: SET_DEPENDANT,
  payload
});
export const setDependants = (payload) => ({
  type: SET_DEPENDANTS,
  payload
});
export const setUserMain = (payload) => ({
  type: SET_USER_MAIN,
  payload
});
export const setBeneficary = (payload) => ({
  type: SET_BENEFICARY,
  payload
});
export const setPayments = (payload) => ({
  type: SET_PAYMENTS,
  payload
});
export const setPayouts = (payload) => ({
  type: SET_PAYOUTS,
  payload
});

export const setClaims = (payload) => ({
  type: SET_CLAIMS,
  payload
});


export const setStep= (payload) => ({
  type: SET_STEP,
  payload
});
export const changeStep= (payload) => ({
  type: CHANGE_STEP,
  payload
});

export const toggleSideMenu = () => ({
  type: TOGGLE_SIDE_MENU
});

export const showErrorModal = (payload) => ({
  type: SHOW_ERROR_MODAL,
  payload
});
export const hideErrorModal = () => ({
  type: HIDE_ERROR_MODAL
});
export const showNotifyModal = (payload) => ({
  type: SHOW_NOTIFY_MODAL,
  payload
});
export const hideNotifyModal = () => ({
  type: HIDE_NOTIFY_MODAL
});

export const showDependantModal = (payload) => ({
  type: SHOW_DEPENDANT_MODAL,
  payload
});
export const hideDependantModal = () => ({
  type: HIDE_DEPENDANT_MODAL
});

export const showClaimModal = (payload) => ({
  type: SHOW_CLAIM_MODAL,
  payload
});
export const hideClaimModal = () => ({
  type: HIDE_CLAIM_MODAL
});
export const showLoadingModal = (payload) => ({
  type: SHOW_LOADING_MODAL,
  payload
});


const initialState = {
  sideMenuOpen: false,
  claim:null,
  showErrorModal: false,
  showNotifyModal: false,
  showAdminDependantModal: false,
  showAdminDependantModalEdit: false,
  showAdminBeneficiaryModal: false,
  showAdminCreditCard: false,
  showAdminBeneficiaryModalEdit: false,
  showAdminClaimsModal: false,
  showAdminCreateModal: false,
  showDeclineModal:false,
  showClaimModal: false,
  showDependantModal: false,
  errorMessage:null,
  showLoading: false,
  message:null,
  selectedPlan: {},
  currentStep:1,
  stepChange:1,
  totalSteps:6,
  dependant:null,
  dependents:[],
  beneficiary: [],
  payments: [],
  payouts: [],
  claims: [],
  userMain:null,
  modalType:null,
  amountToCharge:'1.00',
  userView:'userProfileTab',
  showCancelSuccess: false,
  showDocModal:false
};


export const viewReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SHOW_DOC_MODAL:
      return {
        ...state,
        showDocModal: action.payload,
      };
    case SHOW_CANCEL_SUCCESS:
      return {
        ...state,
        showCancelSuccess: action.payload,
      };
    case SHOW_ADMIN_CREATE_MODAL:
      console.log('admin create 2')
      return {
        ...state,
        showAdminCreateModal: action.payload,
      };
    case SHOW_ADMIN_CREDIT_CARD:
     
      return {
        ...state,
        showAdminCreditCard: action.payload,
      };
    case SET_AMOUNT:
     
      return {
        ...state,
        amountToCharge: action.payload,
      };
    case SET_CLAIM:
     
      return {
        ...state,
        claim: action.payload,
      };
    case SHOW_ADMIN_DECLINE:
     
      return {
        ...state,
        showDeclineModal: action.payload,
      };
    case SHOW_ADMIN_DEPENDANT_MODAL_EDIT:
     
      return {
        ...state,
        showAdminDependantModalEdit: action.payload,
      };
    case SHOW_ADMIN_CLAIMS_MODAL:
      return {
        ...state,
        showAdminClaimsModal: action.payload,
      };
    case SHOW_ADMIN_BENEFICIARY_MODAL:
      return {
        ...state,
        showAdminBeneficiaryModal: action.payload,
      };
    case SHOW_ADMIN_BENEFICIARY_MODAL_EDIT:
      return {
        ...state,
        showAdminBeneficiaryModalEdit: action.payload,
      };
    case SHOW_ADMIN_DEPENDANT_MODAL:
      return {
        ...state,
        showAdminDependantModal: action.payload,
      };
    case SET_USER_VIEW:
      return {
        ...state,
        userView: action.payload,
      };
    case SET_PLAN:
      console.log('View set plan x', state.selectedPlan, action.payload)
      return {
        ...state,
        selectedPlan: action.payload,
      };

      case SET_DEPENDANT:
        console.log('View set dependant', action.payload)
        return {
          ...state,
          dependant: action.payload,
        };
      case SET_DEPENDANTS:
        console.log('View set dependants', action.payload)
        return {
          ...state,
          dependants: action.payload,
        };
        case SET_CLAIMS:
          console.log('View set claims', action.payload)
          return {
            ...state,
            claims: action.payload,
          };
        case SET_USER_MAIN:
          console.log('ready to auth user main', action.payload)
          return {
            ...state,
            userMain: action.payload,
          };
      case SET_BENEFICARY:
        console.log('View set dependants', action.payload)
        return {
          ...state,
          beneficiary: action.payload,
        };
      case SET_PAYMENTS:
        console.log('View set dependants', action.payload)
        return {
          ...state,
          payments: action.payload,
        };
      case SET_PAYOUTS:
        console.log('View set dependants', action.payload)
        return {
          ...state,
          payouts: action.payload,
        };
    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        sideMenuOpen: !state.sideMenuOpen,
      };
      case CHANGE_STEP:
        return {
          ...state,
          stepChange: action.payload,
        };
    case SHOW_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: true,
        errorMessage: action.payload
      };
      case SHOW_NOTIFY_MODAL:
        return {
          ...state,
          showNotifyModal: true,
          message: action.payload
        };
        case SHOW_DEPENDANT_MODAL:
        return {
          ...state,
          showDependantModal: true,
          modalType: action.payload

        };
      case SHOW_LOADING_MODAL:
        return {
          ...state,
          showLoading: action.payload
        };
    case HIDE_ERROR_MODAL:
    return {
      ...state,
      showErrorModal: false,
      errorMessage: null
    };

    case HIDE_NOTIFY_MODAL:
    return {
      ...state,
      showNotifyModal: false,
      message: null
    };

    case HIDE_DEPENDANT_MODAL:
    return {
      ...state,
      showDependantModal: false,
    };

    case SHOW_CLAIM_MODAL:
      return {
        ...state,
        showClaimModal: true,
      };

      case HIDE_CLAIM_MODAL:
        return {
          ...state,
          showClaimModal: false,
        };
    
    default:
      return {...state};
  }
};
