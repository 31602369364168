/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onDependantCreate = /* GraphQL */ `
  subscription OnDependantCreate($userId: ID!) {
    onDependantCreate(userId: $userId) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      relationship
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDependantDelete = /* GraphQL */ `
  subscription OnDependantDelete($userId: ID!) {
    onDependantDelete(userId: $userId) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      relationship
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDependantUpdate = /* GraphQL */ `
  subscription OnDependantUpdate($userId: ID!) {
    onDependantUpdate(userId: $userId) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      relationship
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onBeneficiaryCreate = /* GraphQL */ `
  subscription OnBeneficiaryCreate($userId: ID!) {
    onBeneficiaryCreate(userId: $userId) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      contactNumber
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onBeneficiaryDelete = /* GraphQL */ `
  subscription OnBeneficiaryDelete($userId: ID!) {
    onBeneficiaryDelete(userId: $userId) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      contactNumber
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onBeneficiaryUpdate = /* GraphQL */ `
  subscription OnBeneficiaryUpdate($userId: ID!) {
    onBeneficiaryUpdate(userId: $userId) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      contactNumber
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onPaymentCreate = /* GraphQL */ `
  subscription OnPaymentCreate($userId: ID!) {
    onPaymentCreate(userId: $userId) {
      id
      userId
      reference
      status
      amount
      paymentDate
      createdAt
      updatedAt
    }
  }
`;
export const onPayoutCreate = /* GraphQL */ `
  subscription OnPayoutCreate($userId: ID!) {
    onPayoutCreate(userId: $userId) {
      id
      userId
      amount
      reference
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onPayoutUpdate = /* GraphQL */ `
  subscription OnPayoutUpdate($userId: ID!) {
    onPayoutUpdate(userId: $userId) {
      id
      userId
      amount
      reference
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onPlanCreate = /* GraphQL */ `
  subscription OnPlanCreate($userId: ID!) {
    onPlanCreate(userId: $userId) {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      payoutDate
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onClaimCreate = /* GraphQL */ `
  subscription OnClaimCreate($userId: ID!) {
    onClaimCreate(userId: $userId) {
      id
      userId
      dependantId
      dependantName
      postalStreet
      postalCity
      postalProvince
      resedentialStreet
      resedentialCity
      resedentialProvince
      copyOfDeath
      copyOfIdMain
      copyOfIdDependant
      copyOfDeathNotice
      copyOfClaim
      copyBankStatements
      status
      reasonForDecline
      createdAt
      updatedAt
    }
  }
`;
export const onClaimUpdate = /* GraphQL */ `
  subscription OnClaimUpdate($userId: ID!) {
    onClaimUpdate(userId: $userId) {
      id
      userId
      dependantId
      dependantName
      postalStreet
      postalCity
      postalProvince
      resedentialStreet
      resedentialCity
      resedentialProvince
      copyOfDeath
      copyOfIdMain
      copyOfIdDependant
      copyOfDeathNotice
      copyOfClaim
      copyBankStatements
      status
      reasonForDecline
      createdAt
      updatedAt
    }
  }
`;
export const onUserUpdate = /* GraphQL */ `
  subscription OnUserUpdate($id: ID!) {
    onUserUpdate(id: $id) {
      firstName
      lastName
      id
      dependants {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          relationship
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          contactNumber
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          userId
          reference
          status
          amount
          paymentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      payouts {
        items {
          id
          userId
          amount
          reference
          status
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          userId
          accountName
          accountNo
          bank
          accountType
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      authorization {
        items {
          id
          authorization_code
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        items {
          id
          cost
          name
          annualPayout
          familyCover
          extendedCover
          payoutDate
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      claims {
        items {
          id
          userId
          dependantId
          dependantName
          postalStreet
          postalCity
          postalProvince
          resedentialStreet
          resedentialCity
          resedentialProvince
          copyOfDeath
          copyOfIdMain
          copyOfIdDependant
          copyOfDeathNotice
          copyOfClaim
          copyBankStatements
          status
          reasonForDecline
          createdAt
          updatedAt
        }
        nextToken
      }
      paymentDate
      gender
      email
      cell
      idNumber
      status
      dob
      marketingApproved
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCandidate = /* GraphQL */ `
  subscription OnCreateCandidate {
    onCreateCandidate {
      id
      name
      email
      cell
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCandidate = /* GraphQL */ `
  subscription OnUpdateCandidate {
    onUpdateCandidate {
      id
      name
      email
      cell
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCandidate = /* GraphQL */ `
  subscription OnDeleteCandidate {
    onDeleteCandidate {
      id
      name
      email
      cell
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      firstName
      lastName
      id
      dependants {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          relationship
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          contactNumber
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          userId
          reference
          status
          amount
          paymentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      payouts {
        items {
          id
          userId
          amount
          reference
          status
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          userId
          accountName
          accountNo
          bank
          accountType
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      authorization {
        items {
          id
          authorization_code
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        items {
          id
          cost
          name
          annualPayout
          familyCover
          extendedCover
          payoutDate
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      claims {
        items {
          id
          userId
          dependantId
          dependantName
          postalStreet
          postalCity
          postalProvince
          resedentialStreet
          resedentialCity
          resedentialProvince
          copyOfDeath
          copyOfIdMain
          copyOfIdDependant
          copyOfDeathNotice
          copyOfClaim
          copyBankStatements
          status
          reasonForDecline
          createdAt
          updatedAt
        }
        nextToken
      }
      paymentDate
      gender
      email
      cell
      idNumber
      status
      dob
      marketingApproved
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      firstName
      lastName
      id
      dependants {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          relationship
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          contactNumber
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          userId
          reference
          status
          amount
          paymentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      payouts {
        items {
          id
          userId
          amount
          reference
          status
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          userId
          accountName
          accountNo
          bank
          accountType
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      authorization {
        items {
          id
          authorization_code
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        items {
          id
          cost
          name
          annualPayout
          familyCover
          extendedCover
          payoutDate
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      claims {
        items {
          id
          userId
          dependantId
          dependantName
          postalStreet
          postalCity
          postalProvince
          resedentialStreet
          resedentialCity
          resedentialProvince
          copyOfDeath
          copyOfIdMain
          copyOfIdDependant
          copyOfDeathNotice
          copyOfClaim
          copyBankStatements
          status
          reasonForDecline
          createdAt
          updatedAt
        }
        nextToken
      }
      paymentDate
      gender
      email
      cell
      idNumber
      status
      dob
      marketingApproved
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      firstName
      lastName
      id
      dependants {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          relationship
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          contactNumber
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          userId
          reference
          status
          amount
          paymentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      payouts {
        items {
          id
          userId
          amount
          reference
          status
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          userId
          accountName
          accountNo
          bank
          accountType
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      authorization {
        items {
          id
          authorization_code
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        items {
          id
          cost
          name
          annualPayout
          familyCover
          extendedCover
          payoutDate
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      claims {
        items {
          id
          userId
          dependantId
          dependantName
          postalStreet
          postalCity
          postalProvince
          resedentialStreet
          resedentialCity
          resedentialProvince
          copyOfDeath
          copyOfIdMain
          copyOfIdDependant
          copyOfDeathNotice
          copyOfClaim
          copyBankStatements
          status
          reasonForDecline
          createdAt
          updatedAt
        }
        nextToken
      }
      paymentDate
      gender
      email
      cell
      idNumber
      status
      dob
      marketingApproved
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount {
    onCreateAccount {
      id
      userId
      accountName
      accountNo
      bank
      accountType
      code
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount {
    onUpdateAccount {
      id
      userId
      accountName
      accountNo
      bank
      accountType
      code
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount {
    onDeleteAccount {
      id
      userId
      accountName
      accountNo
      bank
      accountType
      code
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAuthorizations = /* GraphQL */ `
  subscription OnCreateAuthorizations {
    onCreateAuthorizations {
      id
      authorization_code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAuthorizations = /* GraphQL */ `
  subscription OnUpdateAuthorizations {
    onUpdateAuthorizations {
      id
      authorization_code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAuthorizations = /* GraphQL */ `
  subscription OnDeleteAuthorizations {
    onDeleteAuthorizations {
      id
      authorization_code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDependant = /* GraphQL */ `
  subscription OnCreateDependant {
    onCreateDependant {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      relationship
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDependant = /* GraphQL */ `
  subscription OnUpdateDependant {
    onUpdateDependant {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      relationship
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDependant = /* GraphQL */ `
  subscription OnDeleteDependant {
    onDeleteDependant {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      relationship
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBeneficiary = /* GraphQL */ `
  subscription OnCreateBeneficiary {
    onCreateBeneficiary {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      contactNumber
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBeneficiary = /* GraphQL */ `
  subscription OnUpdateBeneficiary {
    onUpdateBeneficiary {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      contactNumber
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBeneficiary = /* GraphQL */ `
  subscription OnDeleteBeneficiary {
    onDeleteBeneficiary {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      contactNumber
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePlan = /* GraphQL */ `
  subscription OnCreatePlan {
    onCreatePlan {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePlan = /* GraphQL */ `
  subscription OnUpdatePlan {
    onUpdatePlan {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePlan = /* GraphQL */ `
  subscription OnDeletePlan {
    onDeletePlan {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProrata = /* GraphQL */ `
  subscription OnCreateProrata {
    onCreateProrata {
      id
      plan
      payment1
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProrata = /* GraphQL */ `
  subscription OnUpdateProrata {
    onUpdateProrata {
      id
      plan
      payment1
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProrata = /* GraphQL */ `
  subscription OnDeleteProrata {
    onDeleteProrata {
      id
      plan
      payment1
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAddress = /* GraphQL */ `
  subscription OnCreateAddress {
    onCreateAddress {
      id
      street
      city
      province
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAddress = /* GraphQL */ `
  subscription OnUpdateAddress {
    onUpdateAddress {
      id
      street
      city
      province
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAddress = /* GraphQL */ `
  subscription OnDeleteAddress {
    onDeleteAddress {
      id
      street
      city
      province
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserPlan = /* GraphQL */ `
  subscription OnCreateUserPlan {
    onCreateUserPlan {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      payoutDate
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserPlan = /* GraphQL */ `
  subscription OnUpdateUserPlan {
    onUpdateUserPlan {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      payoutDate
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserPlan = /* GraphQL */ `
  subscription OnDeleteUserPlan {
    onDeleteUserPlan {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      payoutDate
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment {
    onCreatePayment {
      id
      userId
      reference
      status
      amount
      paymentDate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment {
    onUpdatePayment {
      id
      userId
      reference
      status
      amount
      paymentDate
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment {
    onDeletePayment {
      id
      userId
      reference
      status
      amount
      paymentDate
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePayout = /* GraphQL */ `
  subscription OnCreatePayout {
    onCreatePayout {
      id
      userId
      amount
      reference
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePayout = /* GraphQL */ `
  subscription OnUpdatePayout {
    onUpdatePayout {
      id
      userId
      amount
      reference
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePayout = /* GraphQL */ `
  subscription OnDeletePayout {
    onDeletePayout {
      id
      userId
      amount
      reference
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateClaim = /* GraphQL */ `
  subscription OnCreateClaim {
    onCreateClaim {
      id
      userId
      dependantId
      dependantName
      postalStreet
      postalCity
      postalProvince
      resedentialStreet
      resedentialCity
      resedentialProvince
      copyOfDeath
      copyOfIdMain
      copyOfIdDependant
      copyOfDeathNotice
      copyOfClaim
      copyBankStatements
      status
      reasonForDecline
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateClaim = /* GraphQL */ `
  subscription OnUpdateClaim {
    onUpdateClaim {
      id
      userId
      dependantId
      dependantName
      postalStreet
      postalCity
      postalProvince
      resedentialStreet
      resedentialCity
      resedentialProvince
      copyOfDeath
      copyOfIdMain
      copyOfIdDependant
      copyOfDeathNotice
      copyOfClaim
      copyBankStatements
      status
      reasonForDecline
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteClaim = /* GraphQL */ `
  subscription OnDeleteClaim {
    onDeleteClaim {
      id
      userId
      dependantId
      dependantName
      postalStreet
      postalCity
      postalProvince
      resedentialStreet
      resedentialCity
      resedentialProvince
      copyOfDeath
      copyOfIdMain
      copyOfIdDependant
      copyOfDeathNotice
      copyOfClaim
      copyBankStatements
      status
      reasonForDecline
      createdAt
      updatedAt
    }
  }
`;
