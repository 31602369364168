// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicAdminClaims } from "./plasmic/admin_imbewu/PlasmicAdminClaims";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setBeneficary, setClaim, setClaims, setDependant, setDependants, showAdminBeneficiaryModal, showAdminBeneficiaryModalEdit, showAdminClaimsModal, showAdminDependantModal, showAdminDependantModalEdit, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import moment from "moment";
import ListUsers from "./ListUsers";
import ListItemDependentBeneficicaryA from "./ListItemDependentBeneficicaryA";
import { API } from 'aws-amplify'
import { deleteBeneficiary, deleteClaim, deleteDependant } from '../graphql/mutations'
import * as queries from '../graphql/queries';
import { onBeneficiaryDelete, onDeleteClaim, onDependantDelete } from "../graphql/subscriptions";
import AdminClaimListItem from "./AdminClaimListItem";
function AdminClaims_(props, ref) {
  const [subscription, setSubscription] = React.useState(null);
  React.useEffect(() => {
    if(subscription==null){
      try {
        setSubscription(API.graphql({
          query: onDeleteClaim,
          variables: {
            userId: props.otp.adminUser.id
          }
        })
        .subscribe({
          next: data => {
            console.log('LISTEN claim delete',props.otp)
            getClaims(props.otp.adminUser.id)
          }
        }))
      } catch (error) {
        
      }
      
    }
    
   
  }, [subscription]);
  
  const getClaims = React.useCallback(async(id) => {
    try {
      
      const beneficiariesReceived = await API.graphql({ query: queries.listClaims, variables: { filter: {
        userId: {
            eq: props.otp.adminUser.id
        }
      }}});
      console.log('admin get benef', beneficiariesReceived.data.listClaims.items)
      props.setClaims(beneficiariesReceived.data.listClaims.items)
      subscription.unsubscribe()
    } catch (error) {
      console.log('ready to auth error', error)
    }
  },[props.otp.adminUser.id]);
 
  const removeBeneficary = async(item) => {
    
    props.showLoadingModal(true)
    const user = await API.graphql({
      query: deleteClaim,
      variables: {
        input: {
          id: item.id
        },
      },
    })
    props.showLoadingModal(false)
   //retrieveUser()
  }

  console.log('Admin Claims', props.view.payments[0])
  return <PlasmicAdminClaims root={{ ref }} {...props} 
  nextPayment = {props.view.payments.length>0 && moment(props.view.payments[props.view.payments.length-1].createdAt).add(1, 'months').format('DD-MM-YYYY')}
      latestPayment = {props.view.payments.length>0 && moment(props.view.payments[props.view.payments.length-1].createdAt).add(0, 'months').format('DD-MM-YYYY')}
      latestPayout = {props.view.payments.length>0 && moment(props.view.payments[0].createdAt).add(1, 'years').format('DD-MM-YYYY')}
      addClaim= {{
        onClick:(e)=>{
          if(props.view.beneficiary.length==0){
            props.showAdminClaimsModal(true)
          }
            
        }
      }}
      scroller = {{
        children: props.view.claims.map((item, index)=>(
          <React.Fragment key={index}>
            <AdminClaimListItem
            txtName = {item.dependantName} 
            status = {String(item.status).toLowerCase()}
            txtAmount = {"R "+props.otp.adminUser.plan.items[0].familyCover}
            btnView = {{
              onClick:(e)=>{
                if(item.status=='Submitted'){
                  props.setClaim(item)
                  props.showAdminClaimsModal(true)
                }else if(item.status=='failure'){
                  props.showErrorModal(item.reasonForDecline)
                }
               
              }
             
            }}
            btnDelete = {{
              onClick:(e)=>{
                removeBeneficary(item)
              }
            }}
            />
          </React.Fragment>
       ))
      }}
  />;
}

const AdminClaims = React.forwardRef(AdminClaims_);

const mapStateToProps = state => ({
  otp: state.otpReducer,
  view: state.viewReducer
});


const mapDispatchToProps = dispatch => ({
  
  showAdminClaimsModal: (string) => dispatch(showAdminClaimsModal(string)),
  setDependant: (obj) => dispatch(setDependant(obj)),
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  showErrorModal: (obj) => dispatch(showErrorModal(obj)),
  setClaims: (obj) => dispatch(setClaims(obj)),
  setClaim: (obj) => dispatch(setClaim(obj)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(AdminClaims);
