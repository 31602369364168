/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCandidate = /* GraphQL */ `
  query GetCandidate($id: ID!) {
    getCandidate(id: $id) {
      id
      name
      email
      cell
      comment
      createdAt
      updatedAt
    }
  }
`;
export const listCandidates = /* GraphQL */ `
  query ListCandidates(
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        cell
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      firstName
      lastName
      id
      dependants {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          relationship
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          firstName
          lastName
          gender
          dateOfBirth
          idNumber
          contactNumber
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      payments {
        items {
          id
          userId
          reference
          status
          amount
          paymentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      payouts {
        items {
          id
          userId
          amount
          reference
          status
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          userId
          accountName
          accountNo
          bank
          accountType
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      authorization {
        items {
          id
          authorization_code
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        items {
          id
          cost
          name
          annualPayout
          familyCover
          extendedCover
          payoutDate
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      claims {
        items {
          id
          userId
          dependantId
          dependantName
          postalStreet
          postalCity
          postalProvince
          resedentialStreet
          resedentialCity
          resedentialProvince
          copyOfDeath
          copyOfIdMain
          copyOfIdDependant
          copyOfDeathNotice
          copyOfClaim
          copyBankStatements
          status
          reasonForDecline
          createdAt
          updatedAt
        }
        nextToken
      }
      paymentDate
      gender
      email
      cell
      idNumber
      status
      dob
      marketingApproved
      type
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        firstName
        lastName
        id
        dependants {
          nextToken
        }
        beneficiaries {
          nextToken
        }
        payments {
          nextToken
        }
        payouts {
          nextToken
        }
        account {
          nextToken
        }
        authorization {
          nextToken
        }
        plan {
          nextToken
        }
        claims {
          nextToken
        }
        paymentDate
        gender
        email
        cell
        idNumber
        status
        dob
        marketingApproved
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      userId
      accountName
      accountNo
      bank
      accountType
      code
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        accountName
        accountNo
        bank
        accountType
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAuthorizations = /* GraphQL */ `
  query GetAuthorizations($id: ID!) {
    getAuthorizations(id: $id) {
      id
      authorization_code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listAuthorizations = /* GraphQL */ `
  query ListAuthorizations(
    $filter: ModelAuthorizationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthorizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authorization_code
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDependant = /* GraphQL */ `
  query GetDependant($id: ID!) {
    getDependant(id: $id) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      relationship
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listDependants = /* GraphQL */ `
  query ListDependants(
    $filter: ModelDependantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDependants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        gender
        dateOfBirth
        idNumber
        relationship
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBeneficiary = /* GraphQL */ `
  query GetBeneficiary($id: ID!) {
    getBeneficiary(id: $id) {
      id
      firstName
      lastName
      gender
      dateOfBirth
      idNumber
      contactNumber
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listBeneficiaries = /* GraphQL */ `
  query ListBeneficiaries(
    $filter: ModelBeneficiaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBeneficiaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        gender
        dateOfBirth
        idNumber
        contactNumber
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      createdAt
      updatedAt
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cost
        name
        annualPayout
        familyCover
        extendedCover
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProrata = /* GraphQL */ `
  query GetProrata($id: ID!) {
    getProrata(id: $id) {
      id
      plan
      payment1
      amount
      createdAt
      updatedAt
    }
  }
`;
export const listProratas = /* GraphQL */ `
  query ListProratas(
    $filter: ModelProrataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProratas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        plan
        payment1
        amount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      street
      city
      province
      createdAt
      updatedAt
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        street
        city
        province
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserPlan = /* GraphQL */ `
  query GetUserPlan($id: ID!) {
    getUserPlan(id: $id) {
      id
      cost
      name
      annualPayout
      familyCover
      extendedCover
      payoutDate
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listUserPlans = /* GraphQL */ `
  query ListUserPlans(
    $filter: ModelUserPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cost
        name
        annualPayout
        familyCover
        extendedCover
        payoutDate
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      userId
      reference
      status
      amount
      paymentDate
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        reference
        status
        amount
        paymentDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayout = /* GraphQL */ `
  query GetPayout($id: ID!) {
    getPayout(id: $id) {
      id
      userId
      amount
      reference
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const listPayouts = /* GraphQL */ `
  query ListPayouts(
    $filter: ModelPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        amount
        reference
        status
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClaim = /* GraphQL */ `
  query GetClaim($id: ID!) {
    getClaim(id: $id) {
      id
      userId
      dependantId
      dependantName
      postalStreet
      postalCity
      postalProvince
      resedentialStreet
      resedentialCity
      resedentialProvince
      copyOfDeath
      copyOfIdMain
      copyOfIdDependant
      copyOfDeathNotice
      copyOfClaim
      copyBankStatements
      status
      reasonForDecline
      createdAt
      updatedAt
    }
  }
`;
export const listClaims = /* GraphQL */ `
  query ListClaims(
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        dependantId
        dependantName
        postalStreet
        postalCity
        postalProvince
        resedentialStreet
        resedentialCity
        resedentialProvince
        copyOfDeath
        copyOfIdMain
        copyOfIdDependant
        copyOfDeathNotice
        copyOfClaim
        copyBankStatements
        status
        reasonForDecline
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
