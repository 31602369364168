import { takeLatest, call, put, select } from 'redux-saga/effects';
import axios from 'axios';
import { PWASuccess, PWARequest, PWAFailure } from '../reducers/updatePWA';
import { API_LINK } from '../../constants';

const API = `${API_LINK}api/checkVersion`;

function* PWAAxios() {

  const state = yield select(fullState => fullState);
  const {
    user: { userId },
    token,
  } = state.loginReducer;
  const response = yield call(() => {

  return axios({
    method: 'get',
    url: API,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${token}`,
    },
    credentials: 'same-origin',
  });
});

  return response;
}

function* workerPWASaga() {
  try {
    const response = yield call(PWAAxios);
    const responseFromPWA= response.data;
    
    yield put(PWASuccess(responseFromPWA));
    if (response.status === 500) {
      yield put(PWAFailure(responseFromPWA));
    }
  } catch (error) {
     yield put(PWAFailure(error));
  }
}

export function* watcherPWASaga() {
  yield takeLatest(PWARequest().type, workerPWASaga);
}
