// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicRegister5AddPayout } from "./plasmic/imbewu_mobile_first/PlasmicRegister5AddPayout";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { changeStep, setPlan, setStep, showLoadingModal } from "../redux/reducers/view";
import Select__Option from "./Select__Option";
import { API } from 'aws-amplify'
import {createAccount} from '../graphql/mutations'
import banks from '../assets/banks.json';
import Select from 'react-select'
function Register5AddPayout_(props, ref) {
  const inputAccountName = React.useRef(null);
  const [accountName, setAccountName] = React.useState(null);
  const [errorAccountName, setErrorAccountName] = React.useState(false);
  const [errorAccountNameTxt, setErrorAccountNameTxt] = React.useState(null);

  const inputBranch = React.useRef(null);
  const [branch, setBranch] = React.useState(null);
  const [errorBranch, setErrorBranch] = React.useState(false);
  const [errorBranchTxt, setErrorBranchTxt] = React.useState(null);


  const inputBank = React.useRef(null);
  const [bank, setBank] = React.useState(null);
  const [errorBank, setErrorBank] = React.useState(true)
  const [errorBankTxt, setErrorBankTxt] = React.useState(null);

  const inputType= React.useRef(null);
  const [type, setType] = React.useState(null);
  const [errorType, setErrorType] = React.useState(true)
  const [errorTypeTxt, setErrorTypeTxt] = React.useState(null);

  const inputAccountNo= React.useRef(null);
  const [accountNo, setAccountNo] = React.useState(null);
  const [errorAccountNo, setErrorAccountNo] = React.useState(true)
  const [errorAccountNoTxt, setErrorAccountNoTxt] = React.useState(null);


  const onChangeAccountName =  React.useCallback((e) => {
    setAccountName(e);
  },[accountName])

  const onChangeBranch =  React.useCallback((e) => {
    setBranch(e);
  },[branch])

  const onChangeAccountNo =  React.useCallback((e) => {
    console.log('Acc')
    setAccountNo(e);
  },[accountNo])

  const onChangeBank=  React.useCallback((e) => {
    setBank(e.value);
  },[bank])

  const onChangeType=  React.useCallback((e) => {
    setType(e);
  },[type])

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const validate =  React.useCallback((e) => {
   
    if(accountName==null || accountName.length<3){
     
      setErrorAccountNameTxt('Required')
      setErrorAccountName(true)
      return false
    }else{
      setErrorAccountName(false)
    }

   

    
    if(accountNo==null || accountNo.length<6){
      console.log('Account', accountNo)
      setErrorAccountNoTxt('Required')
      setErrorAccountNo(false)

      return false
    }else{
      setErrorAccountNo(true)
    }

    if(bank==null ){
    
      setErrorBankTxt('Required')
      setErrorBank(false)

      return false
    }else{
      setErrorBank(true)
    }
  
    if(type==null ){
    
      setErrorTypeTxt('Required')
      setErrorType(false)

      return false
    }else{
      setErrorType(true)
    }

    if(branch==null ){
    
      setErrorBranchTxt('Required')
      setErrorBranch(true)

      return false
    }else{
      setErrorBranch(false)
    }
  
   
   
   return true
 },[accountNo, accountName, type, branch,bank])

 React.useEffect(() => {
    props.showLoadingModal(false)
 },[])

 const setBankAccount = async() => {
   console.log('userc',props)
  props.showLoadingModal(true)
      const user = await API.graphql({
        query: createAccount,
        variables: {
          input: {
            userId: props.otpReducer.user.id,
            accountName:accountName,
            accountNo: accountNo,
            bank:bank,
            accountType:type,
            code:branch
            }
          },

      })
      props.changeStep(6)
      props.showLoadingModal(false)
      changePage('/accountsuccess')
};

const skipPayout = async() => {
     props.changeStep(6)
     props.showLoadingModal(false)
     changePage('/accountsuccess')
};

  return <PlasmicRegister5AddPayout root={{ ref }} {...props} 
  accountName = {{
    txtValue : {
      onChange: (e) => onChangeAccountName(e.target.value),
      value: accountName,
      placeHolder: 'Account Name'
    },
    txtError: errorAccountNameTxt,
    showError:errorAccountName,
    ref: inputAccountName
  }}

  branchCode = {{
    txtValue : {
      onChange: (e) => onChangeBranch(e.target.value),
      value: branch,
      placeHolder: 'Branch Code'
    },
    txtError: errorBranchTxt,
    showError:errorBranch,
    ref: inputBranch
  }}
  accountNo= {{
    txtValue : {
      onChange: (e) => onChangeAccountNo(e.target.value),
      value: accountNo,
    },
    txtError: errorAccountNoTxt,
    showError:errorAccountNo,
    ref: inputAccountNo
  }}

  selectBank = {{
    txtError: errorBankTxt,
    showError:errorBank,
    ref: inputBank,
    selectTxt:'Bank',
   selectHolder: {
     placeholder:'Select your bank...',
     children:  <div  style={{zIndex:9, width:"100%"}}>
     <Select
       options={banks}
       isSearchable
       placeholder="Select..."
       onChange = {onChangeBank}
   />
 </div>
  }}
  }
  submitBtn={{
    onClick:(e)=>{
      if(validate()){
        setBankAccount()
      }
     
    }
  }}

  btnSkip = {{
    onClick:(e) =>{
      skipPayout()
    }
  }}

  accountType = {{
    txtError: errorTypeTxt,
    showError:errorType,
    ref: inputType,
    selectTxt:'Account Type',
   selectGender: {
    placeholder:'Select your account type...',
     children:  <React.Fragment>
   <Select__Option
     value={"Checking"}
   >
     {"Checking"}
   </Select__Option>

   <Select__Option
     value={"Savings"}
   >
     {"Savings"}
   </Select__Option>

   <Select__Option
     value={"Current"}
   >
     {"Current"}
   </Select__Option>
 </React.Fragment>,
    onChange: (e) => onChangeType(e),
    selectedContent: type
  }}
  }
  
  />;
}

const Register5AddPayout = React.forwardRef(Register5AddPayout_);

const mapStateToProps = state => ({
  view: state.viewReducer,
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setStep: (int) => dispatch(setStep(int)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  changeStep: (int) => dispatch(changeStep(int)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Register5AddPayout);
