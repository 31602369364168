// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicModalCancelSuccess } from "./plasmic/imbewu_mobile_first/PlasmicModalCancelSuccess";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showCancelSuccess } from "../redux/reducers/view";
function ModalCancelSuccess_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  return <div>
  {props.viewReducer.showCancelSuccess ? <div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}>
    <PlasmicModalCancelSuccess root={{ ref }} {...props} 
     btnClose = {{
      onClick:(e)=>{
        props.showCancelSuccess(false)
        changePage('/plancancelled')
      }
    }}
    btnCloseMain = {{
      onClick:(e)=>{
        props.showCancelSuccess(false)
        changePage('/plancancelled')
      }
    }}
    
    />
    </div>:null}
</div>
}

const ModalCancelSuccess = React.forwardRef(ModalCancelSuccess_);

const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
});

const mapDispatchToProps = dispatch => ({
  showCancelSuccess: (bool) => dispatch(showCancelSuccess(bool))
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalCancelSuccess);
