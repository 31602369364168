// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Icon32Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      stroke={"currentColor"}
      fill={"currentColor"}
      strokeWidth={"0"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M4.75 17.25a.75.75 0 01.75.75v2.25c0 .138.112.25.25.25h12.5a.25.25 0 00.25-.25V18a.75.75 0 011.5 0v2.25A1.75 1.75 0 0118.25 22H5.75A1.75 1.75 0 014 20.25V18a.75.75 0 01.75-.75z"
        }
        stroke={"none"}
      ></path>

      <path
        d={
          "M5.22 9.97a.749.749 0 011.06 0l4.97 4.969V2.75a.75.75 0 011.5 0v12.189l4.97-4.969a.749.749 0 111.06 1.06l-6.25 6.25a.749.749 0 01-1.06 0l-6.25-6.25a.749.749 0 010-1.06z"
        }
        stroke={"none"}
      ></path>
    </svg>
  );
}

export default Icon32Icon;
/* prettier-ignore-end */
