// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicAdminDashboard } from "./plasmic/copy_of_imbewu_admin/PlasmicAdminDashboard";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import ListUsers from "./ListUsers";
import * as queries from '../graphql/queries';

import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setBeneficary, setClaims, setDependant, setDependants, setPayments, setPayouts, setPlan, setUserMain, showAdminCreateModal, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import { setAdminUser, setSession, setUser, setUserName, setVerified } from "../redux/reducers/otp";
import * as XLSX from 'xlsx';

function AdminDashboard_(props, ref) {
  const [users, setUsers] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(null);
  
  React.useEffect(() => {
   listAllUsers()
   
  }, []);

  function exportUserDataToExcel() {
    // Prepare data for Excel export
    const data = users.map((user) => ({
      FirstName: user.Attributes.find(attr => attr.Name === 'name')?.Value || '',
      LastName: user.Attributes.find(attr => attr.Name === 'family_name')?.Value || '',
      Email: user.Attributes.find(attr => attr.Name === 'email')?.Value || '',
      Phone: user.Attributes.find(attr => attr.Name === 'phone_number')?.Value || '',
      Gender: user.Attributes.find(attr => attr.Name === 'gender')?.Value || '',
      UserCreateDate: user.UserCreateDate || ''
    }));
  
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'User Data');
  
    // Generate Excel file
    const excelData = XLSX.write(workbook, { type: 'binary', bookType: 'xlsx' });
    const buffer = new ArrayBuffer(excelData.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < excelData.length; i++) {
      view[i] = excelData.charCodeAt(i) & 0xff;
    }
  
    // Create a Blob from the ArrayBuffer
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
  
    // Create a download link and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'user_data.xlsx';
    link.click();
  }

  const onChangeSearch = React.useCallback((e) => {
    console.log(e)
    setSearchTerm(e);
  },[searchTerm])

  let nextToken;

  async function listAllUsers(limit) {
    const apiName = 'AdminQueries';
    const path = '/listUsersInGroup';
    const myInit = {
      queryStringParameters: {
        groupname: 'hurtusers',
        limit,
        token: nextToken,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
  
    const { Users, NextToken } = await API.get(apiName, path, myInit);
  
    const filteredUsers = Users.filter(user => user.UserStatus !== 'FORCE_CHANGE_PASSWORD');
  
    setUsers(filteredUsers);
    props.showLoadingModal(false);
  
    nextToken = NextToken;
  }
  


  async function searchUsers(){

    try {
      let apiName = 'AdminQueries';
      let path = '/getUser';
      let myInit = { 
          queryStringParameters: {
            "groupname": "hurtusers",
            "username": searchTerm
          },
          headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          }
      }
      const { NextToken, ...rest } =  await API.get(apiName, path, myInit);

      console.log('admin search', rest)
      let user = []
      user.push(rest)
      setUsers(user);
    } catch (error) {
      console.log('admin error')
      props.showErrorModal('User does not exist')
    }
    
  }

  function getName(attributes){
    let firstName
    let lastName
    for (let index = 0; index < attributes.length; index++) {
      const element = attributes[index];
      if(element.Name=='name'){
        firstName = element.Value
      }
      if(element.Name=='family_name'){
        lastName = element.Value
      }
      
    }
    return firstName+' '+lastName
  }

  function getCell(attributes){
    let cell
   
    for (let index = 0; index < attributes.length; index++) {
      const element = attributes[index];
      if(element.Name=='phone_number'){
        cell = element.Value
      }
     
      
    }
    return cell
  }

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  async function showUser(user){
    props.showLoadingModal(true)
    const userReceived = await API.graphql(graphqlOperation(queries.getUser, { id:user.Username }));
      
    
      var loggedinUser =userReceived.data.getUser;
      //props.setUser(userReceived);
      props.setSession(null);
      props.setVerified(true)
      console.log('admin user get',loggedinUser)
      props.setDependants(loggedinUser.dependants.items)
      props.setBeneficary(loggedinUser.beneficiaries.items)
      props.setPayouts(loggedinUser.payouts.items)
      props.setPayments(loggedinUser.payments.items)
      props.setClaims(loggedinUser.claims.items)
      props.setPlan(loggedinUser.plan.items[0])
      props.setUser(loggedinUser);
      props.showLoadingModal(false)
      console.log('XXX', loggedinUser)
      changePage('/adminuserprofile/'+user.Username)
      
  }
  return <PlasmicAdminDashboard root={{ ref }} {...props} 

      navPageTitle = {{
       title:"Dashboard"
      }} 

      inputSearch = {{
        txtValue : {
          onChange: (e) => onChangeSearch(e.target.value),
          value: searchTerm,
          placeHolder: 'Search by email'
        }
      }}

      btnAddUser =  {{
        onClick:(e)=>{
         props.showAdminCreateModal(true)
        }
      }}


      btnSearch = {{
        onClick:(e)=>{
          searchUsers()
        }
      }}

      btnExport = {{
        onClick:(e)=>{
          exportUserDataToExcel()
        }
      }}



      userList = {{
          children: users.map((item, index)=>(
            <React.Fragment key={index}>
              <ListUsers 
              nameTxt = {item.Attributes ? getName(item.Attributes):getName(item.UserAttributes)} 
              idTxt = {item.Attributes ? getCell(item.Attributes):getCell(item.UserAttributes)}
              btnEdit = {{
                onClick:(e)=>{
                  showUser(item)
                }
              }}
              />
            </React.Fragment>
         ))
        }}
  
  />;
}

const AdminDashboard = React.forwardRef(AdminDashboard_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer
});

const mapDispatchToProps = dispatch => ({
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  showErrorModal: (obj) => dispatch(showErrorModal(obj)),
  showAdminCreateModal: (obj) => dispatch(showAdminCreateModal(obj)),
  setSession: (string) => dispatch(setSession(string)),
  setVerified: (string) => dispatch(setVerified(string)),
  setUser: (obj) => dispatch(setAdminUser(obj)),
  setUserName: (obj) => dispatch(setUserName(obj)),
  setUserMain: (obj) => dispatch(setUserMain(obj)),
  setDependants: (obj) => dispatch(setDependants(obj)),
  setBeneficary: (obj) => dispatch(setBeneficary(obj)),
  setPayments: (obj) => dispatch(setPayments(obj)),
  setPayouts: (obj) => dispatch(setPayouts(obj)),
  setClaims: (obj) => dispatch(setClaims(obj)),
  setPlan: (obj) => dispatch(setPlan(obj))
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(AdminDashboard);

