// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect } from 'react';
import { PlasmicStartup } from "./plasmic/imbewu_mobile_first/PlasmicStartup";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import {Analytics} from '@aws-amplify/analytics';
import { setSession, setUser } from "../redux/reducers/otp";

Amplify.configure(awsconfig);

const NOTSIGNIN = 'You are NOT logged in';
const SIGNEDIN = 'You have logged in successfully';
const SIGNEDOUT = 'You have logged out successfully';
const WAITINGFOROTP = 'Enter OTP number';
const VERIFYNUMBER = 'Verifying number (Country code +XX needed)';

function Startup_(props, ref) {
  const [message, setMessage] = useState('Starting up ...');

  // useEffect(() => {
  //   console.log('Ready to auth');
  //   // Auth.currentCredentials();
  //   setTimeout(verifyAuth, 1500);
  //   Analytics.autoTrack('session', {
  //     enable: true,
  //   });
  // }, []);

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const verifyAuth = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        props.setUser(user);
        props.setSession(null);
        changePage('/home')
      })
      .catch((err) => {
        console.error(err);
       // changePage('/welcome')
      });
  };
  return <div className="content"><PlasmicStartup root={{ ref }} {...props} 
    txtStartup = {message}
    className="content--inner"
  /></div>;
}

const Startup = React.forwardRef(Startup_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setSession: (string) => dispatch(setSession(string)),
  setUser: (obj) => dispatch(setUser(obj))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps), 
  withRouter)(Startup) ;

