import * as React from "react";
import { PlasmicSelectA__OverlayA } from "./plasmic/copy_of_imbewu_admin/PlasmicSelectA__OverlayA";

function SelectA__OverlayA_(props, ref) {
  const { plasmicProps } = PlasmicSelectA__OverlayA.useBehavior(props, ref);
  return <PlasmicSelectA__OverlayA {...plasmicProps} />;
}

const SelectA__OverlayA = React.forwardRef(SelectA__OverlayA_);

export default Object.assign(SelectA__OverlayA, {
  __plumeType: "triggered-overlay"
});
