// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { hideErrorModal, hideNotifyModal, showErrorModal, showNotifyModal } from "../redux/reducers/view";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PlasmicModalNotify from "./plasmic/imbewu_mobile_first/PlasmicModalNotify";
function ModalNotify_(props, ref) {
  
  return <div>
    {props.viewReducer.showNotifyModal ? <div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}>
    <PlasmicModalNotify root={{ ref }} {...props} 
      txtModal = {props.viewReducer.message}
      btnClose = {{
        onClick: (e) => {
          props.hideNotifyModal()
        }
      }}
    />;
  </div> : null}
  </div>
}

const ModalNotify = React.forwardRef(ModalNotify_);
const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
});

const mapDispatchToProps = dispatch => ({
  hideNotifyModal: () => dispatch(hideNotifyModal()),
  showNotifyModal:(string) => dispatch(showNotifyModal(string))
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalNotify);

