// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicUserInfoArea } from "./plasmic/copy_of_imbewu_admin/PlasmicUserInfoArea";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showDependantModal, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import { setAdminUser, setUserName } from "../redux/reducers/otp";
import { API,graphqlOperation } from 'aws-amplify'
import {createUser, updateAccount, updateUser} from '../graphql/mutations'
import Select from 'react-select'
import genderList from '../assets/gender';
import banks from '../assets/banks.json';
import accountTypes from '../assets/acountTypes.json';
import { onBeneficiaryCreate, onUserUpdate } from "../graphql/subscriptions";
import * as queries from '../graphql/queries';
import axios from 'axios'
import qs from 'qs'

function UserInfoArea_(props, ref) {
  const inputElement1 = React.useRef(null);
  const inputElement2 = React.useRef(null);
  const inputElement3 = React.useRef(null);
  const inputFirstName = React.useRef(null);
  const inputLastName = React.useRef(null);
  const inputGender = React.useRef(null);
  const inputCell = React.useRef(null);
  const inputEmail = React.useRef(null);
  const [cellNumber1, setCellNumber1] = React.useState(null);
  const [cellNumber2, setCellNumber2] = React.useState(null);
  const [cellNumber3, setCellNumber3] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [valid, setValid] = React.useState(true);
  const [email, setEmail] = React.useState(null);
  const [codeTxt, setCode] = React.useState(null);
  const [errorCodeTxt, setErrorCodeTxt] = React.useState(true);
  const [errorCode, setErrorCode] = React.useState(false);
  
  
  const [gender, setGender] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [errorFirstNameTxt, setErrorFirstNameTxt] = React.useState(true);
  const [errorFirstName, setErrorFirstName] = React.useState(false);
  const [errorLastNameTxt, setErrorLastNameTxt] = React.useState(true);
  const [errorLastName, setErrorLastName] = React.useState(false);
  const [errorGender, setErrorGender] = React.useState(true);

  const [errorDob, setErrorDob] = React.useState(false);
  const [errorDobTxt, setErrorDobTxt] = React.useState(true);

  const [errorCell, setErrorCell] = React.useState(true);
 
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorEmailTxt, setErrorEmailTxt] = React.useState(true);
  const [errorPassword, setErrorPassword] = React.useState(true);
  const [errorConfirmPassword, setErrorConfirmPassword] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [idNumber, setSAId] = React.useState(null);
  const [errorSAId, setErrorSAId] = React.useState(false);
  const [errorSAIdTxt, setErrorSAIdTxt] = React.useState(null);
  const [errorDOB, setErrorDOB] = React.useState(false);
  const [errorDOBTxt, setErrorDOBTxt] = React.useState(false);
  const [errorTerms, setErrorTerms] = React.useState(true);
  const [errorTermsTxt, setErrorTermsTxt] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [year, setYear] = React.useState(null);
  const [month, setMonth] = React.useState(null);
  const [day, setDay] = React.useState(null);
  const [plan, setPlan] = React.useState(null);

  const inputAccountName = React.useRef(null);
  const [accountName, setAccountName] = React.useState(null);
  const [errorAccountName, setErrorAccountName] = React.useState(false);
  const [errorAccountNameTxt, setErrorAccountNameTxt] = React.useState(null);

  const inputBranch = React.useRef(null);
  const [branch, setBranch] = React.useState(null);
  const [errorBranch, setErrorBranch] = React.useState(false);
  const [errorBranchTxt, setErrorBranchTxt] = React.useState(null);


  const [oldPassword, setOldPassword] = React.useState(null);
  const [newPassword, setNewPassword] = React.useState(null);

  const inputBank = React.useRef(null);
  const [bank, setBank] = React.useState(null);
  const [errorBank, setErrorBank] = React.useState(true)
  const [errorBankTxt, setErrorBankTxt] = React.useState(null);

  const inputType= React.useRef(null);
  const [type, setType] = React.useState(null);
  const [errorType, setErrorType] = React.useState(true)
  const [errorTypeTxt, setErrorTypeTxt] = React.useState(null);

  const inputAccountNo= React.useRef(null);
  const [accountNo, setAccountNo] = React.useState(null);
  const [errorAccountNo, setErrorAccountNo] = React.useState(true)
  const [errorAccountNoTxt, setErrorAccountNoTxt] = React.useState(null);
  const [subscription, setSubscription] = React.useState(null);
  

  React.useEffect(() => {
  
    if(subscription==null){
      console.log('Listen  user setup')
      try {
        setSubscription(API.graphql({
          query: onUserUpdate,
          variables: {
            userId: props.otpReducer.adminUser.id
          }
        })
        .subscribe({
          next: data => {
            console.log('LISTEN user update',props.otpReducer)
            getBeneficiaries(props.otpReducer.adminUser.id)
          }
        }))
      } catch (error) {
        
      }
      
    }
    
   
  }, [subscription]);

  const sendCode = async()=>{
    console.log('wet 1',props.otpReducer.adminUser)
    var data = qs.stringify({
      'cell':props.otpReducer.adminUser.cell
    });
    var config = {
      method: 'post',
      url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/checkverifyhurt',
      headers: { 
        "x-api-key": process.env.API_IMBEWU2_GRAPHQLAPIKEYOUTPUT,
        'Content-Type': 'application/x-www-form-urlencoded', 
       },
       data:data
    };
   
      const test = await axios(config)
      .then(function (response) {
        //console.log('Pay',JSON.parse(response.data));
        //setPay(JSON.parse(response.data))
        props.showErrorModal("OTP Pin was sent successfuly")
      })
      .catch(function (error) {
        console.log('Pay error',error);
      });
      console.log('Wet', test)

  }

  const checkCode = async()=>{
    console.log('wet 1',props.otpReducer.adminUser)
    var data = qs.stringify({
      'cell':props.otpReducer.adminUser.cell,
      'code':codeTxt
    });
    var config = {
      method: 'post',
      url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/checkcodehurt',
      headers: { 
        "x-api-key": process.env.API_IMBEWU2_GRAPHQLAPIKEYOUTPUT,
        'Content-Type': 'application/x-www-form-urlencoded', 
       },
       data:data
    };
   
      const test = await axios(config)
      .then(function (response) {
        console.log('Cehck Code',response.data);
        if(response.data.status=='approved'){
          updateInfo()
        }else{
          props.showErrorModal('Invalid Pin')
        }
  
      })
      .catch(function (error) {
        console.log('Pay error',error);
      });
      console.log('Wet', test)

  }


  const onChangeCode = React.useCallback((e) => {
    setCode(e);
  },[codeTxt])


  const getBeneficiaries = React.useCallback(async(id) => {
    try {
      
      const userReceived = await API.graphql(graphqlOperation(queries.getUser, { id:props.otpReducer.adminUser.id}));
      
      console.log('listen user received', userReceived)
      props.setUser(userReceived.data.getUser);
      subscription.unsubscribe()
    } catch (error) {
      console.log('ready to auth error', error)
    }
  },[props.otpReducer.adminUser.id]);


  React.useEffect(() => {
   
    if(props.otpReducer.adminUser && firstName==null){
      console.log('admin user area', props.otpReducer)
      setFirstName(props.otpReducer.adminUser.firstName)
      setLastName(props.otpReducer.adminUser.lastName)
    
      setGender(props.otpReducer.adminUser.gender)
      setSAId(props.otpReducer.adminUser.idNumber)
      var yearSplit = String(props.otpReducer.adminUser.idNumber).substr(0,2)
    var monthSplit = String(props.otpReducer.adminUser.idNumber).substr(2,2)
    var daySplit = String(props.otpReducer.adminUser.idNumber).substr(4,2)
   
    if(parseInt(yearSplit) < 30){
      yearSplit = "20"+yearSplit
    }else{
      yearSplit = "19"+yearSplit
    }
    console.log('SAID2', props.otpReducer.user,props.otpReducer.adminUser)
    setYear(yearSplit)
    setMonth(monthSplit)
    setDay(daySplit)
      setCellNumber1(String(props.otpReducer.adminUser.cell).substring(3, 5));
      setCellNumber2(String(props.otpReducer.adminUser.cell).substring(5, 8));
      setCellNumber3(String(props.otpReducer.adminUser.cell).substring(8));
      setEmail(props.otpReducer.adminUser.email)
      if(props.otpReducer.adminUser.account.items.length>0){
        var account = props.otpReducer.adminUser.account.items[0]
        setAccountNo(account.accountNo)
        setAccountName(account.accountName)
        setType(account.accountType)
        setBank(account.bank)
  
        setBranch(account.code)
      }
      
    }

  }, [props.otpReducer, props.view]);

  const onChangeSAId= React.useCallback((e) => {
    var yearSplit = String(e).substr(0,2)
    var monthSplit = String(e).substr(2,2)
    var daySplit = String(e).substr(4,2)
   
    if(parseInt(yearSplit) < 30){
      yearSplit = "20"+yearSplit
    }else{
      yearSplit = "19"+yearSplit
    }
    console.log('SAID', yearSplit, monthSplit, daySplit)
    setYear(yearSplit)
    setMonth(monthSplit)
    setDay(daySplit)
    setSAId(e); 
  },[idNumber])

  const onChangeCell1 =  React.useCallback((e) => {
    if(e.length<3){
      setCellNumber1(e); 
    }
    console.log('tab', e.length)
    if(e.length==2){
     inputElement2.current.focus()
    }
   
  },[cellNumber1])

  const onChangeCell2 =  React.useCallback((e) => {
    if(e.length<4){
    setCellNumber2(e); 
    }
    if(e.length==3){
      inputElement3.current.focus()
     }
  },[cellNumber2])

  const onChangeCell3 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber3(e); 
    }
  },[cellNumber3])
  
  const onChangeShowPassword=  React.useCallback((e) => {
    console.log('LLL', showPassword)
    setShowPassword(!showPassword); 
  },[showPassword])

  const onChangeShowConfirmPassword=  React.useCallback((e) => {
    console.log('LLL2', showConfirmPassword)
    setShowConfirmPassword(!showConfirmPassword); 
  },[showConfirmPassword])


  const onChangeFirstName = React.useCallback((e) => {
    setFirstName(e);
  },[firstName])

  const onChangeLastName =  React.useCallback((e) => {
    console.log('LLL4', firstName)
    setLastName(e);
  },[lastName])

  const onChangeEmail = React.useCallback((e) => {
    setEmail(e); 
  },[email])



  const onChangeGender =  React.useCallback((e) => {
    setGender(e.value);
  },[gender])



  const onChangeAccountName =  React.useCallback((e) => {
    setAccountName(e);
  },[accountName])

  const onChangeBranch =  React.useCallback((e) => {
    setBranch(e);
  },[branch])

  const onChangeAccountNo =  React.useCallback((e) => {
    console.log('Acc')
    setAccountNo(e);
  },[accountNo])

  const onChangeBank=  React.useCallback((e) => {
    setBank(e.value);
  },[bank])

  const onChangeType=  React.useCallback((e) => {
    setType(e.value);
  },[type])

  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);




  const validate =  React.useCallback((e) => {

    if(accountName==null || accountName.length<3){
     
      setErrorAccountNameTxt('Required')
      setErrorAccountName(true)
      return false
    }else{
      setErrorAccountName(false)
    }

   

    
    if(accountNo==null || accountNo.length<6){
      console.log('Account', accountNo)
      setErrorAccountNoTxt('Required')
      setErrorAccountNo(false)

      return false
    }else{
      setErrorAccountNo(true)
    }

    if(bank==null ){
    
      setErrorBankTxt('Required')
      setErrorBank(false)

      return false
    }else{
      setErrorBank(true)
    }
  
    if(type==null ){
    
      setErrorTypeTxt('Required')
      setErrorType(false)

      return false
    }else{
      setErrorType(true)
    }

    if(branch==null ){
    
      setErrorBranchTxt('Required')
      setErrorBranch(true)

      return false
    }else{
      setErrorBranch(false)
    }
    
     if(firstName==null || firstName.length<2){
      setErrorFirstNameTxt('Required')
      window.scrollTo(0, inputFirstName.current.offsetTop)
      setErrorFirstName(true)
      return false
    }else{
      setErrorFirstName(false)
    }
    if(lastName==null || lastName.length<2){
      
      setErrorLastNameTxt('Required')
      window.scrollTo(0, inputLastName.current.offsetTop)
      setErrorLastName(true)
      return false
    }else{
      setErrorLastNameTxt(false)
    }
    console.log('GEnder', gender)
    if(gender==null){
     
      setErrorGender('Required')
      window.scrollTo(0, inputGender.current.offsetTop)
      return false
    }else{
      setErrorGender(true)
    }
    if(cellNumber1== null || cellNumber2==null || cellNumber3==null || (cellNumber1+cellNumber2+cellNumber3).length<9){
      
      setErrorCell('A Valid cell number is required')
      return false
    }else{
      setErrorCell(true)
    }

 
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
     
      setErrorEmail(false)
    }else{
      setErrorEmailTxt('A valid email address is required')
      setErrorEmail(true)
      return false
    }
 
    
    return true
  },[firstName, lastName, gender, password, confirmPassword, email, cellNumber1,cellNumber2,cellNumber3,accountNo, accountName, type, branch,bank])


  const validate2 =  React.useCallback((e) => {


    if(accountName==null || accountName.length<3){
     
      setErrorAccountNameTxt('Required')
      setErrorAccountName(true)
      return false
    }else{
      setErrorAccountName(false)
    }

   

    
    if(accountNo==null || accountNo.length<6){
      console.log('Account', accountNo)
      setErrorAccountNoTxt('Required')
      setErrorAccountNo(false)

      return false
    }else{
      setErrorAccountNo(true)
    }

    if(bank==null ){
    
      setErrorBankTxt('Required')
      setErrorBank(false)

      return false
    }else{
      setErrorBank(true)
    }
  
    if(type==null ){
    
      setErrorTypeTxt('Required')
      setErrorType(false)

      return false
    }else{
      setErrorType(true)
    }

    if(branch==null ){
    
      setErrorBranchTxt('Required')
      setErrorBranch(true)

      return false
    }else{
      setErrorBranch(false)
    }
    
     if(firstName==null || firstName.length<2){
      setErrorFirstNameTxt('Required')
      window.scrollTo(0, inputFirstName.current.offsetTop)
      setErrorFirstName(true)
      return false
    }else{
      setErrorFirstName(false)
    }
    if(lastName==null || lastName.length<2){
      
      setErrorLastNameTxt('Required')
      window.scrollTo(0, inputLastName.current.offsetTop)
      setErrorLastName(true)
      return false
    }else{
      setErrorLastNameTxt(false)
    }
    console.log('GEnder', gender)
    if(gender==null){
     
      setErrorGender('Required')
      window.scrollTo(0, inputGender.current.offsetTop)
      return false
    }else{
      setErrorGender(true)
    }
    if(cellNumber1== null || cellNumber2==null || cellNumber3==null || (cellNumber1+cellNumber2+cellNumber3).length<9){
      
      setErrorCell('A Valid cell number is required')
      return false
    }else{
      setErrorCell(true)
    }

 
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
     
      setErrorEmail(false)
    }else{
      setErrorEmailTxt('A valid email address is required')
      setErrorEmail(true)
      return false
    }
 
    
    return true
  },[firstName, lastName, gender, password, confirmPassword, email, cellNumber1,cellNumber2,cellNumber3,accountNo, accountName, type, branch,bank, codeTxt])

  const setBankAccount = async() => {
      setFirstName(null)
       const user = await API.graphql({
         query: updateAccount,
         variables: {
           input: {
             id:props.otpReducer.adminUser.account.items[0].id,
             userId: props.otpReducer.adminUser.id,
             accountName:accountName,
             accountNo: accountNo,
             bank:bank,
             accountType:type,
             code:branch
             }
           },
 
       })

       
 };
 
 const updateInfo = async() => {
   
    if(validate()){
      props.showLoadingModal(true)
      setBankAccount();
       var dob = day+'-'+month+'-'+year
       const user = await API.graphql({
         query: updateUser,
         variables: {
           input: {
             id:props.otpReducer.adminUser.id ? props.otpReducer.adminUser.id : props.otpReducer.adminUser.username,
             idNumber,
             dob,
             cell: '+27'+cellNumber1+cellNumber2+cellNumber3,
             gender: gender,
             firstName: firstName,
             lastName: lastName,
             email: email
           },
         },
       })
       setFirstName(null)
       getBeneficiaries(props.otpReducer.adminUser.id)
       props.showLoadingModal(false)
      //  props.showDependantModal('updateSuccessful')
     }
 };
  
 
  const sendRegistration = async(id) => {
    if(validate()){
      var name = firstName + ' ' + lastName;
      var cell = '+27'+cellNumber1+cellNumber2+cellNumber3
      var type = 'user'
      const user = await API.graphql({
        query: createUser,
        variables: {
          input: {
            id,
            firstName,
            lastName,
            email,
            cell,
            gender,
            type
          },
        },
      })
      console.log('USER@',user.data)
      props.setUser(user.data.createUser)
      props.showLoadingModal(false)
      changePage('/success')
    }
    
  }


  return <PlasmicUserInfoArea root={{ ref }} {...props} 
  code = {{
    txtValue : {
      onChange: (e) => onChangeCode(e.target.value),
      value: codeTxt,
      placeHolder: 'OTP Pin'
    },
    txtError: errorCodeTxt,
    showError:errorCode
  }}
  
  planName = {props.view.selectedPlan.name}
  planCost = {'Cost: R '+props.view.selectedPlan.cost+' per month'}
  planPayout = {'Annual Payout: R '+props.view.selectedPlan.annualPayout+' per year'}
  planCover = {'Family Cover: R '+props.view.selectedPlan.familyCover+' '}
  planExtended = {'Extended Cover: R '+props.view.selectedPlan.extendedCover+''}
  dob={{
    txtError: errorDobTxt,
    showError:errorDob,
    year:{
      onClick:(e)=>{
        console.log('year')
          //handleYearOpen()
      },
      value:year
      
    },
    month:{
      onClick:(e)=>{
        console.log('year')
         // handleMonthOpen()
      },
  
      value:month
    },
    day:{
      onClick:(e)=>{
        console.log('year')
          //handleDayOpen()
      },
      value:day
      
    },
  
    
    
  }}
    inputId = {{
      txtValue: {
        onChange: (e) => onChangeSAId(e.target.value),
        placeHolder:'SA Id Number',
        value: idNumber
      },
      txtError: errorSAIdTxt,
      showError:errorSAId
    }}
  
    inputCell = {{
      txtCell1: {
        onChange: (e) => onChangeCell1(e.target.value),
        value: cellNumber1,
        type:'number',
        inputMode:'numeric',
        pattern:"[0-9]*",
        maxLength:2,
        ref: inputElement1
      },
      txtCell2:{
        onChange: (e) => onChangeCell2(e.target.value),
        value: cellNumber2,
        type:'number',
        inputMode:'numeric',
        pattern:"[0-9]*",
        maxLength:3,
        ref: inputElement2
      },
      txtCell3:{
        onChange: (e) => onChangeCell3(e.target.value),
        value: cellNumber3,
        type:'number',
        inputMode:'numeric',
        pattern:"[0-9]*",
        maxlLength:4,
        ref: inputElement3
      },
      txtError: errorCell,
      showError:errorCell
    }}
    
    inputFirstName = {{
      txtValue : {
        onChange: (e) => onChangeFirstName(e.target.value),
        value: firstName,
        placeHolder: 'First Name'
      },
      txtError: errorFirstNameTxt,
      showError:errorFirstName,
      ref: inputFirstName
    }}
  
    inputLastName = {{
      txtValue : {
        onChange: (e) => onChangeLastName(e.target.value),
        value: lastName,
        placeHolder: 'Last Name'
      },
      showError:true,
      txtError: errorLastNameTxt,
      showError:errorLastName,
      ref: inputLastName
    }}
  
    inputEmail = {{
      txtValue : {
        onChange: (e) => onChangeEmail(e.target.value),
        value: email,
        placeHolder: 'Email address'
      },
      txtError: errorEmailTxt,
      showError:errorEmail,
      ref: inputEmail
    }}
  
  
    inputGender = {{
      selectTxt:'Gender',
      txtError: errorGender,
      showError:errorGender,
      ref: inputGender,
     selectHolder:{
       children:    <div  style={{zIndex:9, width:"100%"}}>
       <Select
        value={{ "label": gender, "value":gender}}
         options={genderList}
         isSearchable
         placeholder="Select..."
         onChange = {onChangeGender}
     />
   </div>
    }
   }}
  
  
    accountName = {{
      txtValue : {
        onChange: (e) => onChangeAccountName(e.target.value),
        value: accountName,
        placeHolder: 'Account Name'
      },
      txtError: errorAccountNameTxt,
      showError:errorAccountName,
      ref: inputAccountName
    }}
  
    branchCode = {{
      txtValue : {
        onChange: (e) => onChangeBranch(e.target.value),
        value: branch,
        placeHolder: 'Branch Code'
      },
      txtError: errorBranchTxt,
      showError:errorBranch,
      ref: inputBranch
    }}
    accountNo= {{
      txtValue : {
        onChange: (e) => onChangeAccountNo(e.target.value),
        value: accountNo,
      },
      txtError: errorAccountNoTxt,
      showError:errorAccountNo,
      ref: inputAccountNo
    }}
  
    selectBank = {{
      txtError: errorBankTxt,
      showError:errorBank,
      ref: inputBank,
      selectTxt:'Bank',
     selectHolder: {
       placeholder:'Select your bank...',
       children:  <div  style={{zIndex:10, width:"100%"}}>
       <Select
         options={banks}
         isSearchable
         placeholder="Select..."
         onChange = {onChangeBank}
         value={{ "label": bank, "value":bank}}
     />
   </div>
    }}
    }
  
  
    accountType = {{
      txtError: errorTypeTxt,
      showError:errorType,
      ref: inputType,
      selectTxt:'Account Type',
      selectHolder: {
      
        children:  <div  style={{zIndex:9, width:"100%"}}>
        <Select
          options={accountTypes}
          isSearchable
          placeholder="Select..."
          onChange = {onChangeType}
          value={{ "label": type, "value":type}}
      />
    </div>
     }}
    }
   
    
    senCodeBtn = {{
      onClick:(e)=>{
        sendCode()
      }
    }}
  
    
      submitBtn = {{
        onClick: (e) => {
          if(validate2()){
            updateInfo()
          }
        }
      }}
     
  />;
}

const UserInfoArea = React.forwardRef(UserInfoArea_);


const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer
});


const mapDispatchToProps = dispatch => ({
  setUsername: (string) => dispatch(setUserName(string)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  showDependantModal: (string) => dispatch(showDependantModal(string)),
  setUser: (obj) => dispatch(setAdminUser(obj)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(UserInfoArea);