
import * as React from "react";
import { PlasmicAdminBeneficiaries } from "./plasmic/admin_imbewu/PlasmicAdminBeneficiaries";

import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setBeneficary, setDependant, setDependants, showAdminBeneficiaryModal, showAdminBeneficiaryModalEdit, showAdminDependantModal, showAdminDependantModalEdit, showLoadingModal } from "../redux/reducers/view";
import moment from "moment";
import ListUsers from "./ListUsers";
import ListItemDependentBeneficicaryA from "./ListItemDependentBeneficicaryA";
import { API } from 'aws-amplify'
import { deleteBeneficiary, deleteDependant } from '../graphql/mutations'
import * as queries from '../graphql/queries';
import { onBeneficiaryDelete, onDependantDelete } from "../graphql/subscriptions";
function AdminBeneficiaries_(props, ref) {
 
  let subscription
  React.useEffect(() => {
    if(subscription==null){
      try {
        subscription = API.graphql({
          query: onBeneficiaryDelete,
          variables: {
            userId: props.otp.adminUser.id
          }
        })
        .subscribe({
          next: data => {
            console.log('LISTEN beneficiary delete',props.otp)
            getBeneficaries(props.otp.adminUser.id)
          }
        })
      } catch (error) {
        
      }
      
    }
    
   
  }, [subscription]);
  
  const getBeneficaries = React.useCallback(async(id) => {
    try {
      
      const beneficiariesReceived = await API.graphql({ query: queries.listBeneficiaries, variables: { filter: {
        userId: {
            eq: props.otp.adminUser.id
        }
      }}});
      console.log('admin get benef', beneficiariesReceived.data.listBeneficiaries.items)
      props.setBeneficary(beneficiariesReceived.data.listBeneficiaries.items)
      subscription.unsubscribe()
    } catch (error) {
      console.log('ready to auth error', error)
    }
  },[props.otp.adminUser.id, subscription]);
 
  const removeBeneficary = async(item) => {
    
    props.showLoadingModal(true)
    const user = await API.graphql({
      query: deleteBeneficiary,
      variables: {
        input: {
          id: item.id
        },
      },
    })
    getBeneficaries(props.otp.adminUser.id)
    props.showLoadingModal(false)
   //retrieveUser()
  }

  //console.log('Admin Benefit', moment(props.view.payments[0].createdAt).add(1, 'years').format('DD-MM-YYYY'))
  return <PlasmicAdminBeneficiaries root={{ ref }} {...props} 
  nextPayment = {props.view.payments.length>0 && moment(props.view.payments[props.view.payments.length-1].createdAt).add(1, 'months').format('DD-MM-YYYY')}
  latestPayment = {props.view.payments.length>0 && moment(props.view.payments[props.view.payments.length-1].createdAt).add(0, 'months').format('DD-MM-YYYY')}
  latestPayout = {props.view.payments.length>0 && moment(props.view.payments[0].createdAt).add(1, 'years').format('DD-MM-YYYY')}
      addBeneficiary = {{
        disabled: props.view.beneficiary.length>0 ? true :false,
        onClick:(e)=>{
          if(props.view.beneficiary.length==0){
            props.showAdminBeneficiaryModal(true)
          }
            
        }
      }}
      scroller = {{
        children: props.view.beneficiary.map((item, index)=>(
          <React.Fragment key={index}>
            <ListItemDependentBeneficicaryA
            nameTxt = {item.firstName +' '+item.lastName} 
            btnEdit = {{
              onClick:(e)=>{
                console.log('admin show edit')
                props.showAdminBeneficiaryModalEdit(true)
              }
             
            }}
            btnDelete = {{
              onClick:(e)=>{
                removeBeneficary(item)
              }
            }}
            />
          </React.Fragment>
       ))
      }}
  
  />;
}

const AdminBeneficiaries = React.forwardRef(AdminBeneficiaries_);

const mapStateToProps = state => ({
  otp: state.otpReducer,
  view: state.viewReducer
});


const mapDispatchToProps = dispatch => ({
  
  showAdminBeneficiaryModal: (string) => dispatch(showAdminBeneficiaryModal(string)),
  setBeneficary: (obj) => dispatch(setBeneficary(obj)),
  showAdminBeneficiaryModalEdit:(bool)=>dispatch(showAdminBeneficiaryModalEdit(bool)),
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  setBeneficary: (obj) => dispatch(setBeneficary(obj)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(AdminBeneficiaries);