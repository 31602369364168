// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicCardPricingSliderCarousel } from "./plasmic/imbewu_mobile_first/PlasmicCardPricingSliderCarousel";

import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CardPlanItem from "./CardPlanItem";
import DotSliderItem from "./DotSliderItem";
import CardPlanA from "./CardPlanA";
import CardPlanB from "./CardPlanB";
import CardPlanC from "./CardPlanC";
import Flickity from 'react-flickity-component'

function CardPricingSliderCarousel_(props, ref) {
  const plans = [<CardPlanA style={{marginRight:30}}/>, <CardPlanB style={{marginRight:30}}/>, <CardPlanC/>]

 

var flickityOptions = {
  initialIndex: 0,
  pageDots: true,
  prevNextButtons:false
}
  return <PlasmicCardPricingSliderCarousel root={{ ref }} {...props} 
  planHolder = {{
    style:{width:'100%'},
    children: <Flickity
    className={'carousel'} // default ''
    elementType={'div'} // default 'div'
    options={flickityOptions} // takes flickity options {}
    disableImagesLoaded={false} // default false
    reloadOnUpdate // default false
    static // default false
    
  >
    {plans.map((item, index)=>(
   
      <React.Fragment key={index}>
        { item }
    </React.Fragment>
  ))}
  </Flickity>
  }}
  />;
}

const CardPricingSliderCarousel = React.forwardRef(CardPricingSliderCarousel_);

export default CardPricingSliderCarousel;
