import { all } from 'redux-saga/effects';

import { watcherPWASaga } from './PWAupdate'

 
export default function* rootSaga() {
  yield all([
    
    watcherPWASaga(),
    
  ]);
}
