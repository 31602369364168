// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { showLoadingModal } from "../redux/reducers/view";
import { PlasmicFooterWidget } from "./plasmic/imbewu_mobile_first/PlasmicFooterWidget";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
function FooterWidget_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  return <PlasmicFooterWidget root={{ ref }} {...props} 
  versionTxt = {'Version: 1.0.39'}
      contact = {{
        onClick:(e)=>{
            changePage('/contact')
        }
      }}
  />;
}

const FooterWidget = React.forwardRef(FooterWidget_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  showLoadingModal:(string) => dispatch(showLoadingModal(string))
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(FooterWidget);
