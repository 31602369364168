// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useCallback} from "react"; 
import { toggleSideMenu } from "../redux/reducers/view";
import MenuItem from "./MenuItem";
import { PlasmicSideNav } from "./plasmic/imbewu_mobile_first/PlasmicSideNav";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';

function SideNav_(props, ref) {
  const menuItemList = [
  {title:'Home', loc:'/home'},
  {title:'About', loc:'/about'},
  {title:'FAQ', loc:'/faq'},
  {title:'Plans & Benefits', loc:'/plansbenefits'},
  {title:'Contact', loc:'/contact'},
  {title:'Terms & Conditions', loc:'/terms'},
  {title:'Submit a claim', loc:'/startclaim'},
  {title:'Cancel Plan', loc:'/cancelClaim'},
  {title:'Sign Out', loc:'/logout'},
  {title:'Support'}
];
const menuItemListWelcome = [
  {title:'Home', loc:'/welcome'},
  {title:'About', loc:'/about'},
  {title:'FAQ', loc:'/faq'},
  {title:'Plans & Benefits', loc:'/plansbenefits'},
  {title:'Contact', loc:'/contact'},
  {title:'Terms & Conditions', loc:'/terms'},
  {title:'Sign In', loc:'/login'},
  {title:'Sign Up', loc:'/register'},
  {title:'Support'}
];
  const changePage =  useCallback((e) => {
   props.toggleSideMenu()
    props.history.push(e);
   
  }, []);

  return <div style={{position:'fixed', top:70, left:0, height:'100%', zIndex:1000}}>
        {props.viewReducer.sideMenuOpen && !props.otpReducer.user && <PlasmicSideNav root={{ ref }} {...props}
           versionTxt = {'Version: 1.0.39'}
            navHolder=  {{children : menuItemListWelcome.map((item) => <MenuItem
                txtMenu = {item.title}
                btnMenuItem = {{
                  onClick: (e) => {
                    if(item.loc){
                      if(item.loc=="/terms"){

                        window.open('https://dev.imbewustokvest.com/TermsandConditions.pdf', '_blank');
                      }else{
                        changePage(item.loc)

                      }
                     
                    }else{
                      console.log('window.ze',window)
                      window.zE('messenger', 'open');
                      window.zE('messenger:set', 'zIndex', 10000);
                    }
                    
                  }
                }}
                />),
          }}
        />}
        {props.viewReducer.sideMenuOpen && props.otpReducer.user && <PlasmicSideNav root={{ ref }} {...props}
           versionTxt = {'Version: 1.0.39'}
           navHolder=  {{children : menuItemList.map((item) => <MenuItem
               txtMenu = {item.title}
               btnMenuItem = {{
                onClick: (e) => {
                  if(item.loc){
                    if(item.loc=="/terms"){

                      window.open('https://dev.imbewustokvest.com/TermsandConditions.pdf', '_blank');
                    }else{
                      changePage(item.loc)

                    }
                  }else{
                    console.log('window.ze',window)
                    window.zE('messenger', 'open');
                    window.zE('messenger:set', 'zIndex', 10000);


                  }
                  
                }
               }}
               />),
         }}
       />}
    </div>
}

const SideNav = React.forwardRef(SideNav_);

const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  toggleSideMenu: () => dispatch(toggleSideMenu()),
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(SideNav);

