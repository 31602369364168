// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicNavPageTitle } from "./plasmic/imbewu_mobile_first/PlasmicNavPageTitle";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { toggleSideMenu } from "../redux/reducers/view";

function NavPageTitle_(props, ref) {
  const [allowed, setAllowed] = React.useState(true);
  var title = ''
  console.log('props', props)
  if (props.location.pathname){
    // if(props.location.pathname=='/home' && allowed!=true){
    //   setAllowed(true)
    // }else if(allowed !=false){
    //   setAllowed(false)
    // }
    switch (props.location.pathname) {
      case '/welcome':
          title = 'Welcome to Imbewu'
        break;
      case '/about':
        title = 'About Imbewu Stokvest™'
      break;
      case '/faq':
        title = 'Frequently Asked Questions'
      break;
      case '/contact':
        title = 'Contact Us'
      break;
      case '/addbenificiary':
        title = 'Add Benificiary'
      break;
      case '/editbeneficiary':
        title = 'Edit Benificiary'
      break;
      case '/editdependant':
        title = 'Edit Dependant'
      break;
      case '/howto':
        title = 'How To'
      break;
      case '/home':
        title = 'My Dashboard'
      break;
      case '/forgot':
        title = 'Forgotten Password'
      break;
      case '/plansbenefits':
        title = 'Plans & Benefits'
      break;
    
      default:
        break;
    }
  }

  const goBack = (e) => {
    props.history.push("/home")
  }


  return <PlasmicNavPageTitle root={{ ref }} {...props} 
          title = {title}
          backNotAllowed={allowed}
          btnBack = {{
            onClick: (e) => {
              goBack()
            }
          }}

  />;
}

const NavPageTitle = React.forwardRef(NavPageTitle_);

const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  toggleSideMenu: () => dispatch(toggleSideMenu()),
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(NavPageTitle);

