// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicViewClaim } from "./plasmic/imbewu_mobile_first/PlasmicViewClaim";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setClaims, showAdminClaimsModal, showAdminDecline, showDocModal, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import { API,graphqlOperation } from 'aws-amplify'
import { updateClaim} from "../graphql/mutations";
import { onClaimUpdate } from "../graphql/subscriptions";
import * as queries from '../graphql/queries';
import axios from 'axios'
import qs from 'qs'
import paymentTypes from '../assets/paymentTypes.json';
import Select from 'react-select'
import ModalDocView from "./ModalDocView";
function ViewClaim_(props, ref) {
  const [subscription, setSubscription] = React.useState(null);
  const inputElement1 = React.useRef(null);
  const [reason, setReason] = React.useState(null);
  const [codeTxt, setCode] = React.useState(null);
  const [errorCodeTxt, setErrorCodeTxt] = React.useState(true);
  const [errorCode, setErrorCode] = React.useState(false);

  const [paymentType, setPaymentType] = React.useState(null);
  const [errorTypeTxt, setErrorTypeTxt] = React.useState(true);
  const [errorType, setErrorType] = React.useState(true);
  const [fileUrl, setFileUrl] = React.useState(null);
  React.useEffect(() => {
  
    if(subscription==null){
      console.log('Listen benef setup')
      try {
        setSubscription(API.graphql({
          query: onClaimUpdate,
          variables: {
            userId: props.otpReducer.adminUser.id
          }
        })
        .subscribe({
          next: data => {
            console.log('LISTEN benef create',props.otpReducer)
            getBeneficiaries(props.otpReducer.adminUser.id)
          }
        }))
      } catch (error) {
        
      }
      
    }
    
   
  }, [subscription]);

  const onChangeType =  React.useCallback((e) => {
    setPaymentType(e);
  },[paymentType])


  const sendCode = async()=>{
    console.log('Claim help', props.otpReducer)
    props.showLoadingModal(true)
    var data = qs.stringify({
      'cell':props.otpReducer.user.attributes.phone_number,
    });
    var config = {
      method: 'post',
      url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/checkverifyhurt',
      headers: { 
        "x-api-key": process.env.API_IMBEWU2_GRAPHQLAPIKEYOUTPUT,
        'Content-Type': 'application/x-www-form-urlencoded', 
       },
       data:data
    };
   
      const test = await axios(config)
      .then(function (response) {
        //console.log('Pay',JSON.parse(response.data));
        //setPay(JSON.parse(response.data))
        props.showErrorModal("OTP Pin was sent successfuly")
      })
      .catch(function (error) {
        console.log('claim error', error)
        props.showErrorModal(error.message)
      });
      props.showLoadingModal(false)

  }

  const checkCode = async()=>{
    console.log('Claim help', props.otpReducer.adminUser.cell, codeTxt)
    props.showLoadingModal(true)
  //  sendPayout()
    var data = qs.stringify({
      'cell':props.otpReducer.user.attributes.phone_number,
      'code':codeTxt
    });
    var config = {
      method: 'post',
      url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/checkcodehurt',
      headers: { 
        "x-api-key": process.env.API_IMBEWU2_GRAPHQLAPIKEYOUTPUT,
        'Content-Type': 'application/x-www-form-urlencoded', 
       },
       data:data
    };
   
      const test = await axios(config)
      .then(function (response) {
        console.log('Cehck Code',response.data);
        if(response.data.status=='approved'){
          sendPayout()
        }else{
          props.showErrorModal('Invalid Pin')
        }
  
      })
      .catch(function (error) {
        console.log('Pay error',error);
      });
      props.showLoadingModal(false)

  }



  const onChangeCode = React.useCallback((e) => {
    setCode(e);
  },[codeTxt])


  const getBeneficiaries = React.useCallback(async(id) => {
    try {
      
      const dependantsReceived = await API.graphql({ query: queries.listClaims, variables: { filter: {
        userId: {
            eq: props.otpReducer.adminUser.id
        }
      }}});
      console.log('admin get benef', dependantsReceived.data.listClaims.items)
      props.setClaims(dependantsReceived.data.listClaims.items);
     
      subscription.unsubscribe()
    } catch (error) {
      console.log('ready to auth error', error)
    }
  },[props.otpReducer.adminUser.id]);

  const updateClaimNow = async() => {
    console.log('Update claim')
   
    props.showLoadingModal(true)
    
    
       const claim = await API.graphql({
         query: updateClaim,
         variables: {
           input: {
             id:props.viewReducer.claim.id,
             status:"success",

           },
         },
       })
       getBeneficiaries(props.otpReducer.adminUser.id)
       props.showLoadingModal(false)
       props.showAdminClaimsModal(false)
      
     
 };

 const sendPayout = async() =>{
  if(validate()){
    props.showLoadingModal(true)
    var amount
    console.log('Family', paymentType)
    if(paymentType.label=='Family Claim'){
      amount = parseInt(props.otpReducer.adminUser.plan.items[0].familyCover)
    }else{
      amount = parseInt(props.otpReducer.adminUser.plan.items[0].extendedCover)
    }
   console.log('XXX2', props.otpReducer.adminUser.cell)
    var data = {
      "firstName": props.otpReducer.adminUser.firstName,
      "lastName": props.otpReducer.adminUser.lastName,
      "accountNo": props.otpReducer.adminUser.account.items[0].accountNo,
      "code": props.otpReducer.adminUser.account.items[0].code,
      "userId": props.otpReducer.adminUser.id,
      "amount": amount,
      "cell":props.otpReducer.adminUser.cell,
      "type":paymentType.label
    }
  
    var config = {
      method: 'post',
      url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/payoutclaimhurt2',
      headers: { 
        "x-api-key": "da2-mbeb4idwcrb5pgcd2cdoyp5z7i",
        'Content-Type': 'application/json', 
       },
       data:data
    };
   
      const test = await axios(config)
      .then(function (response) {
        console.log('claim', response)
        updateClaimNow()
  
      })
      .catch(function (error) {
        console.log('claim error', error)
        props.showErrorModal(error);
      });
      console.log('Wet', test)
  }
}
 const validate =  React.useCallback((e) => {
    if(paymentType==null ){
    setErrorTypeTxt('Required')
    setErrorType(false)
    return false
  }else{
    setErrorType(true)
  }
  

  return true;
 },[paymentType])

 const validate2 =  React.useCallback((e) => {
  
return true;
},[codeTxt])
  console.log('CLAIM VIEW',props.viewReducer.claim)
  return <div>
  {props.viewReducer.showAdminClaimsModal && <div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}><PlasmicViewClaim root={{ ref }} {...props} 
        dependantName = {props.viewReducer.claim.dependantName}
        code = {{
          txtValue : {
            onChange: (e) => onChangeCode(e.target.value),
            value: codeTxt,
            placeHolder: 'OTP Pin'
          },
          txtError: errorCodeTxt,
          showError:errorCode
        }}
        btnBack = {{
          onClick:(e)=>{
            props.showAdminClaimsModal(false)
          }
        }}
        sendCodeBtn = {{
          onClick:(e)=>{
            if(validate()){
              sendCode()
            }
          }
        }}
      
      

        approveBtn = {{
          onClick:(e)=>{
            if(validate2()){
              checkCode()
            }
          }
        }}

        declineBtn = {{
          onClick:(e)=>{
            props.showAdminDecline(true)
          }
        }}

        natureOfDeath = {props.viewReducer.claim.natureOfDeath}
        street = {props.viewReducer.claim.postalStreet}
        city = {props.viewReducer.claim.postalCity}
        province = {props.viewReducer.claim.postalProvince}
        resStreet = {props.viewReducer.claim.resedentialStreet}
        resCity = {props.viewReducer.claim.resedentialCity}
        resProvince = {props.viewReducer.claim.resedentialProvince}

        paymentType = {{
          selectTxt:'Payout Type',
          txtError: errorTypeTxt,
          showError:errorType,
          selectHolder:{
            children:    <div  style={{zIndex:9, width:"100%"}}>
            <Select
              value={paymentType}
              options={paymentTypes}
              placeholder="Select..."
              onChange = {onChangeType}
          />
        </div>}
        }}
  
        btnDeceasedId = {{
          onClick:(e)=>{
            setFileUrl('https://claimsbucket90419-hurt.s3.amazonaws.com/public/'+String(props.viewReducer.claim.copyOfIdDependant).replace(/\s+/g, '+'))
            props.showDocModal(true)
    
          }
        }}
        btnDeathNotice = {{
          onClick:(e)=>{
            setFileUrl('https://claimsbucket90419-hurt.s3.amazonaws.com/public/'+String(props.viewReducer.claim.copyOfDeathNotice).replace(/\s+/g, '+'))
          props.showDocModal(true)
          }
        }}
        btnBank = {{
          onClick:(e)=>{
            setFileUrl('https://claimsbucket90419-hurt.s3.amazonaws.com/public/'+String(props.viewReducer.claim.copyBankStatements).replace(/\s+/g, '+'))
          props.showDocModal(true)
          }
        }}
        btnClaim = {{
          onClick:(e)=>{
            setFileUrl('https://claimsbucket90419-hurt.s3.amazonaws.com/public/'+String(props.viewReducer.claim.copyOfClaim).replace(/\s+/g, '+'))
          props.showDocModal(true)
          }
        }}
        btnMemberId = {{
          onClick:(e)=>{
            setFileUrl('https://claimsbucket90419-hurt.s3.amazonaws.com/public/'+String(props.viewReducer.claim.copyOfIdMain).replace(/\s+/g, '+'))
          props.showDocModal(true)
          }
        }}
        btnDeathCert = {{
          onClick:(e)=>{
            setFileUrl('https://claimsbucket90419-hurt.s3.amazonaws.com/public/'+String(props.viewReducer.claim.copyOfIdDependant).replace(/\s+/g, '+'))
          props.showDocModal(true)
          }
        }}
  /></div>}
  {props.viewReducer.showDocModal && <ModalDocView url={fileUrl}/>}
  </div>;
}

const ViewClaim = React.forwardRef(ViewClaim_);

const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otpReducer:state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  showDocModal: (bool) => dispatch(showDocModal(bool)),
  showAdminClaimsModal: (bool) => dispatch(showAdminClaimsModal(bool)),
  showAdminDecline: (bool) => dispatch(showAdminDecline(bool)),
  showLoadingModal: (bool) => dispatch(showLoadingModal(bool)),
  showErrorModal: (bool) => dispatch(showErrorModal(bool)),
  setClaims: (bool) => dispatch(setClaims(bool)),
});


export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ViewClaim);
