import React, { useState, useEffect, useRef } from 'react';

const PaymentPage = ({ paymentData, ...props }) => {
  const [methodUrl, setMethodUrl] = useState(null);
  const [methodParams, setMethodParams] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [redirectParams, setRedirectParams] = useState([]);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    const handleIframeMessage = (event) => {

    
      
      // check if the message is valid
      if (typeof event.data !== 'object' || event.data === null) {
        return;
      }

      // process the message data
      const messageData = event.data;

      // close the iframe
      if (messageData.paymentId) {
        console.log('PAYMENT PAGE1',event)
        fetch(`https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/checkstatushurt?id=${messageData.paymentId}`)
    .then(response => response.json())
    .then(data => {
      console.log('PAYMENT', data)
      const messageData2 = {
        action: 'paymentStatus',
        paymentId: messageData.paymentId,
        status: data // or any other payment status you want to send
      };
      console.log('PAYMENT PAGE3', messageData2)
      // send a message to the parent window
      props.paymentDataReceived(messageData2)
    })
        setIframeLoaded(false);
      }
    };

    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const handlePaymentCompleted = () => {
    // send a message to the iframe to close it
    const messageData = { action: 'close' };
    paymentIframeRef.current.contentWindow.postMessage(messageData, '*');
  };

  const paymentIframeRef = useRef(null);


  useEffect(() => {
    // Extract relevant data from paymentData
    // const {
    //   redirect: { url: redirUrl, parameters: redirParams },
    //   preconditions: { url: methUrl, parameters: methParams },
    // } = paymentData;

    // Set state variables with extracted data
    setMethodUrl(paymentData.redirect?.preconditions[0]?.url);
    setMethodParams(paymentData.redirect?.preconditions[0]?.parameters[0]);
    setRedirectUrl(paymentData.redirect.url);
    setRedirectParams(paymentData.redirect.parameters);

    // If methodUrl is present, open a hidden iframe and post methodParams to it
    if (methodUrl && methodParams.length) {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';

      const form = document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', methodUrl);

      methodParams.forEach(({ name, value }) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
        form.appendChild(input);
      });

      iframe.addEventListener('load', () => {
        document.body.removeChild(iframe);
      });

      document.body.appendChild(iframe);
      iframe.contentDocument.body.appendChild(form);
      form.submit();
    }
  }, [paymentData]);

  useEffect(() => {
    if (redirectUrl) {
      // const iframe = document.createElement('iframe');
      // iframe.style.position = 'absolute';
      // iframe.style.top = '0';
      // iframe.style.left = '0';
      // iframe.style.width = '100%';
      // iframe.style.height = '100%';
      // iframe.style.border = 'none';
      // iframe.style.margin = '0';
  
      // const form = document.createElement('form');
      // form.setAttribute('method', 'POST');
      // form.setAttribute('action', redirectUrl);
  
      // redirectParams.forEach(({ name, value }) => {
      //   const input = document.createElement('input');
      //   input.setAttribute('type', 'hidden');
      //   input.setAttribute('name', name);
      //   input.setAttribute('value', value);
      //   form.appendChild(input);
      // });
  
      // iframe.addEventListener('load', () => {
      //   console.log('Pay loaded', iframe.contentDocument.body)
      //   if (iframe.contentDocument && iframe.contentDocument.body) {
      //     iframe.contentDocument.body.appendChild(form);
      //   }
      // });
      // 
      // document.body.appendChild(iframe);
      setIframeLoaded(true)
    }
  }, [redirectUrl, redirectParams]);

  
  
  
  
  
  

  return (
    <>
      {methodUrl && methodParams.length && (
        <iframe
          title="3D Secure Method Form"
          src={methodUrl}
          style={{ display: 'none' }}
        />
      )}
      {console.log('IFRAME LOADED', iframeLoaded)}
      {redirectUrl && iframeLoaded && (
        <iframe
          title="3D Secure Redirect Form"
          src={redirectUrl}
          id="paymentIframe"
          style={{ display: 'block', position:'absolute', top:-70, left:0, width:"100%", height:'100%', zIndex:100000 }}
        />
      )}
    </>
  );
};

export default PaymentPage;
