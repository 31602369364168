import * as React from "react";
import { PlasmicSelectA } from "./plasmic/copy_of_imbewu_admin/PlasmicSelectA";
import Option from "./SelectA__OptionA";
import OptionGroup from "./SelectA__OptionGroupA";

function SelectA_(props, ref) {
  const { plasmicProps, state } = PlasmicSelectA.useBehavior(props, ref);
  return <PlasmicSelectA {...plasmicProps} />;
}

const SelectA = React.forwardRef(SelectA_);

export default Object.assign(SelectA, {
  Option,
  OptionGroup,
  __plumeType: "select"
});
