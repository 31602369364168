// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicAdminUserPageUserProfile } from "./plasmic/copy_of_imbewu_admin/PlasmicAdminUserPageUserProfile";

import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showLoadingModal } from "../redux/reducers/view";

function AdminUserPageUserProfile_(props, ref) {
  const [userName, setUserName] = React.useState('');
  React.useEffect(() => {
    if(props.otpReducer.adminUser){
      setUserName(props.otpReducer.adminUser.firstName+' '+props.otpReducer.adminUser.lastName)
    }
  
   }, [props.otpReducer]);
   console.log('user tabs', props.view.userView)
  return <PlasmicAdminUserPageUserProfile root={{ ref }} {...props} 
        profileArea={{
          nameTxt:userName
        }}
        userInfoArea = {{
          pageName:props.view.userView
        }}
  />;
}

const AdminUserPageUserProfile = React.forwardRef(AdminUserPageUserProfile_);

const mapDispatchToProps = dispatch => ({
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
})


const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer
});

export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(AdminUserPageUserProfile);

