// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicClaims2 } from "./plasmic/imbewu_mobile_first/PlasmicClaims2";
import { PlasmicPayout } from "./plasmic/imbewu_mobile_first/PlasmicPayout";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PlasmicRegister2Secure } from "./plasmic/imbewu_mobile_first/PlasmicRegister2Secure";
import { setUserName } from "../redux/reducers/otp";
import { setStep, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { API } from 'aws-amplify'
import { updateUser } from '../graphql/mutations'
function Claims2_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  return <PlasmicClaims2 root={{ ref }} {...props} 
  submitBtn = {{
    onClick:(e)=>{
          changePage('/claimsubmitted')
    }
  }}/>;
}

const Claims2 = React.forwardRef(Claims2_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setUsername: (string) => dispatch(setUserName(string)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  setStep: (int) => dispatch(setStep(int)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Claims2);


