import * as React from "react";
import { PlasmicLogin } from "./plasmic/imbewu_mobile_first/PlasmicLogin";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import { setAdminUser, setSession, setUser, setUserName } from "../redux/reducers/otp";
import { showErrorModal, showLoadingModal } from "../redux/reducers/view";
import { setBeneficary, setClaims, setDependant, setDependants, setPayments, setPayouts } from "../redux/reducers/view";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
Amplify.configure(awsconfig);

function Login_(props, ref) {
  const inputElement1 = React.useRef(null);
  const inputElement2 = React.useRef(null);
  const inputElement3 = React.useRef(null);
  const [cellNumber1, setCellNumber1] = React.useState(null);
  const [cellNumber2, setCellNumber2] = React.useState(null);
  const [cellNumber3, setCellNumber3] = React.useState(null);

  const [email, setEmail] = React.useState(null);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorEmailTxt, setErrorEmailTxt] = React.useState(true);

  const [errorCell, setErrorCell] = React.useState(true);
  const [password, setPassword] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState(true);
  const [btnStateSubmit, setBtnStateSubmit] = React.useState('active');
  const [btnStateRegister, setBtnStateRegister] = React.useState('active');

  
  const onChangeEmail = React.useCallback((e) => {
    setEmail(e); 
  },[email])

  const onChangeCell1 =  React.useCallback((e) => {
    if(e.length<3){
      setCellNumber1(e); 
    }
    console.log('tab', e.length)
    if(e.length==2){
     inputElement2.current.focus()
    }
   
  },[cellNumber1])

  const onChangeCell2 =  React.useCallback((e) => {
    if(e.length<4){
    setCellNumber2(e); 
    }
    if(e.length==3){
      inputElement3.current.focus()
     }
  },[cellNumber2])

  const onChangeCell3 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber3(e); 
    }
  },[cellNumber3])

  const onChangePassword =  React.useCallback((e) => {
    setPassword(e);
},[])

const onChangeShowPassword=  React.useCallback((e) => {
  console.log('LLL', showPassword)
  setShowPassword(!showPassword); 
},[showPassword])


const validate =  React.useCallback((e) => {
    
  

if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
     
  setErrorEmail(false)
}else{
  setErrorEmailTxt('A valid email address is required')
  setErrorEmail(true)
  return false
}

 
 

//  if (password &&
//    /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password)
//    ) {
//      setErrorPassword(true) 
//  }else{
  
//    setErrorPassword('Incorrect username or password. Please try again'   )         
//    return false
//  }

 return true;

},[ cellNumber1,cellNumber2,cellNumber3, email, password])


  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  const signIn = () => {
    if(validate()){

    props.showLoadingModal(true)
    setBtnStateSubmit('loading')
    setBtnStateRegister('disabled')
    Auth.signIn(email, password)
      .then((result) => {
        if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
          console.log('challengeName', result)
          props.setUser(result);
          changePage('/newpassword')
          props.showLoadingModal(false)
        }else{
          const groups = result.signInUserSession.accessToken.payload["cognito:groups"];


          if(groups.includes('shaunadmin')){
            console.log('ready wrong x, user')
           props.setAdminUser(result)
           changePage('/admindashboard')
          }else{
            props.setUser(result);
        
            retrieveUser(result)
          }
        }
     
      })
      .catch((e) => {
        console.log('ERROR', e)
        props.showLoadingModal(false)
        setBtnStateSubmit('active')
        setBtnStateRegister('active')
        if (e.code === 'UserNotFoundException') {
          props.showErrorModal('Incorrect username or password. Please try again')
        } else if (e.code === 'NotAuthorizedException') {
          props.showErrorModal('Error: Incorrect cell or password')
        } else {
          props.showErrorModal('Error: '+e.code)
        }
      });
    }
  };

  const retrieveUser = async(user) => {
   
    
    try {
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

     
      console.log('Group', groups)
      if(groups.includes('shaunadmin')){
        changePage('/admindashboard')
      }else{
        const userReceived = await API.graphql(graphqlOperation(queries.getUser, { id:user.username }));
        var loggedinUser =userReceived.data.getUser;
        props.setUser(userReceived);
        props.setSession(null);
        console.log('ready to auth 66',loggedinUser)
        props.setDependants(loggedinUser.dependants.items)
        props.setBeneficary(loggedinUser.beneficiaries.items)
        props.setPayouts(loggedinUser.account.items)
        props.setPayments(loggedinUser.payments.items)
        props.setUser(loggedinUser);
        
        if(loggedinUser.idNumber==null || loggedinUser.dob==null){
          changePage('/secure')
        }else if(loggedinUser.plan.items.length==0){
          changePage('/chooseplan')
        }else{
          console.log('ready to auth 6',props.viewReducer)
          changePage('/home')
        }
        props.showLoadingModal(false)
      }
    } catch (error) {
      console.log('ready to auth error', error)
    }
  
}

//  inputCell = {{
//   txtCell1: {
//     onChange: (e) => onChangeCell1(e.target.value),
//     value: cellNumber1,
//     type:'number',
//     inputMode:'numeric',
//     pattern:"[0-9]*",
//     maxLength:2,
//     ref: inputElement1,
//     onKeyUp: (e)=>autoTab(e)
//   },
//   txtCell2:{
//     onChange: (e) => onChangeCell2(e.target.value),
//     value: cellNumber2,
//     type:'number',
//     inputMode:'numeric',
//     pattern:"[0-9]*",
//     maxLength:3,
//     ref: inputElement2,
//     onKeyUp: (e)=>autoTab(e)
//   },
//   txtCell3:{
//     onChange: (e) => onChangeCell3(e.target.value),
//     value: cellNumber3,
//     type:'number',
//     inputMode:'numeric',
//     pattern:"[0-9]*",
//     maxlLength:4,
//     ref: inputElement3,
//     onKeyUp: (e)=>autoTab(e)
//   },
//   txtError: errorCell,
//   showError:errorCell
// }}
  return <PlasmicLogin root={{ ref }} {...props} 

    inputEmail = {{
      txtValue : {
        onChange: (e) => onChangeEmail(e.target.value),
        value: email,
        placeHolder: 'Email address'
      },
      txtError: errorEmailTxt,
      showError:errorEmail,

    }}
  inputPassword = {{
    txtValue : {
      onChange: (e) => onChangePassword(e.target.value),
      value: password,
      placeHolder: 'Password'
    },
    
    showPassword: showPassword,
    btnEye: {onClick:(e)=>onChangeShowPassword()},
    btnEyeClosed: {onClick:(e)=>onChangeShowPassword()},
    showError:errorPassword
  }}
      registerBtn = {{
        btnState: btnStateRegister,
        onClick: (e) => {
          changePage("/register")
        }
      }}
      forgotBtn = {{
        onClick: (e) => {
          changePage("/forgot")
        }
      }}
      submit ={{
        btnState: btnStateSubmit,
        onClick: (e) => {
         signIn()
        },
      }}
  />;
}

const Login = React.forwardRef(Login_);

const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
});

const mapDispatchToProps = dispatch => ({
  setUser: (string) => dispatch(setUser(string)),
  setAdminUser: (string) => dispatch(setAdminUser(string)),
  setUserName: (string) => dispatch(setUserName(string)),
  setSession: (string) => dispatch(setSession(string)),
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  setDependants: (obj) => dispatch(setDependants(obj)),
  setBeneficary: (obj) => dispatch(setBeneficary(obj)),
  setPayments: (obj) => dispatch(setPayments(obj)),
  setPayouts: (obj) => dispatch(setPayouts(obj)),
  setClaims: (obj) => dispatch(setClaims(obj))
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(Login);
