// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { setBeneficary, setClaims, setDependant, setDependants, setPayments, setPayouts, setPlan, setStep, setUserMain, showAdminCreditCard, showErrorModal, showLoadingModal } from "../redux/reducers/view";
import { PlasmicModalCreditCard } from "./plasmic/imbewu_mobile_first/PlasmicModalCreditCard";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Select__Option from "./Select__Option";
import axios from 'axios'
import qs from 'qs'
import { API,graphqlOperation } from 'aws-amplify'
import { createAuthorizations, createPayment, updateAuthorizations } from "../graphql/mutations";
import * as queries from '../graphql/queries';
import { setSession, setUser, setUserName, setVerified } from "../redux/reducers/otp";

function ModalCreditCard_(props, ref) {
  const inputElement1 = React.useRef(null);
  const inputElement2 = React.useRef(null);
  const inputElement3 = React.useRef(null);
  const inputElement4 = React.useRef(null);
  const inputElement5 = React.useRef(null);

  const [nameOnCard, setNameOnCard] = React.useState(null);
  const [errorName, setErrorName] = React.useState(false);
  const [errorNameTxt, setErrorNameTxt] = React.useState(true);

  const [cvv, setCvv] = React.useState(null);
  const [errorCvv, setErrorCvv] = React.useState(false);
  const [errorCvvTxt, setErrorCvvTxt] = React.useState(true);
  const [cvvStatus, setCvvStatus] = React.useState(true);

  const [typeValue, setTypeValue] = React.useState(null);
  const [type, setTypeName] = React.useState(null);
  const [errorType, setErrorType] = React.useState(true)
  const [errorTypeTxt, setErrorTypeTxt] = React.useState(null);

  const [cellNumber1, setCellNumber1] = React.useState(null);
  const [cellNumber2, setCellNumber2] = React.useState(null);
  const [cellNumber3, setCellNumber3] = React.useState(null);
  const [cellNumber4, setCellNumber4] = React.useState(null);
  const [errorCell, setErrorCell] = React.useState(true);

  const [month, setMonth] = React.useState(null);
  const [year, setYear] = React.useState(null);
  const [errorDate, setErrorDate] = React.useState(false);
  const [errorDateTxt, setErrorDateTxt] = React.useState(false);



  const [codeTxt, setCode] = React.useState(null);
  const [errorCodeTxt, setErrorCodeTxt] = React.useState(true);
  const [errorCode, setErrorCode] = React.useState(false);
  
  React.useEffect(() => {
 
    //getId()
    
  }, []);



  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);
  const onChangeCvv=  React.useCallback((e) => {
    console.log('error cvv', e)
    if(e.length<4){
      setCvv(e); 
    }

   
  },[cvv])
  const onChangeMonth =  React.useCallback((e) => {
    if(e.length<3){
      setMonth(e); 
    }
    console.log('tab', inputElement5)
    if(e.length==2){
     inputElement5.current.focus()
    }
   
  },[month])
  const onChangeYear =  React.useCallback((e) => {
    if(e.length<3){
      setYear(e); 
    }
   
  },[year])
  const onChangeCell1 =  React.useCallback((e) => {
    if(e.length<5){
      setCellNumber1(e); 
    }
    console.log('tab', e.length)
    if(e.length==4){
     inputElement2.current.focus()
    }
   
  },[cellNumber1])

  const onChangeCell2 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber2(e); 
    }
    if(e.length==4){
      inputElement3.current.focus()
     }
  },[cellNumber2])

  const onChangeCell3 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber3(e); 
    }
    if(e.length==4){
      inputElement4.current.focus()
     }
  },[cellNumber3])

  const onChangeCell4 =  React.useCallback((e) => {
    if(e.length<5){
    setCellNumber4(e); 
    }
  },[cellNumber4])
  const onChangeType=  React.useCallback((e) => {
    console.log('type', e)
    setTypeName(e);
  },[type])

  const onChangeName = React.useCallback((e) => {
    setNameOnCard(e);
  },[nameOnCard])


  const validate =  React.useCallback((e) => {
    console.log('CVV', cvv)
  
    
    
    if(nameOnCard==null || nameOnCard.length<5){
     setErrorNameTxt('Required')
     setErrorName(true)
     return false
   }else{
     setErrorName(false)
   }
   if(cellNumber1== null || cellNumber2==null || cellNumber3==null || cellNumber4==null || (cellNumber1+cellNumber2+cellNumber3+cellNumber4).length<16){
      
    setErrorCell('A valid card number is required')
    return false
  }else{
    setErrorCell(true)
  }
  if(month==null || year==null){
    setErrorDateTxt('A valid expiry date is required')
    setErrorDate(true)
    return false
  }else{
    if(month>12 || year<22){
      setErrorDateTxt('A valid expiry date is required')
      setErrorDate(true)
      return false
    }else{
      setErrorDate(false)
    }
  }
  if(cvv==null){
    setErrorCvvTxt('Required')
    setErrorCvv(true)
    return false
  }else{
    setErrorCvv(false)
  }
  return true
  }, [nameOnCard, cellNumber1, cellNumber3, cellNumber2, cellNumber4, month, year, cvv])


  const onChangeCode = React.useCallback((e) => {
    setCode(e);
  },[codeTxt])

  const validate2 =  React.useCallback((e) => {
    console.log('CVV', cvv)
  
    
    if(nameOnCard==null || nameOnCard.length<5){
     setErrorNameTxt('Required')
     setErrorName(true)
     return false
   }else{
     setErrorName(false)
   }
   if(cellNumber1== null || cellNumber2==null || cellNumber3==null || cellNumber4==null || (cellNumber1+cellNumber2+cellNumber3+cellNumber4).length<16){
      
    setErrorCell('A valid card number is required')
    return false
  }else{
    setErrorCell(true)
  }
  if(month==null || year==null){
    setErrorDateTxt('A valid expiry date is required')
    setErrorDate(true)
    return false
  }else{
    if(month>12 || year<22){
      setErrorDateTxt('A valid expiry date is required')
      setErrorDate(true)
      return false
    }else{
      setErrorDate(false)
    }
  }
  if(cvv==null){
    setErrorCvvTxt('Required')
    setErrorCvv(true)
    return false
  }else{
    setErrorCvv(false)
  }
  return true
  }, [nameOnCard, cellNumber1, cellNumber3, cellNumber2, cellNumber4, month, year, cvv, codeTxt])


  const sendPayment = async() =>{
    if(validate()){
      
      var card_type 
      if(type=="Visa"){
        card_type = "VISA"
      }else{
        card_type = "MASTER"
      }
      console.log('SEND PAYMENT 1', card_type, type)
     
      var data = qs.stringify({
        'amount':props.otpReducer.adminUser.plan.items[0].cost,
        'paymentBrand':card_type,
        'cardNumber':cellNumber1+cellNumber2+cellNumber3+cellNumber4,
        'cardHolder':nameOnCard,
        'expiryMonth':month,
        'expiryYear':year,
        'cvv':cvv,
      });
      var config = {
        method: 'post',
        url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/makepaymenthurt',
        headers: { 
          "x-api-key": process.env.API_IMBEWU2_GRAPHQLAPIKEYOUTPUT,
        'Content-Type': 'application/x-www-form-urlencoded', 
         },
         data:data
      };
     
        const test = await axios(config)
        .then(function (response) {
          console.log('Pay',response.data);
          setPay(response.data)
    
        })
        .catch(function (error) {
          console.log('Pay error',error);
        });
        console.log('Wet', test)
    }
  }
  const setPay= async(reference) => {
    console.log('SEND PAYMENT 2', reference.result.code)
    var status
    if(reference.result.code=="000.100.110"){
      console.log('SEND PAYMENT 3 Approved')
      status='approved'
      const user = await API.graphql({
        query: createPayment,
        variables: {
          input: {
            userId: props.otpReducer.adminUser.id,
            reference: reference.id,
            status: status,
            amount: parseFloat(props.otpReducer.adminUser.plan.items[0].cost)
          },
        },
      })
      const auth = await API.graphql({
        query: updateAuthorizations,
        variables: {
          input: {
            id: props.otpReducer.adminUser.authorization.items[0].id,
            userId: props.otpReducer.adminUser.id,
            authorization_code: reference.registrationId
         
          },
        },
      })
      const userReceived = await API.graphql(graphqlOperation(queries.getUser, { id:props.otpReducer.adminUser.id }));
          console.log('SEND PAYMENT 4', userReceived)
          var loggedinUser =userReceived.data.getUser;
          props.setUser(userReceived);
          props.setSession(null);
          props.setVerified(true)
          console.log('SEND PAYMENT 5',loggedinUser)
          props.setDependants(loggedinUser.dependants.items)
          props.setBeneficary(loggedinUser.beneficiaries.items)
          props.setPayouts(loggedinUser.account.items)
          props.setPayments(loggedinUser.payments.items)
          props.setClaims(loggedinUser.claims.items)
          props.setPlan(loggedinUser.plan.items[0])
          props.setUser(loggedinUser);
          props.showLoadingModal(false)
          props.showAdminCreditCard(false)
    }else{
      console.log('SEND PAYMENT 3 Declined')
      status='declined'
      props.showLoadingModal(false)
      props.showErrorModal('Sorry, your credit card was declined. Please close this window and try again.')
    }
       
       
          
  };

  const sendCode = async()=>{
    console.log('wet 1',props.otpReducer.adminUser)
    props.showLoadingModal(true)
    var data = qs.stringify({
      'cell':props.otpReducer.adminUser.cell
    });
    var config = {
      method: 'post',
      url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/checkverifyhurt',
      headers: { 
        "x-api-key": process.env.API_IMBEWU2_GRAPHQLAPIKEYOUTPUT,
        'Content-Type': 'application/x-www-form-urlencoded', 
       },
       data:data
    };
   
      const test = await axios(config)
      .then(function (response) {
        //console.log('Pay',JSON.parse(response.data));
        //setPay(JSON.parse(response.data))
        props.showLoadingModal(false)
        props.showErrorModal("OTP Pin was sent successfuly")
      })
      .catch(function (error) {
        console.log('Pay error',error);
      });
      console.log('Wet', test)

  }

  const checkCode = async()=>{
    props.showLoadingModal(true)
    var data = qs.stringify({
      'cell':props.otpReducer.adminUser.cell,
      'code':codeTxt
    });
    var config = {
      method: 'post',
      url: 'https://4ornkz9e47.execute-api.us-east-1.amazonaws.com/hurt/checkcodehurt',
      headers: { 
        "x-api-key": process.env.API_IMBEWU2_GRAPHQLAPIKEYOUTPUT,
        'Content-Type': 'application/x-www-form-urlencoded', 
       },
       data:data
    };
   
      const test = await axios(config)
      .then(function (response) {
        console.log('Cehck Code',response.data);
        if(response.data.status=='approved'){
          sendPayment()
        }else{
          props.showLoadingModal(false)
          props.showErrorModal('Invalid Pin')
        }
  
      })
      .catch(function (error) {
        console.log('Pay error',error);
      });
      console.log('Wet', test)

  }

  console.log('pay card', props.otpReducer.adminUser)
  return  <div>
    {props.view.showAdminCreditCard ? <div style={{position:'fixed', top:0, width:'100%',height:'100%', zIndex:1000}}><PlasmicModalCreditCard root={{ ref }} {...props} 
  submitBtn = {{
    txt : 'Make once off payment (R'+props.otpReducer.adminUser.plan.items[0].cost+')',
    onClick:(e)=>{
      if(validate()){
        sendPayment()
      }
     
    }
  }}

  backBtn = {{
    onClick: (e) => {
      props.showAdminCreditCard(false)
    }
  }}



  sendCodeBtn = {{
    onClick:(e)=>{
      if(validate()){
        sendCode()
      }
    }
  }}


  nameOnCard = {{
    txtValue : {
      onChange: (e) => onChangeName(e.target.value),
      value: nameOnCard,
      placeHolder: 'Card Holders Name'
    },
    txtError: errorNameTxt,
    showError:errorName
  }}

  cvv = {{
    cardCvv : {
      onChange: (e) => onChangeCvv(e.target.value),
      value: cvv,
      placeHolder: '000'
    },
    btnHelp: {
      onClick:(e)=>{
        console.log('cvv status', cvvStatus)
        setCvvStatus(!cvvStatus)
      }
    },
    txtError: errorCvvTxt,
    showError:errorCvv,
    helpModal: false
  }}

  cardNo = {{
    cardFirst4: {
      onChange: (e) => onChangeCell1(e.target.value),
      value: cellNumber1,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:4,
      ref: inputElement1
    },
    cardSecond4:{
      onChange: (e) => onChangeCell2(e.target.value),
      value: cellNumber2,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxLength:4,
      ref: inputElement2
    },
    cardThird4:{
      onChange: (e) => onChangeCell3(e.target.value),
      value: cellNumber3,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxlLength:4,
      ref: inputElement3
    },
    cardFourth4:{
      onChange: (e) => onChangeCell4(e.target.value),
      value: cellNumber4,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxlLength:4,
      ref: inputElement4
    },
    txtError: errorCell,
    showError:errorCell
  }}
  code = {{
    txtValue : {
      onChange: (e) => onChangeCode(e.target.value),
      value: codeTxt,
      placeHolder: 'OTP Pin'
    },
    txtError: errorCodeTxt,
    showError:errorCode
  }}
  
  expiryDate = {{
    cardDateMonth: {
      onChange: (e) => onChangeMonth(e.target.value),
      value: month,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxlLength:2,
    },
    cardDateYear:{
      onChange: (e) => onChangeYear(e.target.value),
      value: year,
      type:'number',
      inputMode:'numeric',
      pattern:"[0-9]*",
      maxlLength:2,
      ref: inputElement5
    },
    txtError: errorDateTxt,
    showError:errorDate
  }}

  cardType = {{
    txtError: errorTypeTxt,
    showError:errorType,
    selectedTxt:'Card Type',
   selectGender: {
    placeholder:'Select your card type...',
     children:  <React.Fragment>
   <Select__Option
     value={"Visa"}
   >
     {"Visa"}
   </Select__Option>

   <Select__Option
     value={"Mastercard"}
   >
     {"Mastercard"}
   </Select__Option>
 </React.Fragment>,
    onChange: (e) => onChangeType(e),
    selectedContent: type
  }}
  }
  
  /></div>:null}
  </div>;
}

const ModalCreditCard = React.forwardRef(ModalCreditCard_);


const mapStateToProps = state => ({
  view: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  showLoadingModal: (obj) => dispatch(showLoadingModal(obj)),
  setStep: (int) => dispatch(setStep(int)),
  setSession: (string) => dispatch(setSession(string)),
  setVerified: (string) => dispatch(setVerified(string)),
  setUser: (obj) => dispatch(setUser(obj)),
  setUserName: (obj) => dispatch(setUserName(obj)),
  setUserMain: (obj) => dispatch(setUserMain(obj)),
  setDependants: (obj) => dispatch(setDependants(obj)),
  setBeneficary: (obj) => dispatch(setBeneficary(obj)),
  setPayments: (obj) => dispatch(setPayments(obj)),
  setPayouts: (obj) => dispatch(setPayouts(obj)),
  setClaims: (obj) => dispatch(setClaims(obj)),
  setPlan: (obj) => dispatch(setPlan(obj)),
  showErrorModal: (obj) => dispatch(showErrorModal(obj)),
  showAdminCreditCard: (obj) => dispatch(showAdminCreditCard(obj)),
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(ModalCreditCard);

