// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicUserTabs } from "./plasmic/copy_of_imbewu_admin/PlasmicUserTabs";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setUserView, showDependantModal, showErrorModal, showLoadingModal } from "../redux/reducers/view";
function UserTabs_(props, ref) {
  const [upTab, setUpTab] = React.useState(false);
  const [dTab, setDTab] = React.useState(true);
  const [bTab, setBTab] = React.useState(true);
  const [payTab, setPayTab] = React.useState(true);
  const [cTab, setCTab] = React.useState(true);
  const [outTab, setOutTab] = React.useState(true);
  function changeTab(target){
    console.log('admin tabs', target)
    setDTab(true)
    setUpTab(true)
    setBTab(true)
    setCTab(true)
    setOutTab(true)
    setPayTab(true)
    switch (target) {
      case 'userProfileTab':
        setUpTab(false);
        props.setUserView('userProfileTab')
        break;
      case 'dependentsTab':
        setDTab(false)
        props.setUserView('dependentsTab')
        break;
      case 'beneficiariesTab':
        setBTab(false)
        props.setUserView('beneficiariesTab')
        break;
      case 'claimsTab':
        setCTab(false)
        props.setUserView('claimsTab')
        break;
      case 'payoutsTab':
        setOutTab(false)
        props.setUserView('payoutsTab')
        break;
      case 'paymentsTab':
        setPayTab(false)
        props.setUserView('paymentsTab')
        break;
      default:
        break;
    }
  }
 
  return <PlasmicUserTabs root={{ ref }} {...props}
    userProfileTab = {{
      unselected:upTab,
      onClick:(e=>{
        changeTab('userProfileTab')
      })
    }}
    dependentsTab = {{
      unselected:dTab,
      onClick:(e=>{
        changeTab('dependentsTab')
      })
    }}
    beneficiariesTab = {{
      unselected:bTab,
      onClick:(e=>{
        changeTab('beneficiariesTab')
      })
    }}
    claimsTab = {{
      unselected:cTab,
      onClick:(e=>{
        changeTab('claimsTab')
      })
    }}
    payoutsTab = {{
      unselected:outTab,
      onClick:(e=>{
        changeTab('payoutsTab')
      })
    }}
    paymentsTab = {{
      unselected:payTab,
      onClick:(e=>{
        changeTab('paymentsTab')
      })
    }}
  />;
}

const UserTabs = React.forwardRef(UserTabs_);
const mapStateToProps = state => ({
  otpReducer: state.otpReducer,
  view: state.viewReducer
});


const mapDispatchToProps = dispatch => ({
  showErrorModal:(string) => dispatch(showErrorModal(string)),
  showLoadingModal:(string) => dispatch(showLoadingModal(string)),
  showDependantModal: (string) => dispatch(showDependantModal(string)),
  setUserView:(string)=>dispatch(setUserView(string))
})



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(UserTabs);
