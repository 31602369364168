// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicAbout } from "./plasmic/imbewu_mobile_first/PlasmicAbout";
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { toggleSideMenu } from "../redux/reducers/view";
function About_(props, ref) {
  const changePage =  React.useCallback((e) => {

    props.history.push(e);
   
  }, []);

  return <PlasmicAbout root={{ ref }} {...props} 
  btnSignup = {{
    onClick:(e) =>{
      changePage('/register')
    }
  }}/>;
}

const About = React.forwardRef(About_);

const mapStateToProps = state => ({
  viewReducer: state.viewReducer,
  otpReducer: state.otpReducer
});

const mapDispatchToProps = dispatch => ({
  toggleSideMenu: () => dispatch(toggleSideMenu()),
});



export default compose( connect(
  mapStateToProps,
  mapDispatchToProps,
),withRouter)(About);

